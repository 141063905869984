import callApi from '../../utils/callApi';
import { baseUrl, flaskUrl } from '../../utils/config';

import {
  REQUEST_CANDIDATE_CONTENT, REQUEST_CANDIDATE_TALENTPOOL_ADD,
  REQUEST_TALENTPOOL_SEARCH, REQUEST_CANDIDATE_SHORTLIST_UPDATE,
  REQUEST_BULK_SHORTLIST_REJECT, REQUEST_BULK_LIKE, REQUEST_REFERSH_SCORES,
  REQUEST_APPLICATION_SHARE, REQUEST_OTHER_JOBS, REQUEST_CANDIDATE_PROFILE,
  REQUEST_EDIT_CANDIDATE_PROFILE, REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT,
  REQUEST_ASSET_ANALYTICS, REQUEST_DELETE_CANDIDATE, REQUEST_DOWNLOAD_CANDIDATE,
  REQUEST_DELETE_APPLICANT_INFO, REQUEST_GET_MORE_CANDIDATES_AISOURCING,
  REQUEST_ALLCANDIDATE_CONTENT, REQUEST_UPDATE_PLACEMENT, ERROR_UPDATE_PLACEMENT, 
  SUCCESS_UPDATE_PLACEMENT
} from './actionTypes';

import { REQUEST_COMPANIES_DATA, REQUEST_UPSERT_PROFILE_CONTACT, REQUEST_GET_JOBS_APPLIED_COUNT } from '../../actions/actionTypes';
import { deleteApiCache } from 'utils/helpers';

const shareCV = shareObj => (dispatch) => {
  const { appId = 0 } = shareObj;
  const url = `${baseUrl}/applications/${appId}/shares`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(shareObj),
    actionType: REQUEST_APPLICATION_SHARE,
  };

  return callApi(params);
};

const addApplicantToTalentPool = bulkSrObj => (dispatch) => {
  const url = `${baseUrl}/profile-base/bulkAddToTalentPool?`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(bulkSrObj),
    actionType: REQUEST_CANDIDATE_TALENTPOOL_ADD,
  };

  return callApi(params);
};

const setShortlistStatus = (appId, shortlisted) => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}`;
  const shortlistStatusObj = {
    appId,
    shortlisted,
  };
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(shortlistStatusObj),
    actionType: REQUEST_CANDIDATE_SHORTLIST_UPDATE,
  };

  return callApi(params);
};

const bulkShortlistReject = bulkSrObj => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(bulkSrObj),
    actionType: REQUEST_BULK_SHORTLIST_REJECT,
  };
  return callApi(params);
};

const bulkLike = bulkSrObj => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(bulkSrObj),
    actionType: REQUEST_BULK_LIKE,
  };
  return callApi(params);
}

const findAllUserJobApplicants = jobId => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}${
    jobId ? `&jobId=${jobId}` : ''
  }`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_CANDIDATE_CONTENT,
  };

  return callApi(params);
};

const deleteCandidateProfile = userId => (dispatch) => {
  const url = `${baseUrl}/userinfos/${userId}`;

  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_CANDIDATE,
  };

  return callApi(params);
};

const deleteApplicantInfo = appId => (dispatch) => {
  const url = `${baseUrl}/applications/${appId}`;

  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: REQUEST_DELETE_APPLICANT_INFO,
  };

  return callApi(params);
};

const downloadCandidateProfile = userId => (dispatch) => {
  const url = `${baseUrl}/userinfos/${userId}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_DOWNLOAD_CANDIDATE,
  };

  return callApi(params);
};

const findOtherJobsApplied = (appId = 0, profileId, itemsPerPage, activePage) => (dispatch) => {
  if (!profileId) {
    return;
  }
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const url = `${baseUrl}/profiles/${appId}/applications?_=${new Date().getTime()}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}&profileId=${profileId}`;

  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_OTHER_JOBS,
  };

  return callApi(params);
};


const getCandidateProfile = profileId => (dispatch) => {
  if (!profileId) {
    return Promise.resolve(new Error('No profileId')); // reject?
  }
  const url = `${baseUrl}/profiles/${profileId}?_=${new Date().getTime()}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_CANDIDATE_PROFILE,
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const getAssetAnalytics = appId => (dispatch) => {
  if (!appId) {
    return;
  }
  const url = `${baseUrl}/application-base/getAssetAnalytics?applicationId=${appId}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_ASSET_ANALYTICS,
  };
  return callApi(params);
};

const editCandidateProfile = data => (dispatch) => {
  const { profileId } = data || {};
  let url = `${baseUrl}/profiles`;
  if (profileId) {
    url += `/${profileId}?_=${Date.now()}`;
  } else {
    url += `?_=${Date.now()}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_CANDIDATE_PROFILE,
  };
  deleteApiCache('xpa-api-profiles');
  return callApi(params);
};

const editProfileContact = data => (dispatch) => {
  const { profileId, personMobId } = data || {};
  if (!profileId) {
    return new Promise(res => res(''));
  }
  const url = `${baseUrl}/profiles/${Number(profileId)}/contacts/${personMobId || ''}?_=${Date.now()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: personMobId?'PATCH':'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_EDIT_CANDIDATE_PROFILE,
  };

  return callApi(params);
};

const upsertProfileContact = data => (dispatch) => {
  const { profileId, mobile } = data || {};
  const { personMobId } = mobile || {};
  if (!profileId) {
    return new Promise((resolve) => {
      resolve({
        error: {
          message: 'Missing required fields',
        },
      });
    });
  }
  let url = `${baseUrl}/profiles/${Number(profileId)}/contacts?_=${Date.now()}&profileId=${Number(profileId)}`;
  if (personMobId) {
    url = `${baseUrl}/profiles/${Number(profileId)}/contacts/${personMobId}?_=${Date.now()}&profileId=${profileId}&personMobId=${personMobId}`;
  }
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: personMobId ? 'PATCH' : 'POST',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPSERT_PROFILE_CONTACT,
  };

  return callApi(params);
};


const getCountries = () => (dispatch) => {
  const url = `${baseUrl}/countries`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_COMPANIES_DATA,
  };
  return callApi(params);
};

const findFromUserTalentPool = (jobId, bucketId = 0) => (dispatch) => {
  let url = '';
  if (bucketId === 0) {
    url = `${flaskUrl}/jobs/talentpool/matches?job_id=${jobId}&_=${new Date().getTime()}`;
  } else {
    url = `${flaskUrl}/jobs/talentpool/matches?job_id=${jobId}&bucket_id=${bucketId}&_=${new Date().getTime()}`;
  }
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: REQUEST_TALENTPOOL_SEARCH,
  };

  return callApi(params);
};

const getRefreshScores = (jobId, userId) => (dispatch) => {
  const url = `${flaskUrl}/jobs/${jobId}/scores?_=${Date.now()}&taskType=celery`;
  // if (!appIdArr || !Array.isArray(appIdArr) || !(Array.isArray(appIdArr) && appIdArr.length > 0)) {
  //   const err = new Error('No applications to refresh scores for.');
  //   return Promise.reject(err);
  // }
  if (!jobId) {
    const err = new Error('No applications to refresh scores for.');
    return Promise.reject(err);
  }
  if (!userId) {
    const err = new Error('Unauthorized user.');
    return Promise.reject(err);
  }
  const data = {
    jobId,
    addDownvoted: true,
  };
  const params = {
    dispatch,
    method: 'POST',
    url,
    headers: {
      'x0pa-user-id': userId || 0,
      'Content-Type': 'application/json',
    },
    actionType: REQUEST_REFERSH_SCORES,
    body: JSON.stringify(data),
  };
  return callApi(params);
};

const paginateCandidate = (itemsPerPage, activePage) => (dispatch) => {
  const skip = (itemsPerPage - 1) * activePage;
  const url = `${baseUrl}/Applications/findAllUserJobApplicants?filter[limit]=${itemsPerPage}&filter[skip]=0&_=${new Date().getTime()}`;

  const params = {
    actionType: REQUEST_CANDIDATE_CONTENT,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const reRenderContent = ({
  jobId = 0,
  itemsPerPage = 10,
  activePage = 1,
  shortlistStatus = null,
  filterOptions = '',
  searchKey = null,
  status = null,
  applicationStatus = '',
  filterName_applicationStatus = [],
  filterName_workflowStages = [],
  applicationType = '',
  workflowSubStageStatus = '',
  filterName_applicationType = [],
  filterName_workflowSubStageStatus = [],
  filterName_offerStatus = [],
  offerStatus = '',
  workflowStages = '',
  hiringStatus = '',
  sourcingStatus = '',
  filterName_hiringStatus = [],
  filterName_sourcingStatus = [],
  gender='',
  skills='',
  filterName_gender = [],
  filterName_skills = [],
  courseId,
  dashboard,
  batch
}, allApps = false) => (dispatch) => {
  const skip = activePage === 0 ? 0 : (activePage - 1) * itemsPerPage;
  const usp = new URLSearchParams(window.document.location.search)
  let appLocation = window.location;
  let { pathname } = appLocation;
  const applicationDashboardStatus = usp.get("applicationDashboardStatus")
  const applicationBatchStatus = usp.get("batch")

  let url = `${baseUrl}/applications?_=${new Date().getTime()}&jobId=${jobId}&filter[limit]=${itemsPerPage}&filter[skip]=${skip}${
    ((shortlistStatus === true) || (shortlistStatus === false)) ?`&shortlisted=${shortlistStatus}` : ''
  }${
    (searchKey) ?`&searchKey=${searchKey}` : ''
  }${
    (status) ?`&status=${status}` : ''
  }${
    (dashboard) ?`&dashboard=${dashboard}` : ''
  }${
    (batch) ?`&batch=${batch}` : ''
  }&`.concat(filterOptions);

  if (applicationStatus) {
    url += `&filterKeys[applicationStatus]=${encodeURIComponent((JSON.stringify(filterName_applicationStatus)))}`;
  }

  if (applicationType) {
    url += `&filterKeys[${applicationType}]=${encodeURIComponent((JSON.stringify(filterName_applicationType)))}`;
  }

  if (sourcingStatus) {
    url += `&filterKeys[${sourcingStatus}]=${encodeURIComponent((JSON.stringify(filterName_sourcingStatus)))}`;
  }

  if (workflowStages) {
    url += `&filterKeys[${workflowStages}]=${encodeURIComponent((JSON.stringify(filterName_workflowStages)))}`;
  }

  if (workflowSubStageStatus) {
    url += `&filterKeys[${workflowSubStageStatus}]=${encodeURIComponent((JSON.stringify(filterName_workflowSubStageStatus)))}`;
  }

  if (offerStatus) {
    url += `&filterKeys[${offerStatus}]=${encodeURIComponent((JSON.stringify(filterName_offerStatus)))}`;
  }

  if (hiringStatus) {
    url += `&filterKeys[${hiringStatus}]=${encodeURIComponent((JSON.stringify(filterName_hiringStatus)))}`;
  }

  if (gender) {
    url += `&filterKeys[${gender}]=${encodeURIComponent((JSON.stringify(filterName_gender)))}`;
  }

  if (skills) {
    url += `&filterKeys[${skills}]=${encodeURIComponent((JSON.stringify(filterName_skills)))}`;
  }

  if(applicationDashboardStatus){
    url += `&filterKeys[${applicationDashboardStatus}]=${encodeURIComponent((JSON.stringify(applicationDashboardStatus)))}`
  }

  if(courseId){
    url += `courseId=${courseId}`
  }

  // if(applicationBatchStatus){
  //   url += `&batch=${applicationBatchStatus}`
  // }
  // else{
  //   url += '&batch=2'
  // }

  // if(pathname == '/app/campus'){
  //   url += `&batch=2`
  // }

  if (allApps) {
    const params = {
      actionType: REQUEST_ALLCANDIDATE_CONTENT,
      dispatch,
      url,
      method: 'GET',
    };

    return callApi(params);
  }

  const params = {
    actionType: REQUEST_CANDIDATE_CONTENT,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getJobAnalyticsDashboardCount = ({
  jobId = 0,
  status = null,
}) => (dispatch) => {
  const url = `${baseUrl}/applications?_=${new Date().getTime()}&jobId=${jobId}${
    (status) ? `&status=${status}` : ''
  }&`;

  const params = {
    actionType: REQUEST_JOB_ANALYTICS_DASHBOARD_COUNT,
    dispatch,
    url,
    method: 'GET',
  };

  return callApi(params);
};

const getMoreCandidatesAiSourcing = data => (dispatch) => {
  const { jobId = null, count = null } = data;
  if (jobId === null || count === null) return;
  const url = `${baseUrl}/candidaterecords/${jobId}?count=${count}`;
  const params = {
    actionType: REQUEST_GET_MORE_CANDIDATES_AISOURCING,
    dispatch,
    url,
    method: 'GET',
  };
  return callApi(params);
};

const getJobAppliedCount = profileId => (dispatch) => {
  if (!profileId) {
    const error = {
      message: 'Invalid profile id passed',
    };
    Promise.reject(error);
  }
  const url = `${baseUrl}/applications/${Number(profileId)}/count?_=${Date.now()}`;
  const params = {
    dispatch,
    method: 'get',
    url,
    actionType: REQUEST_GET_JOBS_APPLIED_COUNT,
  };

  return callApi(params);
};

const getStudentAppData = () =>(dispatch)=> {
  const url = `${baseUrl}/applications/exports?_=${new Date().getTime()}`
  window.location.href = url;
}

const updateReversePlacement = data => (dispatch) => {
  if(!data){
    const err = {
      error: {
        message: 'Missing required data',
      },
    };
    return Promise.resolve(err);
  }

  const url = `${baseUrl}/applications/reverseplacement?_=${new Date().getTime()}`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'PATCH',
    url,
    body: JSON.stringify(data),
    actionType: REQUEST_UPDATE_PLACEMENT,
  };
  return callApi(params);
}

const updateSelectedForGeip = data => (dispatch) => {
  const url = `${baseUrl}/profiles/b/selectedforgeip`;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(data),
    actionType: 'REQUEST_UPDATE_SELECTED_FOR_GEIP',
  };

  return callApi(params);
}

export {
  findAllUserJobApplicants, setShortlistStatus, findFromUserTalentPool,
  paginateCandidate, reRenderContent, addApplicantToTalentPool,
  bulkShortlistReject, bulkLike, getRefreshScores, shareCV, findOtherJobsApplied,
  getCandidateProfile, getJobAnalyticsDashboardCount, editCandidateProfile, getCountries,
  getAssetAnalytics, deleteCandidateProfile, downloadCandidateProfile, editProfileContact,
  deleteApplicantInfo, upsertProfileContact, getMoreCandidatesAiSourcing,
  getJobAppliedCount, getStudentAppData, updateReversePlacement, updateSelectedForGeip
};