import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import CandidateProfile from 'containers/roboroy/CandidateProfile';
import BackRow from 'components/common/BackRow/BackRow';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import ColoredCircle from 'components/common/ColoredCircle';
import ScheduledInterviews from 'containers/robocampus/ScheduledInterviews';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { getCustomEmailTemplates } from 'actions/email-templates';
import {
  Tile,
  Toggle,
  SelectItem,
  Select,
  Button,
  Loading,
  Pagination,
  InlineNotification,
  ToolbarItem,
  Modal,
  Checkbox,
  TextInput,
  Accordion,
  AccordionItem,
  RadioButtonGroup,
  RadioButton,
} from 'carbon-components-react';
import Preferences from 'containers/robocampus/Preferences';
import {
  getStudentProfileJobMatches,
  updateStudentProfileJobMatches,
  addStudentProfileToJob,
  getStudentProfileEmails,
  addStudentToSetAsHired,

  getInterviewFeedback,
  createInterviewFeedback,
  upsertInterviewFeedback,
} from 'actions/StudentProfileView';
import {
  getCandidateProfile,
  editCandidateProfile,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';
import StudentProfileRegister from 'containers/roboroy/StudentProfileRegister';
import { sendEmails } from 'actions/talentSearch/talentPool';
import moment from 'moment';
import { getCitiesList } from 'actions/CitiesDetails';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { uploadCampusDocs } from 'actions/userProfile/action';
import {
  uploadSignedOfferDocument
} from 'actions/uploadAllDocuments';
import { getBatchDetails } from 'actions/BatchManagementView';
import './StudentProfileView.css';

import {
  upsertUserInfoDetails,
} from 'actions/candidate/candidateConsent';
import CandidateVideoInterview from './CandidateVideoInterview';
import GeipDetails from '../common/campusLogin/CandidateRegister/geipDetails';
import InternCandidate from 'containers/robocampus/internCandidate';
import CandidateDetails from 'components/roboroy/CandidateDetails';
import InterviewFeedbackForm from 'components/roboroy/InterviewFeedbackForm';
import {
  Layout,
  Facets,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';


// const batchArr = [
//   { label: "AY20 Sem 2", value: "AY20 Sem 2" },
//   { label: "AY21 Sem 1", value: "AY21 Sem 1" }
// ]

const NoContentBox = ({ t, errorCode }) => (
  <Tile className="">
    {errorCode !== null && (
      <div className="text-center text-dark">
        {t('errorCode')}: {errorCode}
      </div>
    )}
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">{t('noJobsMsg')}</p>
    </div>
  </Tile>
);

class StudentProfileView extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    {
      id: 'browseStudents',
      name: 'Browse Students',
      href: '/app/campus/browse/students',
    },
    { id: 'student', name: 'Profile', href: '' },
  ];

  candPaths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'student', name: 'Recommended Jobs', href: '' },
  ];



  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      tabName: '',
      isValidStudentPrefRankMsg: '',
      showLoader: false,
      showError: false,
      errorCode: null,
      jobMatchesPreferences: [],
      activePage: 1,
      itemsPerPage: 10,
      suspendAccountLoader: false,
      isOpenEmailModal: false,
      showNotifInfo: false,
      isOpenCityModal: false,
      cityArr: [],
      citiesList: [],
      detailsTab: 0,
      candidateSelectedTab: 0,
      showHiredErr: false,
      showJobvacanicesErr: false,
      showJobApplyLimitErr: false,
      showHiredJobvacerr: false,
      batchLoader: false,
      currStdProfTab: 0,
      selectedTabBelongsTo: 'geipComp',
      tabIndex: '0'
    };
  }

  componentDidMount() {
    const { selectedTab } = this.state;
    const {
      history: { push } = {},
      user: { roleId } = {},
      match: {
        params: { profileId, studentProfileId, tabName: paramTabName } = {},
      } = {},
    } = this.props;
    this.handleSingleSelectFacets();
    this.props.getBatchDetails().then((res) => {
      if(res && !res.error){
        const { list: batchList } = res;
        this.setState({
          ...this.state,
          batchArr: batchList
        })
      }
    })
    if (roleId !== 3) {
      if (!paramTabName) {
        let tabName = '';
        if (selectedTab === 0) {
          tabName = 'student-profile';
          this.props.getCandidateProfile(profileId);
          this.props.getJobAppliedCount(profileId);
        } else if (selectedTab === 1) {
          tabName = 'job-matches';
          this.props.getJobAppliedCount(profileId);
        }
        else if (selectedTab === 2) {
          tabName = 'institution-preferred';
        } else if (selectedTab === 3) {
          tabName = 'student-preferred';
        } 
        else if (selectedTab === 4) {
          tabName = 'student-slots';
        }
        // else if (selectedTab === 2) {
        //   tabName = 'email';
        // }
        else if (selectedTab === 5) {
          tabName = 'video-int';
        }
        else if (selectedTab === 6) {
          tabName = 'student-settings';
        } 
        else if (selectedTab === 7) {
          tabName = 'interview-feedback';
        }
        this.setState(
          {
            tabName,
          },
          () => {
            const { tabName: selectedTabName } = this.state;
            if (push) {
              push(
                `/app/campus/profile/${profileId}/student/${studentProfileId}/tab/${selectedTabName}`
              );
            }
          }
        );
      } else if (
        paramTabName &&
        paramTabName !== null &&
        paramTabName !== undefined
      ) {
        let tabNumber = 0;
        if (paramTabName === 'student-profile') {
          tabNumber = 0;
          this.props.getCandidateProfile(profileId);
          this.props.getJobAppliedCount(profileId);
        } else if (paramTabName === 'job-matches') {
          this.setState(
            {
              showLoader: true,
            },
            () => {
              const data = {
                studentProfileId,
                activePage: 1,
                itemsPerPage: 10,
              };
              this.props
                .getStudentProfileJobMatches(data, null)
                .then(res => {
                  if (res && !res.error) {
                    this.props.getJobAppliedCount(profileId).then((cnt) => {
                      if (cnt && !cnt.error) {
                        this.setState({
                          showLoader: false,
                        });
                      }
                    });
                  }
                })
                .catch(e => console.log('Error', e));
            }
          );
          tabNumber = 1;
        }
        else if (paramTabName === 'institution-preferred') {
          this.setState({
            showLoader: true,
          }, () => {
            const data = {
              studentProfileId,
              activePage: 1,
              itemsPerPage: 10,
            };
            this.props.getStudentProfileJobMatches(data, 'ihlpreferred').then((res) => {
              if (res && !res.error) {
                this.setState({
                  showLoader: false,
                });
              }
            })
              .catch(e => console.log('Error', e));
          });
          tabNumber = 2;
        } else if (paramTabName === 'student-preferred') {
          this.setState({
            showLoader: true,
          }, () => {
            const data = {
              studentProfileId,
              activePage: 1,
              itemsPerPage: 10,
            };
            this.props.getStudentProfileJobMatches(data, 'studentpreferred').then((res) => {
              if (res && !res.error) {
                this.setState({
                  showLoader: false,
                });
              }
            })
              .catch(e => console.log('Error', e));
          });
          tabNumber = 3;
        } else if (paramTabName === 'student-slots') {
          tabNumber = 4;
        }else if (paramTabName === 'video-int') {
          tabNumber = 5;
        }
        // else if (paramTabName === 'email') {
        //   this.setState(
        //     {
        //       showLoader: true,
        //     },
        //     () => {
        //       this.props.getCustomEmailTemplates();
        //       this.props.getCandidateProfile(profileId);
        //       this.props
        //         .getStudentProfileEmails(profileId)
        //         .then(res => {
        //           if (res && !res.error) {
        //             this.setState({
        //               showLoader: false,
        //             });
        //           }
        //         })
        //         .catch(e => console.log('Error', e));
        //     }
        //   );
        //   tabNumber = 1;
        // }
        else if (paramTabName === 'student-settings') {
          tabNumber = 6;
          this.handleGetStudentSettingsData();
        } else if (paramTabName === 'interview-feedback') {
          tabNumber = 7;
          this.props.getCandidateProfile(profileId);
          this.handleGetInterviewFeedbackData();
        }
        this.setState(
          {
            selectedTab: tabNumber,
            tabName: paramTabName,
          },
          () => {
            const citydata = {
              activePage: 1,
              itemsPerPage: -1,
              keyword: '',
            };
            this.props.getCitiesList(citydata);
          }
        );
      }
    } else if (roleId === 3) {
      const { user: { profileId: currProfileId } = {} } = this.props;
      this.setState(
        {
          showLoader: true,
        },
        () => {
          this.props.getCandidateProfile(currProfileId).then(res => {
            if (res && !res.error) {
              const currStudentProfileId =
                res &&
                res.studentprofile &&
                res.studentprofile.studentProfileId;
              const finalData = {
                studentProfileId: currStudentProfileId,
                activePage: 1,
                itemsPerPage: 10,
              };
              this.props
                .getStudentProfileJobMatches(finalData, 'ihlpreferred')
                .then(result => {
                  if (result && !result.error) {
                    const { scores } = this.props;
                    const fstate = {};
                    if (scores && Array.isArray(scores) && scores.length > 0) {
                      scores.forEach(v => {
                        fstate[
                          `studentPreferenceRank_${Number(v.scoringStudentId)}`
                        ] = v.studentPreferenceRank;
                      });
                    }
                    const jobMatchesPreferences =
                      scores &&
                      Array.isArray(scores) &&
                      scores.length > 0 &&
                      scores.map(scs => {
                        const {
                          jobId,
                          job: {
                            company: { companyName } = {},
                            cities: { cityName } = {},
                            jobname: { nameName } = {},
                            skills,
                          } = {},
                          scoringStudentId,
                        } = scs || {};
                        const data = {
                          id: jobId,
                          name: nameName,
                          skills,
                          companyName,
                          cityName,
                          scoringStudentId,
                        };
                        return data;
                      });
                    this.setState({
                      // selectedTab:2,
                      selectedTab: 0,
                      showLoader: false,
                      showError: false,
                      errorCode: null,
                      jobMatchesPreferences,
                      ...fstate,
                    });
                  }
                })
                .catch(e => console.log('Error:::::', e));
            } else if (res && res.error) {
              this.setState({
                showError: true,
                showLoader: false,
                errorCode: res && res.error && res.error.code,
              });
            }
          });
        }
      );
    }
  }

  componentDidUpdate(prevProps){
    const {offerAcceptedCount: prevOfferAcceptedCount} = prevProps || {};
    const {offerAcceptedCount: currOfferAcceptedCount} = this.props || {};
    if(prevOfferAcceptedCount != currOfferAcceptedCount){
      this.handleSingleSelectFacets();
    }
  }

  handleGetStudentSettingsData = () => {
    const {
      match: {
        params: { profileId } = {},
      } = {},
    } = this.props;
    this.props.getCandidateProfile(profileId).then((res)=>{
      if(res && ! res.error){
        const { profile } = res || {};
        const { batch, tier:tierValue, selected, internshipProgram } = profile || {};
        this.setState({
          batch:{label:batch, value:batch},
          tierValue,
          selectedForGeip: selected == true ? 'yes' : selected == false ? 'no' : null,
          geipInternshipProgram: (internshipProgram && internshipProgram == 'overseas') ? 
            { label: 'Overseas Internship', value: internshipProgram } : (internshipProgram && internshipProgram == 'graded') ? 
            { label: 'Graded Internship', value: internshipProgram } : null,
        })
      }
    }).catch((e)=>{
      console.error('error', e);
    })
  }

  handleSingleSelectFacets = () => {
    const {
      t,
      offerAcceptedCount
    } = this.props;
    // let {leftNavData=[]}=this.state;
    let leftNavData = [
      {
        titleName: "Uploaded Image",
        titleProp: "geipComp-0"
        // onClick: () => {
        //   this.handleStudentProfileInfoTabClick('geipComp', '0');
        // },
      },
      // {
      //   label: "Uploaded CV",
      //   value: '',
      //   onClick: () => {
      //     this.handleStudentProfileInfoTabClick('geipComp', '1');
      //   },
      // },
      {
        titleName: "Reason for GEIP",
        titleProp: "geipComp-2"
      },
      {
        titleName: "Bio/Academia Information Part I",
        titleProp: "geipComp-3"
      },
      {
        titleName: "Bio/Academia Information Part II",
        titleProp: "icComp-1"
      },
      {
        titleName: "Skills Proficiency",
        titleProp: "icComp-2"
      },
      {
        titleName: "Skills you would like to learn",
        titleProp: "icComp-3"
      },
      {
        titleName: "Individual Preferences",
        titleProp: "icComp-4"
      },
      {
        titleName: "Industry, Job Function",
        titleProp: "icComp-5"
      },
      {
        titleName: "Documents",
        titleProp: "icComp-6"
      }
    ];

    if (offerAcceptedCount > 0) {
      leftNavData.push({
        titleName: t('signedUpdocs'),
        titleProp: "offerComp-4"
      });
    }

    const multiValueStudentProfileProps = {
      label: 'Sections',
      options: [],
      onRemove: () => { },
      onSelect: (val) => {
        const foundIndex = leftNavData.findIndex(
          (r) => r && r.titleName === val
        );
        const propValue = leftNavData[foundIndex].titleProp;
        this.handleStudentProfileInfoTabClick(propValue);
      },
    };

    const filterSingleLinkArr = [];
    leftNavData.forEach((res) => {
      const { titleName, titleProp } = res || {};
      const finalObj = {
        selected: false,
        value: titleName,
        count: '',
        propVal: titleProp,
      };
      filterSingleLinkArr.push(finalObj);
    });
    multiValueStudentProfileProps.options.push(...filterSingleLinkArr);
    this.setState({
      multiValueStudentProfileProps: [multiValueStudentProfileProps],
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeEmailModal = () => {
    this.setState({
      isOpenEmailModal: true,
    });
  };

  dismissEmailModal = () => {
    this.setState({
      isOpenEmailModal: false,
    });
  };

  handleCandidateTabClick = e => {
    let candidateSelectedTab = 0;
    switch (e) {
      case 'inst-rec':
        candidateSelectedTab = 0;
        break;
      case 'x0pa-rec':
        candidateSelectedTab = 1;
        break;
      default:
        candidateSelectedTab = 0;
        break;
    }
    this.setState(
      {
        candidateSelectedTab,
      },
      () => {
        const {
          candidateProfile: { studentprofile: { studentProfileId } = {} } = {},
        } = this.props;
        const finalData = {
          studentProfileId,
          activePage: 1,
          itemsPerPage: 10,
        };
        if (this.state.candidateSelectedTab === 0) {
          this.props.getStudentProfileJobMatches(finalData, 'ihlpreferred');
        }
        if (this.state.candidateSelectedTab === 1) {
          this.props.getStudentProfileJobMatches(finalData, 'x0papreferred');
        }
      }
    );
  };

  handleGetInterviewFeedbackData = () => {
    const {
      match: {
        params: { profileId } = {},
      } = {},
    } = this.props;
    this.props.getInterviewFeedback(profileId).then((res) => {
      if (res && !res.error) {
        this.handleSaveFeedbackdata();
      }
    });
  }

  handleProfileViewTabClick = e => {
    let selectedTab = 0;
    let tabName = '';
    const {
      history: { push } = {},
      match: { params: { profileId } = {} } = {},
      user: { roleId } = {},
    } = this.props;
    switch (e) {
      case 'profile-view':
        selectedTab = 0;
        tabName = 'student-profile';
        break;
      case 'job-matches':
        selectedTab = 1;
        tabName = 'job-matches';
        break;
      case 'institution-assigned':
        selectedTab = 2;
        tabName = 'institution-preferred';
        break;
      case 'student-preferred':
        selectedTab = 3;
        tabName = 'student-preferred';
        break;
      case 'student-slots':
        selectedTab = 4;
        tabName = 'student-slots';
        break;
      case 'video-int':
        selectedTab = 5;
        tabName = 'video-int';
        break;
      // case 'email':
      //   selectedTab = 2;
      //   tabName = 'email';
      //   break;
      case 'student-settings':
        selectedTab = 6;
        tabName = 'student-settings';
        this.handleGetStudentSettingsData();
        break;
      case 'interview-feedback':
        selectedTab = 7;
        tabName = 'interview-feedback';
        this.handleGetInterviewFeedbackData();
        break;
      default:
        break;
    }
    this.setState(
      {
        selectedTab,
        tabName,
      },
      () => {
        const { tabName: selectedTabName } = this.state;
        let studentProfileId = 0;
        if (roleId === 3) {
          const {
            candidateProfile: {
              studentprofile: { studentProfileId: currStudentProfileId } = {},
            } = {},
          } = this.props;
          studentProfileId = currStudentProfileId;
        } else {
          const {
            match: {
              params: { studentProfileId: currStudentProfileId } = {},
            } = {},
          } = this.props;
          studentProfileId = currStudentProfileId;
        }
        if (push && roleId !== 3) {
          push(
            `/app/campus/profile/${profileId}/student/${studentProfileId}/tab/${selectedTabName}`
          );
        }
        this.handlePaginationChange();
      }
    );
  };

  handlePaginationChange = data => {
    const {
      candidateProfile: {
        profile,
        studentprofile: { studentProfileId } = {},
      } = {},
      user: { roleId } = {},
    } = this.props;
    const {
      profileId,
    } = profile || {};
    const activePage = (data && data.page) || 1;
    const itemsPerPage = (data && data.pageSize) || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      const finalData = {
        activePage: this.state.activePage,
        itemsPerPage: this.state.itemsPerPage,
        studentProfileId: studentProfileId && Number(studentProfileId),
        filterOptions: this.state.sortParam,
        searchKey: this.state.searchKey,
        cityArr: this.state.citiesList,
      };
      const { selectedTab, candidateSelectedTab } = this.state;
      if (selectedTab === 0) {
        this.props.getCandidateProfile(profileId);
      } else if (selectedTab === 1 && roleId && Number(roleId) !== 3) {
        this.props.getStudentProfileJobMatches(finalData, null);
        this.props.getJobAppliedCount(profileId);
      } else if (selectedTab === 2 && roleId && Number(roleId) !== 3) {
        this.props.getStudentProfileJobMatches(finalData, 'ihlpreferred');
        this.props.getJobAppliedCount(profileId);
      } else if (selectedTab === 3 && roleId && Number(roleId) !== 3) {
        this.props.getStudentProfileJobMatches(finalData, 'studentpreferred');
        this.props.getJobAppliedCount(profileId);
      } 
      // else if (candidateSelectedTab === 0 && roleId && Number(roleId) === 3) {
      //   this.props.getStudentProfileJobMatches(finalData, 'ihlpreferred');
      // } else if (candidateSelectedTab === 1 && roleId && Number(roleId) === 3) {
      //   this.props.getStudentProfileJobMatches(finalData, 'x0papreferred');
      // }
    });
  };

  handleSuspendToggleChange = (profileId, suspended) => {
    let data = {
      profileId: Number(profileId),
      suspended,
    };
    if (profileId) {
      this.setState({ suspendAccountLoader: true }, () => {
        this.props.editCandidateProfile(data).then(() => {
          this.props.getCandidateProfile(profileId).then(() => {
            this.setState({ suspendAccountLoader: false });
          });
        });
      });
    }
  };

  handleSelectedForGeipToggle = (profileId) => {
    const { selectedForGeip, geipInternshipProgram } = this.state || {};
    let data = {
      profileId: Number(profileId),
      selected: selectedForGeip == 'yes' ? true : selectedForGeip == 'no' ? false : null,
      internshipProgram: geipInternshipProgram ? geipInternshipProgram.value : null,
    };
    if (profileId) {
      this.setState({ selectedForGeip }, () => {
        this.props.editCandidateProfile(data).then(() => {
          this.props.getCandidateProfile(profileId).then(() => {
            this.setState({ suspendAccountLoader: false });
          });
        });
      });
    }
  }

  handleRedirectToJobPage = jobId => {
    const { history: { push } = {} } = this.props;
    if (push) {
      push(`/app/campus/job/${jobId}`);
    }
  };

  handleToggleChange = (scoringStudentId, facultyPreferenceRank) => {
    const data = {
      scoringStudentId,
    };
    if (!facultyPreferenceRank) {
      data.facultyPreferenceRank = 1;
    } else if (facultyPreferenceRank) {
      data.facultyPreferenceRank = null;
    }
    const {
      match: { params: { studentProfileId } = {} } = {},
      user: { roleId } = {},
    } = this.props;
    const { activePage, itemsPerPage } = this.state;
    data.roleId = roleId;
    this.props
      .updateStudentProfileJobMatches(data)
      .then(res => {
        if (res && !res.error) {
          const finalData = {
            studentProfileId,
            activePage,
            itemsPerPage,
            searchKey: this.state.searchKey,
          };
          this.props.getStudentProfileJobMatches(finalData);
        }
      })
      .catch(e => console.log('Error', e));
  };

  handleSubmitStudentPreference = scoringStudentId => {
    const {
      candidateProfile: { studentprofile: { studentProfileId } = {} } = {},
      user: { roleId } = {},
    } = this.props;
    const { activePage, itemsPerPage } = this.state;
    if (!this.state[`studentPreferenceRank_${scoringStudentId}`]) {
      this.setState({
        [`validMsg_${scoringStudentId}`]: true,
        isValidStudentPrefRankMsg: 'Please select valid rank',
      });
    } else {
      this.setState(
        {
          [`validMsg_${scoringStudentId}`]: false,
          isValidStudentPrefRankMsg: '',
        },
        () => {
          const data = {
            scoringStudentId,
            studentPreferenceRank: this.state[
              `studentPreferenceRank_${scoringStudentId}`
            ],
            roleId,
          };
          this.props
            .updateStudentProfileJobMatches(data)
            .then(res => {
              if (res && !res.error) {
                const finalData = {
                  studentProfileId,
                  activePage,
                  itemsPerPage,
                };
                this.props.getStudentProfileJobMatches(
                  finalData,
                  'ihlpreferred'
                );
              }
            })
            .catch(e => console.log('Error', e));
        }
      );
    }
  };

  handleSetCandidateAsHired = (jobId, profileId) => {
    const data = {
      jobId: jobId && Number(jobId),
      profileId: profileId && Number(profileId),
    };
    const { match: { params: { studentProfileId } = {} } = {} } = this.props;
    const { activePage, itemsPerPage } = this.state;
    this.props
      .addStudentToSetAsHired(data)
      .then(res => {
        if (res && !res.error) {
          const finalData = {
            studentProfileId,
            activePage,
            itemsPerPage,
          };
          this.props.getStudentProfileJobMatches(finalData, null);
        } else if (res && res.error && res.error.code === 'x0pa-94') {
          this.setState({
            showHiredJobvacerr: true,
            currJobId: jobId
          })
        } else {
          this.setState({
            showHiredErr: true,
            currJobId: jobId,
          });
        }
      })
      .catch(e => {
        console.log('Error:::::', e);
        this.setState({
          showHiredErr: true,
        });
      });
  };

  handleAddtoJob = (jobId, profileId) => {
    const { match: { params: { studentProfileId } = {} } = {} } = this.props;
    const { activePage, itemsPerPage } = this.state;
    const data = {
      jobId: Number(jobId),
      profileId: Number(profileId),
    };
    this.props
      .addStudentProfileToJob(data)
      .then(res => {
        if (res && !res.error) {
          const finalData = {
            studentProfileId,
            activePage,
            itemsPerPage,
          };
          this.setState({
            showAddToJobSuccessModal: true
          }, () => {
            setTimeout(() => {
              this.dismissAddToJobSuccessModal();
            }, 5000);
          })
          this.props.getStudentProfileJobMatches(finalData, null);
        } else if (res && res.error && res.error.code === 'x0pa-94') {
          this.setState({
            showJobvacanicesErr: true,
            currJobId: jobId
          })
        }
        else if (res && res.error && res.error.code === 'x0pa-96') {
          this.setState({
            showJobApplyLimitErr: true,
            currJobId: jobId
          })
        }
      })
      .catch(e => console.log('Error:::::', e));
  };

  dismissAddToJobSuccessModal = () => {
    this.setState({ showAddToJobSuccessModal: false })
  }

  handleRedirectRegister = () => {
    const { 
      history: { push } = {},
      user: { profileId } = {}, 
    } = this.props || {};
    if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/0`);
    }
  };

  handleSaveStudentPref = () => {
    const { jobMatchesPreferences } = this.state;
    const { user: { roleId } = {}, candidateProfile } = this.props;
    const { activePage, itemsPerPage } = this.state;
    const { studentprofile: { studentProfileId } = {} } =
      candidateProfile || {};
    if (
      jobMatchesPreferences &&
      Array.isArray(jobMatchesPreferences) &&
      jobMatchesPreferences.length > 0
    ) {
      jobMatchesPreferences.map((res, idx) => {
        const { scoringStudentId } = res || {};
        const data = {
          scoringStudentId,
          roleId,
          studentPreferenceRank: idx + 1,
        };
        return this.props
          .updateStudentProfileJobMatches(data)
          .then(vals => {
            if (vals && !vals.error) {
              const finalData = {
                studentProfileId,
                activePage,
                itemsPerPage,
              };
              this.setState(
                {
                  showNotifInfo: true,
                },
                () => {
                  this.props.getStudentProfileJobMatches(
                    finalData,
                    'studentpreferred'
                  );
                }
              );
            }
          })
          .catch(e => console.log('Error:::::', e));
      });
    }
  };

  setPreferences = (data, name) => {
    this.setState({ [name]: data });
  };

  onEmailTemplateChange = e => {
    const selectedEmailTemplate = e.target.value;
    this.setState({ selectedEmailTemplate, noTemplateError: false });
  };

  templateModalSubmit = () => {
    const { selectedEmailTemplate } = this.state;
    const {
      candidateProfile: { profile: { firstName, lastName, email } = {} } = {},
      match: { params: { profileId } = {} } = {},
    } = this.props;
    const templateId = selectedEmailTemplate.split('_')[0];
    const templateName = selectedEmailTemplate.split('_')[1];
    const emailData = [
      {
        email,
        profileId,
        payload: {
          studentFirstName: firstName,
          studentLastName: lastName,
        },
      },
    ];
    const data = {
      templateId,
      templateName,
      emailData,
      actionName: 'user-defined-email',
    };
    this.setState(
      {
        // selectedEmailTemplate: 'placeholder-item',
        selectedEmails: [],
        noTemplateError: false,
      },
      () => {
        this.props.sendEmails(data).then(res => {
          if (res && !res.error) {
            this.setState(
              {
                showSuccessMsg: true,
              },
              () => {
                setTimeout(() => {
                  this.dismissEmailModal();
                }, 5000);
                this.props.getStudentProfileEmails(profileId);
              }
            );
          } else if (res && res.error) {
            this.setState({
              noTemplateError: true,
            });
          }
        });
      }
    );
  };

  columnSortJobList = ({ propName }) => {
    const { ascDesc, currentSortProp } = this.state;
    let sortVal = 'DESC';
    if (currentSortProp && currentSortProp == propName) {
      sortVal = ascDesc && ascDesc == 'DESC' ? 'ASC' : 'DESC';
    } else {
      sortVal = 'DESC';
    }

    const qs = ascDesc => `filter[order][0]=${propName === "recToStd" ? "facultyPreferenceRank" : propName}%20${ascDesc}`;
    this.setState(
      {
        ascDesc: sortVal,
        sortParam: qs(sortVal),
        activePage: 1,
        currentSortProp: propName,
      },
      () => {
        this.handlePaginationChange({
          activePage: 1,
          itemsPerPage: this.state.itemsPerPage,
        });
      }
    );
  };

  handleSearchSubmit = event => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    console.log('event', event);
    this.setState(
      {
        keyword: event.target.value,
        searchKey: event.target.value,
        activePage: 1,
        itemsPerPage: this.state.itemsPerPage,
      },
      () => {
        this.searchTimer = setTimeout(() => {
          this.handlePaginationChange({
            activePage: 1,
            itemsPerPage: this.state.itemsPerPage,
          });
        }, 1000);
      }
    );
  };

  handleCheckBoxChange = (e, cityName, cityId) => {
    let { cityArr } = this.state;
    if (e === true) {
      if (
        !cityArr ||
        (cityArr && Array.isArray(cityArr) && cityArr.length <= 0)
      ) {
        cityArr = [cityName];
      } else {
        cityArr.push(cityName);
      }
    }
    this.setState(
      {
        [`name-${cityId}`]: cityName,
        [`city-${cityId}`]: e,
        cityArr,
      },
      () => {
        let filteredArr = [];
        if (e === false) {
          if (
            this.state.cityArr &&
            Array.isArray(this.state.cityArr) &&
            this.state.cityArr.length <= 1
          ) {
            filteredArr = [];
          } else if (
            this.state.cityArr &&
            Array.isArray(this.state.cityArr) &&
            this.state.cityArr.length > 1
          ) {
            filteredArr = this.state.cityArr.filter(res => res != cityName);
          }
          this.setState({
            cityArr: filteredArr,
          });
        }
      }
    );
  };

  activeCitiesModal = () => {
    this.setState({
      isOpenCityModal: true,
    });
  };

  dismissCitiesModal = () => {
    this.setState({
      isOpenCityModal: false,
    });
  };

  handleSubmitCitiesModal = () => {
    const { cityArr } = this.state;
    let cl = null;
    if (cityArr && cityArr.length > 0) {
      cl = cityArr;
    }
    this.setState(
      {
        citiesList: cl,
      },
      () => {
        this.handlePaginationChange();
        this.dismissCitiesModal();
      }
    );
  };

  handlClearFilter = () => {
    this.setState({
      cityArr: [],
      citiesList: null,
    });
  };

  handleDetailsTabClick = tabType => {
    let detailsTab = 0;
    switch (tabType) {
      case 'student-info':
        detailsTab = 0;
        break;
      case 'bio-info':
        detailsTab = 1;
        break;
      case 'preferences':
        detailsTab = 2;
        break;
      case 'documents':
        detailsTab = 3;
        break;
      case 'offer':
        detailsTab = 4;
        break;
      default:
        detailsTab = 0;
        break;
    }
    this.setState({
      detailsTab,
    });
  };

  handleEditStdsProf = () => {
    let {
      history: { push } = {},
      match: { params: { profileId } = {} } = {},
      user: { roleId }
    } = this.props;
    if(roleId == 3){
      const { user: { profileId: currProfileId } = {} } = this.props;
      profileId = currProfileId
    }
    if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/0`);
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    if (name === "batch") {
      this.setState({ showSelectBatchError: false })
    }
    this.setState({
      [name]: metaValue
    })
  }

  onBatchChange = (profileId, batch) => {
    if (batch === null || batch === undefined) {
      this.setState({ showSelectBatchError: true })
      return;
    }
    let data = {
      profileId: Number(profileId),
      batch: batch.label,
    };
    if (profileId) {
      this.setState({ batchLoader: true }, () => {
        this.props.editCandidateProfile(data).then(() => {
          this.setState({
            showNotifInfo: true
          })
          this.props.getCandidateProfile(profileId).then(() => {
            this.setState({ batchLoader: false });
          });
        });
      });
    }
  };

  onTierSave = (profileId, tierValue) => {
    if (!tierValue) {
      this.setState({ showTierSaveError: true, tierErrMsg: "This field cannot be empty" })
      return;
    }
    if(tierValue && Number(tierValue) < 0){
      this.setState({showTierSaveError: true, tierErrMsg: "Tier value couldn't be less than 0"})
      return;
    }
    let data = {
      profileId: Number(profileId),
      tier: tierValue,
    };
    if (profileId) {
      this.setState({ tierLoader: true }, () => {
        this.props.editCandidateProfile(data).then(() => {
          this.props.getCandidateProfile(profileId).then(() => {
            this.setState({ 
              tierLoader: false,
              showTierSaveSuccess: true
            },()=>{
              setTimeout(()=>{
                this.setState({
                  showTierSaveSuccess: false
                })
              }, 5000)
            });
          });
        });
      });
    }
  };

  handleUploadFile = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const {
      candidateProfile,
    } = this.props;
    const {
      profile,
    } = candidateProfile || {};
    const { profileId } = profile || {};
    // if (fileName === null || selectedType === null || profileId === null) return;
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    this.setState({
      showDocLoading: true,
    }, () => {
      this.props.uploadCampusDocs(currFile).then(({ result: { files = {} } = {} }) => {
        const { file: [fileData = {}] = [] } = files || {};
        const { providerResponse: { location: subLocation } = {} } = fileData !== null ? fileData : {};
        this.props.uploadSignedOfferDocument({
          typeId: 12,
          fileName: `Signed Offer Letter ${profileId}`,
          subLocation,
          profileId,
          uploadTimestamp: new Date(),
        }).then((docs) => {
          if (docs && !docs.error) {
            this.props.getCandidateProfile(profileId);
            this.setState({
              files: [],
              showDocLoading: false,
            });
          }
        });
      }).catch(err => console.log(err));
    });
  }

  handleInactivityStatus = (e, studentUserId) => {
    this.setState({
      inactivityStatusLoading: true,
    }, () => {
      this.props.upsertUserInfoDetails({ inactivityStatus: !e, userId: studentUserId && Number(studentUserId) }).then((res) => {
        if (res && !res.error) {
          const {
            history: { push } = {},
            user: { roleId } = {},
            match: {
              params: { profileId, studentProfileId, tabName: paramTabName } = {},
            } = {},
          } = this.props;
          this.props.getCandidateProfile(profileId);
          this.setState({ inactivityStatusLoading: false })
        }
      })
    })
  }

  handleTabClick = (tab) => {
    if (tab === 'prof-info') {
      this.setState({
        currStdProfTab: 0,
      });
    } else {
      this.setState({
        currStdProfTab: 1,
      });
    }
  }

  handleStudentProfileInfoTabClick = (propValue) => {
    const props = propValue.split('-');
    const selectedTabBelongsTo = props[0]; 
    const tabIndex = props[1];
    this.setState({
      selectedTabBelongsTo,
      tabIndex
    })
  }

  handleTierValueChange = (e) => {
    const stateProps = {};
    if(Number(e.target.value) >= 0){
      stateProps.showTierSaveError = false;
      stateProps.tierErrMsg = "";
    }
    this.setState({
      ...stateProps,
      tierValue: e.target.value
    });
  }

  handleShowFeedback = () => {
    this.setState({
      showFeedback: true,
    });
  };

  handleEditFeedback = () => {
    this.setState({
      editFeedback: true,
    });
  }

  handleHideForm = () => {
    this.setState({
      showFeedback: false,
      editFeedback: false,
    });
  }

  handleChangeRadioBtn = (name, value) => {
    this.setState({
      [name]: value,
    });
  }

  handleSubmitFeedbackForm = (profileFeedbackId) => {
    const {
      enterprising,
      innovative,
      resilience,
      resilience2,
      hardskills,
      skillbasedachievements,
      initiativeProactive,
      resourcefulness,
      motivation,
      independence,
      backgroundnotes,
      recommendation,
      enterprisingRemarks,
      innovativeRemarks,
      resilienceRemarks,
      resilience2Remarks,
      hardskillsRemarks,
      skillbasedachievementsRemarks,
      initiativeProactiveRemarks,
      resourcefulnessRemarks,
      motivationRemarks,
      independenceRemarks,
    } = this.state;
    let enterprisingInvalid = false;
    let innovativeInvalid = false;
    let resilienceInvalid = false;
    let resilience2Invalid = false;
    let hardskillsInvalid = false;
    let skillbasedachievementsInvalid = false;
    let initiativeProactiveInvalid = false;
    let resourcefulnessInvalid = false;
    let motivationInvalid = false;
    let independenceInvalid = false;
    let recommendationInvalid = false;
    if (!enterprising) {
      enterprisingInvalid = true;
    } else {
      enterprisingInvalid = false;
    }

    if (!innovative) {
      innovativeInvalid = true;
    } else {
      innovativeInvalid = false;
    }

    if (!resilience) {
      resilienceInvalid = true;
    } else {
      resilienceInvalid = false;
    }

    if (!resilience2) {
      resilience2Invalid = true;
    }

    if (!hardskills) {
      hardskillsInvalid = true;
    } else {
      hardskillsInvalid = false;
    }

    if (!skillbasedachievements) {
      skillbasedachievementsInvalid = true;
    } else {
      skillbasedachievementsInvalid = false;
    }

    if (!initiativeProactive) {
      initiativeProactiveInvalid = true;
    } else {
      initiativeProactiveInvalid = false;
    }

    if (!resourcefulness) {
      resourcefulnessInvalid = true;
    } else {
      resourcefulnessInvalid = false;
    }

    if (!motivation) {
      motivationInvalid = true;
    } else {
      motivationInvalid = false;
    }

    if (!independence) {
      independenceInvalid = true;
    } else {
      independenceInvalid = false;
    }

    if (!recommendation) {
      recommendationInvalid = true;
    } else {
      recommendationInvalid = false;
    }

    this.setState({
      'invalid-enterprising': enterprisingInvalid,
      'invalid-innovative': innovativeInvalid,
      'invalid-resilience': resilienceInvalid,
      'invalid-resilience2': resilience2Invalid,
      'invalid-hardskills': hardskillsInvalid,
      'invalid-skillbasedachievements': skillbasedachievementsInvalid,
      'invalid-initiativeProactive': initiativeProactiveInvalid,
      'invalid-resourcefulness': resourcefulnessInvalid,
      'invalid-motivation': motivationInvalid,
      'invalid-independence': independenceInvalid,
      recommendationInvalid,
    }, () => {
      const {
        candidateProfile: {
          profile: {
            profileId,
          } = {},
        } = {},
      } = this.props;
      if (this.state['invalid-enterprising'] !== true && this.state['invalid-innovative'] !== true
        && this.state['invalid-resilience'] !== true && this.state['invalid-resilience2'] !== true && this.state['invalid-hardskills'] !== true
        && this.state['invalid-skillbasedachievements'] !== true && this.state['invalid-initiativeProactive'] !== true
        && this.state['invalid-resourcefulness'] !== true && this.state['motivation'] !== true
        && this.state['invalid-independence'] !== true && this.state.recommendationInvalid !== true) {
        const data = {
          enterprising,
          innovative,
          resilience,
          resilience2,
          hardskills,
          skillbasedachievements,
          initiativeProactive,
          resourcefulness,
          motivation,
          independence,
          backgroundnotes,
          recommendation,
          enterprisingRemarks,
          innovativeRemarks,
          resilienceRemarks,
          resilience2Remarks,
          hardskillsRemarks,
          skillbasedachievementsRemarks,
          initiativeProactiveRemarks,
          resourcefulnessRemarks,
          motivationRemarks,
          independenceRemarks,
        };

        if (profileFeedbackId) {
          data.profileFeedbackId = profileFeedbackId;
          this.props.upsertInterviewFeedback(data).then((res) => {
            if (res && !res.error) {
              this.setState({
                showSuccessFeedbackMsg: true,
                showFeedback: false,
                editFeedback: false,
              }, () => {
                this.props.getInterviewFeedback(profileId);
              });
            }
          });
        } else {
          data.profileId = profileId;
          this.props.createInterviewFeedback(data).then((res) => {
            if (res && !res.error) {
              this.setState({
                showSuccessFeedbackMsg: true,
                showFeedback: false,
                editFeedback: false,
              }, () => {
                this.props.getInterviewFeedback(profileId);
              });
            }
          });
        }
      }
    });
  }

  handleSaveFeedbackdata = () => {
    const {
      interviewFeedback,
      user: {
        userId,
      } = {},
    } = this.props;
    let filteredList = [];
    if (interviewFeedback && Array.isArray(interviewFeedback) && interviewFeedback.length > 0) {
      filteredList = interviewFeedback.filter(res => res.userId == userId);
    }
    const finalData = filteredList && Array.isArray(filteredList) && filteredList.length > 0 && filteredList[0];
    this.setState({
      ...finalData,
    });
  }

  render() {
    const {
      t,
      history: { push } = {},
      user: {
        roleId,
        profileId:userProfileId,
        userId,
        isSuperAdmin,
        isAdmin,
        courseAdmin: isCourseAdmin,
        facultyId: userFacultyId,
        currentCompany: {
          ctypeId
        } = {},
        firstname,
        lastname,
      } = {},
      scores = [],
      candidateProfile,
      count,
      emails,
      emailTemplates,
      cities,
      offerAcceptedCount,
      user,
      countries,
      taskId,
      interviewFeedback,
    } = this.props;
    let {
      match: { params: { profileId } = {} } = {},
    } = this.props || {};
    if(roleId == 3){
      profileId = userProfileId;
    }
    const {
      studentprofile: { isAppSubmitted, courses, faculty, facultyId } = {},
      profile,
      documents,
      userProfile,
      videointerview,
    } = candidateProfile || {};
    const {
      courseName,
    } = courses || {};
    const {
      facultyName,
    } = faculty || {};
    const {
      userinfo: {
        inactivityStatus,
        userId: studentUserId
      } = {}
    } = userProfile || {};
    let userFeedbackDone = false;
    if (interviewFeedback && Array.isArray(interviewFeedback) && interviewFeedback.length > 0) {
      const filteredFeedback = interviewFeedback.find(res => res.userId == userId);
      const {
        userId: propUserId,
      } = filteredFeedback || {};
      if (propUserId) {
        userFeedbackDone = true;
      } else {
        userFeedbackDone = false;
      }
    }
    const { suspended, selected, firstName, lastName } = profile || {};
    const {
      selectedTab,
      showLoader,
      showError,
      errorCode,
      jobMatchesPreferences,
      activePage,
      isOpenEmailModal,
      selectedEmailTemplate,
      showSuccessMsg,
      noTemplateError,
      showNotifInfo,
      isOpenCityModal,
      citiesList,
      cityArr,
      detailsTab,
      batch,
      candidateSelectedTab,
      batchLoader,
      showAddToJobSuccessModal,
      showSelectBatchError,
      batchArr,
      currStdProfTab,
      selectedTabBelongsTo,
      tabIndex,
      showTierSaveSuccess,
      showTierSaveError,
      tierValue,
      tierErrMsg,
      tierLoader,
      multiValueStudentProfileProps,
      showFeedback,
      showSuccessFeedbackMsg,
    } = this.state;
    console.log("selectedTab",selectedTab)
    let showStudenProfileDetails = false;
    if (userFacultyId == facultyId) {
      showStudenProfileDetails = true;
    }
    const finalEmailTemplates =
      emailTemplates &&
      Array.isArray(emailTemplates) &&
      emailTemplates.length > 0 &&
      emailTemplates.filter(
        res =>
          res &&
          res.ownerId &&
          Number(res.ownerId) === (userId && Number(userId))
      );
    let filteredDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      filteredDocs = documents.filter(res => res.typeId === 12);
    }
    const tabContent = [];
    const candidateTabContent = [];
    if (roleId && Number(roleId) === 3) {
      candidateTabContent.push(
        // {
        //   label: `${t('instRecommended')}`,
        //   value: '',
        //   onClick: () => {
        //     this.handleCandidateTabClick('inst-rec');
        //   },
        // },
        // {
        //   label: `${t('xopaRecommended')}`,
        //   value: '',
        //   selected: true,
        //   onClick: () => {
        //     this.handleCandidateTabClick('x0pa-rec');
        //   },
        // }
      );
    }
    tabContent.push(
      {
        label: `${t('profileViews')}`,
        value: '',
        onClick: () => {
          this.handleProfileViewTabClick('profile-view');
        },
      },
    )
    if (roleId !== 3) {
      tabContent.push(
        // {
        //   label: `${t('profileViews')}`,
        //   value: '',
        //   onClick: () => {
        //     this.handleProfileViewTabClick('profile-view');
        //   },
        // },
        {
          label: `${t('jobMatches')}`,
          value: '',
          selected: true,
          onClick: () => {
            this.handleProfileViewTabClick('job-matches');
          },
        },
        {
          label: `${t('x0paAssigned')}`,
          value: '',
          selected: true,
          onClick: () => { this.handleProfileViewTabClick('institution-assigned'); },
        },
        {
          label: `${t('studentPreferred')}`,
          value: '',
          selected: true,
          onClick: () => { this.handleProfileViewTabClick('student-preferred'); },
        },
        {
          label: `${t('studentSlots')}`,
          value: '',
          selected: true,
          onClick: () => { this.handleProfileViewTabClick('student-slots'); },
        },
        {
          label: "Video Interviews",
          value: '',
          selected: true,
          onClick: () => {
            this.handleProfileViewTabClick('video-int');
          },
        }
      );
      // if (isSuperAdmin === true || isAdmin === true) {
      //   tabContent.push({
      //     label: `${t('email')}`,
      //     value: '',
      //     selected: true,
      //     onClick: () => {
      //       this.handleProfileViewTabClick('email');
      //     },
      //   });
      // }
      tabContent.push({
        label: `${t('settings')}`,
        value: '',
        selected: true,
        onClick: () => {
          this.handleProfileViewTabClick('student-settings');
        },
      });
      tabContent.push({
        label: `${t('interview')} ${t('feedback')}`,
        value: '',
        selected: true,
        onClick: () => {
          this.handleProfileViewTabClick('interview-feedback');
        },
      });
    }
    const columnWithFilter = [];
    const headersEmails = [
      {
        title: `${t('templateName')}`,
        className: 'text-left',
        name: 'templateName',
        propName: 'templateName',
      },
      {
        title: `${t('email')}`,
        className: 'text-left',
        name: 'email',
        propName: 'email',
      },
      {
        title: `${t('postDate')}`,
        className: 'text-left',
        name: 'postDate',
        propName: 'postDate',
      },
    ];
    columnWithFilter.push(
      {
        title: `${t('job')} ${t('name')}`,
        className: 'text-left col-2',
        name: 'jobName',
        propName: 'jobName',
      },
      // {
      //   title: `${t('city')}`,
      //   className: 'text-left',
      //   name: 'city',
      //   propName: 'city',
      // },
      {
        title: `${t('company')} ${t('name')}`,
        className: 'text-left',
        name: 'job.company.companyName',
        propName: 'job.company.companyName',
      }
      // {
      //   title: `${t('job')} ${t('skills')}`,
      //   className: 'text-center',
      //   name: 'jobId',
      //   propName: 'jobId',
      // },
    );
    if (roleId !== 3) {
      if (selectedTab === 1 || selectedTab === 2 || selectedTab === 3) {
        columnWithFilter.push(
          {
            title: `${t('skillScore')}`,
            className: 'text-left',
            name: 'skillScore',
            propName: 'skillScore',
          },
          {
            title: `${t('compatibilityScore')}`,
            className: 'text-left',
            name: 'compatibilityScore',
            propName: 'compatibilityScore',
          },
          {
            title: `${t('finalScore')}`,
            className: 'text-left',
            name: 'finalScore',
            propName: 'finalScore',
          }
        );
      }
      if ((selectedTab === 1 || selectedTab === 2 || selectedTab === 3) && roleId !== 3 && offerAcceptedCount <= 0) {
        columnWithFilter.push(
          {
            title: `${t('recToStd')}`,
            className: 'text-center',
            name: 'recToStd',
            propName: 'recToStd',
          },
          {
            title: `${t('add')} ${t('to')} ${t('job')}`,
            className: 'text-center',
            name: 'actions',
            propName: 'actions',
          },
          {
            title: 'Hire Candidate',
            className: 'text-center',
            name: 'hire',
            propName: 'hire',
          }
        );
      }
      // if (selectedTab === 3) {
      //   columnWithFilter.push(
      //     {
      //       title: `${t('studentPreferred')}`,
      //       className: 'text-center',
      //       name: 'actions',
      //       propName: 'actions',
      //     },
      //     {
      //       title: `${t('action')}`,
      //       className: 'text-center',
      //       name: 'actions',
      //       propName: 'actions',
      //     },
      //   );
      // }
    }

    const studentTabContent = [
      {
        label: "Uploaded Image",
        value: '',
        selected: true,
        onClick: () => {
          this.handleStudentProfileInfoTabClick('geipComp', '0');
        },
      },
      // {
      //   label: "Uploaded CV",
      //   value: '',
      //   onClick: () => {
      //     this.handleStudentProfileInfoTabClick('geipComp', '1');
      //   },
      // },
      {
        label: "Reason for GEIP",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('geipComp', '2');
        },
      },
      {
        label: "Bio/Academia Information Part I",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('geipComp', '3');
        },
      },
      {
        label: "Bio/Academia Information Part II",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '1');
        },
      },
      {
        label: "Skills Profeciency",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '2');
        },
      },
      {
        label: "Skills you would like to learn",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '3');
        },
      },
      {
        label: "Individual Preferences",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '4');
        },
      },
      {
        label: "Industry, Job Function",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '5');
        },
      },
      {
        label: "Documents",
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('icComp', '6');
        },
      }
      
      // {
      //   label: t('geipMsg1'),
      //   value: '',
      //   selected: true,
      //   onClick: () => {
      //     this.handleDetailsTabClick('student-info');
      //   },
      // },
      // {
      //   label: t('geipMsg2'),
      //   value: '',
      //   onClick: () => {
      //     this.handleDetailsTabClick('bio-info');
      //   },
      // },
      // {
      //   label: t('preferences'),
      //   value: '',
      //   onClick: () => {
      //     this.handleDetailsTabClick('preferences');
      //   },
      // },
      // {
      //   label: t('documents'),
      //   value: '',
      //   onClick: () => {
      //     this.handleDetailsTabClick('documents');
      //   },
      // },
    ];
    if (offerAcceptedCount > 0) {
      studentTabContent.push({
        label: t('signedUpdocs'),
        value: '',
        onClick: () => {
          this.handleStudentProfileInfoTabClick('offerComp', '4');
        },
      });
    }
    const otherActionsBtn = [];
    otherActionsBtn.push(
      <ToolbarItem>
        <Button className="mr-2" onClick={this.activeEmailModal} kind="primary">
          {t('send')} {t('email')}
        </Button>
      </ToolbarItem>
    );
    const cityActionsBtn = [];

    const mainTabContent = [
      {
        label: `${t('personalInfo')}`,
        value: '',
        onClick: () => {
          this.handleTabClick('prof-info');
        },
      }]
    // if (roleId != 3) {
    //   mainTabContent.push(
    //     {
    //       label: "Video Interviews",
    //       value: '',
    //       selected: true,
    //       onClick: () => {
    //         this.handleTabClick('video-int');
    //       },
    //     })
    // }
    /*cityActionsBtn.push(
      <ToolbarItem>
        <Button
          className="mr-2"
          onClick={this.activeCitiesModal}
          kind="tertiary"
        >
          {t('filter')}
          {
            citiesList && Array.isArray(citiesList) && citiesList.length > 0
              && (
                <strong className="badge p-1 ml-2" style={{ backgroundColor: 'rgb(62, 165, 89)' }}>{citiesList.length}</strong>
              )
          }
        </Button>
      </ToolbarItem>,
    );*/
    return (
      <Fragment>
        {roleId !== 3 && <BackRow paths={this.paths} />}
        {roleId === 3 && <BackRow paths={this.candPaths} />}
        {roleId === 3 &&
          isAppSubmitted !== true &&
          (showStudenProfileDetails || isSuperAdmin === true) && (
            <Tile className="xpa-banner-color mb-2">
              <div className="d-flex ml-3 align-items-center">
                <div>
                  <h6 className="font-weight-bold mb-0 mr-3">
                    {t('registerGeipHeading')}
                  </h6>
                </div>
                <div>
                  <Button
                    onClick={this.handleRedirectRegister}
                    small
                    kind="primary">
                    {t('submitApplication')}
                  </Button>
                </div>
              </div>
            </Tile>
          )
        }
        <GenericTabs
          selected={currStdProfTab}
          tabContent={mainTabContent}
        />

        {currStdProfTab === 0 &&
          (
            <>
              <StudentProfileRegister
                selectedTab={selectedTab}
                detailsTab={null}
                hideBioEcd
                showSteps={false}
                history={this.props.history}
                match={this.props.match}
              />
              {(roleId == 3 || showStudenProfileDetails || isSuperAdmin === true) && (
                  <GenericTabs selected={selectedTab} tabContent={tabContent} />
                )}
              {/* {roleId && Number(roleId) === 3 && (
                <GenericTabs
                  selected={candidateSelectedTab}
                  tabContent={candidateTabContent}
                />
              )} */}
              {showStudenProfileDetails === false && isSuperAdmin !== true && (
                <div className="h6 text-center">{t('noAccessToWebPage')}</div>
              )}
              {selectedTab === 0 &&
                // (showStudenProfileDetails || isSuperAdmin === true) &&
                // roleId !== 3 && 
                (
                  <Fragment>
                    <div className="mb-2 mt-2 ml-1 bx--type-delta">
                      {t('student')} {t('profile')}
                    </div>
                    {isAppSubmitted !== true && (
                      <InlineNotification
                        lowContrast
                        className="mt-1 mb-1"
                        title=""
                        iconDescription="describes the close button"
                        kind="error"
                        subtitle={t('applMsg')}
                        onCloseButtonClick={() => { }}
                      />
                    )}
                    {(isAdmin === true || isSuperAdmin === true || roleId == 3 ) && (
                      <InlineNotification
                        lowContrast
                        kind="info"
                        className=""
                        title=""
                        subtitle={
                          <div className="d-flex align-items-center">
                            <div>{t('clickToEdit')}</div>
                            <div className="ml-2">
                              <Button
                                kind="ghost"
                                size="small"
                                onClick={this.handleEditStdsProf}>
                                {t('editProfile')}
                              </Button>
                            </div>
                          </div>
                        }
                        hideCloseButton
                        onCloseButtonClick={() => { }}
                      />
                    )}
                    {/* <div>
                      <GenericTabs
                        className="mb-2"
                        // selected={detailsTab}
                        tabContent={studentTabContent}
                      />
                    </div> */}
                    <hr className = "m-0" />
                    <div className="student-profile-view-layout">
                      <Layout
                        sideContent={
                          <Facets>
                            {multiValueStudentProfileProps &&
                              multiValueStudentProfileProps.map((Multiprop) => (
                                <SingleLinksFacet {...{ ...Multiprop }} />
                              ))}
                          </Facets>
                        }
                        bodyContent={
                          <div >
                            {selectedTabBelongsTo == 'geipComp' && 
                              <div className="">
                                <GeipDetails allCountries={countries} needNotRedirect disabled profileId={profileId} push={push} taskId={taskId} indexId={tabIndex} ref="geipDetails" />
                              </div>
                            }
                            {selectedTabBelongsTo == 'icComp' && 
                              <div>
                                <InternCandidate hideProgress propStageIndex={tabIndex} profileId={profileId} disabled />
                              </div>
                            }
                            {selectedTabBelongsTo == 'offerComp' && Number(tabIndex) === 4 && (
                              <div>
                                <h6>Offer Documents</h6>
                                {filteredDocs &&
                                  Array.isArray(filteredDocs) &&
                                  filteredDocs.length > 0 ? (
                                  <div>
                                    {filteredDocs.map(res => {
                                      const {
                                        fileName,
                                        documenttype: { typeName } = {},
                                        subLocation,
                                        personDocId,
                                        typeId,
                                        cvLink
                                      } = res || {};
                                      return (
                                        <div key={personDocId} className="d-flex align-items-center">
                                          <div>Signed Offer Letter</div>
                                          <div className="ml-3">
                                            <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                                              <Button size="small" kind="tertiary">
                                                {t('view')} {t('file')}
                                              </Button>
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <div>
                                      <TextInput
                                        className="w-100 upload-docs-min-width mb-2"
                                        placeholder={t('docName')}
                                        disabled
                                        value="Signed Offer Letter"
                                        hideLabel={false}
                                        name="Signed Offer Letter"
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <div className="bx--file h-auto mx-auto text-center newjob-upload-jd" data-file>
                                        <label
                                          id="same"
                                          htmlFor="jd_upload"
                                          className="bx--btn bx--btn--sm bx--btn--secondary uploadFile"
                                          role="button"
                                          tabIndex="0"
                                        >
                                          {t('upload')}
                                        </label>
                                        <input
                                          type="file"
                                          className="bx--file-input"
                                          id="jd_upload"
                                          accept=".doc,.docx,.pdf,.jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
                                          onChange={this.handleUploadFile}
                                          name="uploadDocs"
                                          data-file-uploader
                                          data-target="[data-file-container]"
                                        />
                                        <div data-file-container className="bx--file-container"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        }
                      />
                    </div>
                    {/* <div>
                      <StudentProfileRegister
                        selectedTab={0}
                        detailsTab={detailsTab}
                        showSteps={false}
                        history={this.props.history}
                      />
                    </div> */}
                  </Fragment>
                )}
                {selectedTab === 5 && (
                  <CandidateVideoInterview
                    profileId={profileId}
                    history={this.props.history}
                    match={this.props.match}
                  />
                )}
                {selectedTab === 7 && (
                  <div className="ml-2">
                    {
                      showSuccessFeedbackMsg === true && (
                        <InlineNotification
                          lowContrast
                          kind="success"
                          title={t('feedbackSuccess')}
                          subtitle=""
                          hideCloseButton
                          onCloseButtonClick={() => {}}
                        />
                      )
                    }
                    <div className="h6">{t('feedbackForm')}</div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--col-lg-2">
                        {t('profileName')}:
                      </div>
                      <div className="text-capitalize">
                        {firstName} {lastName}
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--col-lg-2">
                        {t('profileTime')}
                      </div>
                      <div className="text-capitalize">
                        {moment().format('MMMM YYYY')}
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-2">
                      <div className="bx--col-lg-2">
                        {t('profilesSchool')}:
                      </div>
                      <div className="text-capitalize">
                        {facultyName}/{courseName}
                      </div>
                    </div>
                    <div className="mt-3">
                      {
                        interviewFeedback && Array.isArray(interviewFeedback) && interviewFeedback.length > 0 && (
                          <Accordion>
                            {
                              interviewFeedback.map((res) => {
                                const {
                                  userinfo,
                                } = res;
                                const {
                                  firstname: propFname,
                                  lastname: propLname,
                                } = userinfo || {};
                                return (
                                  <AccordionItem
                                    title={<div className="mb-0 bx--type-zeta xpa-link text-uppercase">{propFname} {propLname}</div>}
                                    className="content-padding"
                                  >
                                    <InterviewFeedbackForm
                                      showFeedback
                                      hideAddFeedBtn
                                      handleShowFeedback={this.handleShowFeedback}
                                      handleEditFeedback={this.handleEditFeedback}
                                      editFeedback={this.state.editFeedback}
                                      handleHideForm={this.handleHideForm}
                                      handleChange={this.handleChange}
                                      handleSubmitFeedbackForm={this.handleSubmitFeedbackForm}
                                      handleChangeRadioBtn={this.handleChangeRadioBtn}
                                      firstname={propFname}
                                      lastname={propLname}
                                      propUserId={res.userId}
                                      userId={userId}
                                      userFeedbackDone={userFeedbackDone}
                                      state={this.state}
                                      responseData={res}
                                    />
                                  </AccordionItem>
                                )
                              })
                            }
                          </Accordion>
                        )
                      }
                    </div>
                    <InterviewFeedbackForm
                      hideAddFeedBtn={false}
                      showFeedback={showFeedback}
                      handleShowFeedback={this.handleShowFeedback}
                      handleHideForm={this.handleHideForm}
                      handleEditFeedback={this.handleEditFeedback}
                      editFeedback={this.state.editFeedback}
                      handleChange={this.handleChange}
                      handleSubmitFeedbackForm={this.handleSubmitFeedbackForm}
                      handleChangeRadioBtn={this.handleChangeRadioBtn}
                      firstname={firstname}
                      lastname={lastname}
                      userId={userId}
                      userFeedbackDone={userFeedbackDone}
                      state={this.state}
                    />
                  </div>
                )}
              {showLoader === true && showError === false && roleId !== 3 && (
                <Tile>
                  <div className="d-flex justify-content-center">
                    <Loading withOverlay={false} />
                  </div>
                </Tile>
              )}
              {
                (
                  (roleId !== 3 && (selectedTab === 1 || selectedTab === 2 || selectedTab === 3))
                  //  || (roleId === 3 && (candidateSelectedTab === 0 || candidateSelectedTab === 1))
                ) && (
                  <Fragment>
                    {selectedTab === 1 && (
                      <Fragment>
                        <div className="mb-2 mt-2 ml-1 bx--type-delta">
                          {t('jobMatches')}
                        </div>
                      </Fragment>
                    )}
                    {
                      selectedTab === 2 && (
                        <div className="mb-2 mt-2 ml-1 bx--type-delta">{t('institutionAssigned')}</div>
                      )
                    }
                    {
                      selectedTab === 3 && (
                        <Fragment>
                          <div className="mb-2 mt-2 ml-1 bx--type-delta">{t('studentPreferred')}</div>
                        </Fragment>
                      )
                    }
                    {scores && Array.isArray(scores) && showLoader === false && roleId!==3 && (
                      // && scores.length > 0
                      <GenericDataTable
                        className=""
                        otherActions={cityActionsBtn}
                        headers={columnWithFilter.map((data, index) => ({
                          key: `header_${index}`,
                          header: data.title,
                          className: data.className,
                          colpropName: data.propName,
                        }))}
                        rows={scores.map(res => {
                          const {
                            scoringStudentId,
                            job: {
                              jobId,
                              cities: { cityName } = {},
                              company: { companyName } = {},
                              jobname: { nameName } = {},
                              skills = [],
                            } = {},
                            // jobId,
                            facultyPreferenceRank,
                            studentPreferenceRank,
                            isMatchedJob,
                            skillScore,
                            compatibilityScore,
                            isJobAdded,
                            appId,
                            isHired,
                            finalScore,
                            choicesRecommendation,
                          } = res || {};
                          const configure = 3;
                          return {
                            isExpanded: true,
                            id: `row_${jobId}`,
                            header_0: (
                              <div className="p-1">
                                <div className="">
                                  <div className="xpa-link text-capitalize font-weight-bold">
                                    {roleId && Number(roleId) !== 3 && (
                                      <a
                                        target="_blank"
                                        href={`/app/campus/jobs/${jobId}/applied`}>
                                        {nameName}
                                      </a>
                                    )}
                                    {roleId && Number(roleId) === 3 && (
                                      <a
                                        target="_blank"
                                        href={`/app/campus/job/${jobId}`}>
                                        {nameName}
                                      </a>
                                    )}
                                  </div>
                                  {/* &nbsp;&nbsp; */}
                                  <div>
                                    <span className="text-dark" style = {{fontSize:"smaller"}}>Job Id: ({jobId})</span>
                                    {isMatchedJob === true && selectedTab !== 3 && (
                                      <div className="bx--tag bx--tag--community ml-2">
                                        {t('recommended')}
                                      </div>
                                    )}
                                  </div>
                                  {roleId &&
                                    Number(roleId) === 3 &&
                                    candidateSelectedTab === 1 && (
                                      <div className="bx--tag bx--tag--community ml-2">
                                        {t('recommended')}
                                      </div>
                                    )}
                                  {/* {
                                      choicesRecommendation === true && selectedTab === 3 && (
                                        <div className="bx--tag bx--tag--community ml-2">{t('recommended')}</div>
                                      )
                                    } */}
                                </div>
                                <div className="d-flex mt-2">
                                  {skills &&
                                    Array.isArray(skills) &&
                                    skills.length > 0 &&
                                    skills.map((skill, idx) => {
                                      const { skill: { skillName } = {} } = skill || {};
                                      return (
                                        <div className="d-flex">
                                          {idx <= 2 && (
                                            <div className="bx--tag bx--tag--custom text-capitalize xpa-name-overflow">
                                              {skillName}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            ),
                            // header_1: (
                            //   <div className="d-flex">
                            //     <div>
                            //       <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 16, height: 16 }} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-map-pin">
                            //         <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                            //         <circle cx="12" cy="10" r="3" />
                            //       </svg>
                            //     </div>
                            //     <div className="ml-1 mt-1">{cityName}</div>
                            //   </div>
                            // ),
                            header_1: (
                              <div className="text-capitalize text-dark">
                                {companyName}
                              </div>
                            ),
                            header_2: (
                              <div>
                                {roleId === 3 && (
                                  <div>
                                    {studentPreferenceRank ? (
                                      <div>{studentPreferenceRank}</div>
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                )}
                                {/* {
                                        roleId === 3 && (
                                          <Fragment>
                                            {
                                              selectedTab === 2 && (
                                                <div className="d-flex justify-content-center">
                                                  <div>
                                                    <Select
                                                      name={`studentPreferenceRank_${Number(scoringStudentId)}`}
                                                      labelText=""
                                                      disabled={configure > 3}
                                                      id={Number(scoringStudentId)}
                                                      onChange={e => this.handleChange(e)}
                                                      value={this.state[`studentPreferenceRank_${Number(scoringStudentId)}`]}
                                                      // invalid={`validMsg_${Number(scoringStudentId)}`}
                                                      // invalidText={isValidStudentPrefRankMsg}
                                                    >
                                                      <SelectItem text="Select" />
                                                      {
                                                        [1, 2, 3, 4, 5].map(val => (<SelectItem key={Number(val)} text={Number(val)} value={Number(val)} />))
                                                      }
                                                    </Select>
                                                  </div>
                                                  <div className="ml-2">
                                                    <Button disabled={configure > 3} onClick={() => this.handleSubmitStudentPreference(scoringStudentId)} kind="primary">{t('save')}</Button>
                                                  </div>
                                                </div>
                                              )
                                            }
                                          </Fragment>
                                        )
                                      } */}
                                <div>
                                  {roleId !== 3 && (
                                    <ColoredCircle
                                      className=" "
                                      noStyle
                                      getColor="getAbsoluteMatchingColor"
                                      value={
                                        skillScore
                                          ? Math.round(skillScore)
                                          : skillScore === null || skillScore <= 0
                                            ? 'N/A'
                                            : 0
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ),
                            header_3: (
                              <div>
                                <ColoredCircle
                                  className=" "
                                  noStyle
                                  getColor="getAbsoluteMatchingColor"
                                  value={
                                    compatibilityScore
                                      ? compatibilityScore <= 0
                                        ? 'N/A'
                                        : Math.round(compatibilityScore)
                                      : compatibilityScore === null
                                        ? 'N/A'
                                        : 0
                                  }
                                />
                              </div>
                            ),
                            header_4: (
                              <div>
                                <ColoredCircle
                                  className=" "
                                  noStyle
                                  getColor="getAbsoluteMatchingColor"
                                  value={
                                    finalScore
                                      ? finalScore <= 0
                                        ? 'N/A'
                                        : Math.round(finalScore)
                                      : finalScore === null
                                        ? 'N/A'
                                        : 0
                                  }
                                />
                              </div>
                            ),
                            header_5: (
                              <div>
                                {roleId !== 3 && (selectedTab === 1 || selectedTab === 2 || selectedTab === 3) && (
                                  <Toggle
                                    id={jobId}
                                    className="align-items-center"
                                    onToggle={() =>
                                      this.handleToggleChange(
                                        scoringStudentId,
                                        facultyPreferenceRank
                                      )
                                    }
                                    toggled={
                                      facultyPreferenceRank !== null ? true : false
                                    }
                                    labelA={t('no')}
                                    labelB={t('yes')}
                                    disabled={configure > 3}
                                  />
                                )}

                                {/* {
                                      roleId !== 3 && selectedTab === 3 && (
                                        <div>
                                          {
                                            studentPreferenceRank ? (
                                              <div>{studentPreferenceRank}</div>
                                            ) : '-'
                                          }
                                        </div>
                                      )
                                    } */}
                              </div>
                            ),
                            header_6: (
                              <div>
                                <div className="d-flex xpa-customize">
                                  {roleId !== 3 &&
                                    (selectedTab === 1 || selectedTab === 2 || selectedTab === 3) &&
                                    isJobAdded !== true && (
                                      <Button
                                        className="secondary"
                                        small
                                        onClick={() =>
                                          this.handleAddtoJob(jobId, profileId)
                                        }>
                                        {t('add')} {t('to')} {t('job')}
                                      </Button>
                                    )}
                                  {roleId !== 3 &&
                                    (selectedTab === 1 || selectedTab === 2 || selectedTab === 3) &&
                                    isJobAdded === true && (
                                      <div className="bx--tag bx--tag--green">
                                        Added
                                      </div>
                                    )}
                                </div>
                                {
                                  this.state.showJobvacanicesErr === true && this.state.currJobId === jobId && (
                                    <div className="mb-2 mt-2 small text-danger">
                                      This internship position is no longer available as it has been accepted by another student.
                                    </div>
                                  )
                                }
                                {
                                  this.state.showJobApplyLimitErr === true && this.state.currJobId === jobId && (
                                    <div className="mb-2 mt-2 small text-danger">
                                      The job apply limit exceeded.
                                    </div>
                                  )
                                }
                                {/* {
                            this.state.showAddToJobModal === true && (
                              <div className="mb-2 mt-2 small text-success">
                                Application created successfully.
                              </div>
                            )
                          } */}
                              </div>
                            ),
                            header_7: (
                              <div className="xpa-customize">
                                {roleId !== 3 && isHired === null && (
                                  <Button
                                    kind="tertiary"
                                    className="ml-2"
                                    small
                                    onClick={() =>
                                      this.handleSetCandidateAsHired(jobId, profileId)
                                    }>
                                    Set As Hired
                                  </Button>
                                )}
                                {isHired !== null && (
                                  <div className="bx--tag bx--tag--green">Hired</div>
                                )}
                                {
                                  this.state.showHiredJobvacerr === true && this.state.currJobId === jobId && (
                                    <div className="mb-2 mt-2 small text-danger">
                                      This internship position is no longer available as it has been accepted by another student.
                                    </div>
                                  )
                                }
                                {this.state.showHiredErr === true &&
                                  this.state.currJobId === jobId && (
                                    <div className="mb-2 mt-2 small text-uppercase text-danger">
                                      This candidate has already accepted an offer or the candidate has already applied the maximum number of applications indicated by the school.
                                    </div>
                                  )}
                              </div>
                            ),
                          };
                        })}
                        selectable={false}
                        searchable
                        isSortable
                        customSorting={this.columnSortJobList}
                        onSearchInputChange={this.handleSearchSubmit}
                        expandable={false}
                        batchActions={false}
                      />
                    )}
                    <Modal
                      open={showAddToJobSuccessModal}
                      success
                      passiveModal
                      modalHeading="Success!"
                      onRequestClose={this.dismissAddToJobSuccessModal}
                    >
                      <InlineNotification
                        lowContrast
                        kind="success"
                        title="Application created successfully"
                        subtitle=""
                        hideCloseButton
                        onCloseButtonClick={() => { }}
                      />
                    </Modal>
                    {scores && Array.isArray(scores) && scores.length > 0 && (
                      <Pagination
                        onChange={this.handlePaginationChange}
                        pageSizes={[10, 20, 30, 40, 50]}
                        page={activePage}
                        totalItems={count}
                      />
                    )}
                    {/* {(candidateSelectedTab === 0 || candidateSelectedTab === 1) &&
                      roleId &&
                      Number(roleId) === 3 &&
                      scores &&
                      Array.isArray(scores) &&
                      scores.length <= 0 && (
                        <div className="h6 text-center">{t('noJobInSelection')}</div>
                      )} */}
                    {selectedTab === 1 && roleId && Number(roleId) !== 3 && (
                      <div className="mb-2 mt-2">
                        <p>
                          <span className="text-capitalize">{t('the')}</span>{' '}
                          <b>{t('recommended')}</b> {t('jobMatchesMsg1')}
                        </p>
                        <div>{t('jobMatchesMsg5')}</div>
                        <div className="ml-3">{t('jobMatchesMsg2')}</div>
                        <div className="ml-3">{t('jobMatchesMsg3')}</div>
                        <div className="mt-2">
                          <a
                            target="_blank"
                            href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing">
                            <b className="xpa-link text-capitalize">{t('clickHere')}</b>
                            &nbsp;
                          </a>
                          {t('jobMatchesMsg4')}
                        </div>
                      </div>
                    )}
                    {/* {
                  selectedTab === 1 && (
                    <Tile className="mb-2 mt-2">
                      <p>
                        <span className="text-capitalize">{t('the')}</span> <b>{t('recommended')}</b> {t('jobMatchesMsg1')}
                      </p>
                      <div>
                        {t('studentMatchesMsg1')}
                      </div>
                      <div className="ml-3">
                        {t('studentMatchesMsg2')}
                      </div>
                      <div className="mt-2">
                        <a target="_blank" href="https://drive.google.com/file/d/1Lc6RDGDiABti6O33QrGIJbMuecZ12Gq8/view?usp=sharing ">
                          <b className="xpa-link text-capitalize">
                            {t('clickHere')}
                          </b>&nbsp;
                        </a>
                        {t('jobMatchesMsg4')}
                      </div>
                    </Tile>
                  )
                } */}
                  </Fragment>
                )}
              {/* {selectedTab === 2 && roleId && Number(roleId) !== 3 && (
          <Fragment>
            {emails && Array.isArray(emails) && emails.length > 0 ? (
              <GenericDataTable
                className=""
                otherActions={otherActionsBtn}
                headers={headersEmails.map((data, index) => ({
                  key: `header_${index}`,
                  header: data.title,
                  className: data.className,
                  colpropName: data.propName,
                }))}
                rows={
                  emails && Array.isArray(emails) && emails.length > 0
                    ? emails.map(res => {
                        const {
                          id,
                          toAddresses,
                          createdAt,
                          emailtemplates: { displayName } = {},
                        } = res || {};
                        return {
                          isExpanded: true,
                          id: `row_${id}`,
                          header_0: (
                            <div className="text-capitalize bx--type-zeta">
                              {displayName}
                            </div>
                          ),
                          header_1: <div>{toAddresses}</div>,
                          header_2: <div>{moment(createdAt).fromNow()}</div>,
                        };
                      })
                    : []
                }
                selectable={false}
                searchable={false}
                onSearchInputChange={() => {}}
                expandable={false}
                batchActions={false}
              />
            ) : (
              <div className="card-body text-center">
                <div className="empty-icon">
                  <i className="fa fa-flag fa-2x" />
                </div>
                <p className="empty-title h5">{t('noLogsMsg')}</p>
              </div>
            )}
          </Fragment>
        )} */}
              {
                roleId && (Number(roleId) !== 3) && (selectedTab === 4) && (
                  <div className="mt-2">
                    <ScheduledInterviews sid={profileId} />
                  </div>
                )
              }
              {roleId && Number(roleId) !== 3 && ctypeId && Number(ctypeId) === 2 && selectedTab === 6 && (
                <div className="mt-2">
                  <div className="mt-2">
                    <strong>{t('selectedForGeip')}</strong>
                  </div>
                  <div className="mt-1">
                    <div>
                      <RadioButtonGroup
                        valueSelected={this.state.selectedForGeip || null}
                        onChange={(e) => { this.setState({ selectedForGeip: e, selectedGeipInvalid: false, geipInternshipProgram: null, geipInternshipProgramInvalid: false }) }}
                      >
                        <RadioButton
                          value="yes"
                          id="selected-geip-yes"
                          labelText="Yes"
                        />
                        <RadioButton
                          value="no"
                          id="selected-geip-no"
                          labelText="No"
                        />
                      </RadioButtonGroup>
                    </div>
                    {this.state.selectedGeipInvalid === true && (
                      <div style={{ fontSize: '.75rem', color: '#da1e28' }} className="mt-1">
                        {t('selectGeip_err_msg')}
                      </div>
                    )}
                    <div className="text-dark">
                      <small>{t('selectedForGeipSubheader')}</small>
                    </div>
                    {this.state.selectedForGeip == 'no' && (
                      <div style={{ maxWidth: '15rem' }}>
                        <div className="">
                          <span className="bx--type-zeta">{(t('Internship Program'))}</span> <span className="xpa-job-title">*</span>
                        </div>
                        <DropDownBox
                          title=""
                          mainClassName="bx--form-item mb-0"
                          titleClass=""
                          labelClass=""
                          required
                          custom
                          className={`w-100 xpa-customize-dropdown ${this.state.geipInternshipProgramInvalid ? 'invalid' : ''}`}
                          options={[{label: 'Overseas Internship', value: 'overseas'}, {label: 'Graded Internship', value: 'graded'}]}
                          placeholder={t('geipInternshipProgram')}
                          onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                          name="geipInternshipProgram"
                          selectedValue={this.state.geipInternshipProgram || null}
                        />
                        {this.state.geipInternshipProgramInvalid === true && (
                          <div style={{color: '#da1e28', fontSize: '.75rem'}} className="mb-2">
                            {t('Please select internship program')}
                          </div>
                        )}
                      </div>
                    )}
                    <div className='d-flex mt-2'>
                      <Button
                        size="small"
                        className="bx--btn bx--btn--primary"
                        onClick={() => {
                          const invalidData = {
                            selectedForGeipInvalid: false,
                            geipInternshipProgramInvalid: false,
                          }
                          if((this.state.selectedForGeip == null || this.state.selectedForGeip == undefined) && (selected == null || selected == undefined)) {
                            invalidData.selectedForGeipInvalid = true;
                          }
                          if(this.state.selectedForGeip == 'no' && !this.state.geipInternshipProgram) {
                            invalidData.geipInternshipProgramInvalid = true;
                          }
                          this.setState({
                            ...invalidData,
                          })
                          if(Object.values(invalidData).includes(true))
                            return;
                          this.handleSelectedForGeipToggle(profileId);
                        }}>
                        Save
                      </Button>
                    </div>
                  </div>
                  <JDProcessOverlay
                    show={this.state && this.state.suspendAccountLoader}
                    message="processing..."
                  />
                </div>
              )}
              {roleId && Number(roleId) !== 3 && selectedTab === 6 && (
                <div className="mt-2">
                  <div className="mt-2">
                    <strong>{t('suspendAccountHeader')}</strong>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="text-dark mt-2">
                      <small>{t('suspendAccountSubHeader1')}</small>
                    </div>
                    <div>
                      <Toggle
                        id="toggleSuspended"
                        className="bx--col-md-2 xpa-toggle"
                        onToggle={val => this.handleSuspendToggleChange(profileId, val)}
                        toggled={suspended}
                        labelA="No"
                        labelB="Yes"
                      />
                    </div>
                  </div>
                  <JDProcessOverlay
                    show={this.state && this.state.suspendAccountLoader}
                    message="processing..."
                  />
                </div>
              )}
              {
                ctypeId && Number(ctypeId) === 2 /*&& isSuperAdmin === true */ && selectedTab === 6 && (
                  <div className="mt-2 mb-3">
                    <div className="mt-2">
                      <strong>{t('tier')}</strong>
                    </div>
                    <div className="bx--row col-xl-4 align-items-center mt-2">
                      {showTierSaveSuccess === true && (
                        <InlineNotification
                          lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="info"
                          subtitle={t('tierSaved')}
                          onCloseButtonClick={() => {
                            this.setState({ showTierSaveSuccess: false });
                          }}
                        />
                      )}
                      {showTierSaveError === true && (
                        <InlineNotification
                          lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle = {tierErrMsg}
                          onCloseButtonClick={() => {
                            this.setState({ showTierSaveError: false });
                          }}
                        />
                      )}
                      <TextInput
                        className="w-100 upload-docs-min-width mb-2"
                        placeholder={t('Enter Tier')}
                        type="number"
                        value={tierValue}
                        hideLabel={false}
                        onChange = {this.handleTierValueChange}
                        name="tier"
                        min={0}
                        defaultValue = {tierValue}
                      />
                      <div className="">
                        {' '}
                        <Button
                          size="small"
                          className="bx--btn bx--btn--primary full-width ml-3"
                          onClick={() => this.onTierSave(profileId, this.state.tierValue)}>
                          {' '}
                          Save{' '}
                        </Button>
                        <JDProcessOverlay
                          show={tierLoader}
                          message="processing..."
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              {
                ctypeId && Number(ctypeId) === 2 /*&& isSuperAdmin === true */ && selectedTab === 6 && (
                  <div className="mt-2 mb-3">
                    <div className="mt-2">
                      <strong>{t('batch')}</strong>
                    </div>
                    <div className="bx--row col-xl-4 align-items-center mt-2">
                      {showNotifInfo === true && (
                        <InlineNotification
                          lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="info"
                          subtitle={t('prefSaved')}
                          onCloseButtonClick={() => {
                            this.setState({ showNotifInfo: false });
                          }}
                        />
                      )}
                      {showSelectBatchError === true && (
                        <InlineNotification
                          lowContrast
                          className="mt-1 mb-1"
                          title=""
                          iconDescription="describes the close button"
                          kind="error"
                          subtitle="Please select the batch"
                          onCloseButtonClick={() => {
                            this.setState({ showSelectBatchError: false });
                          }}
                        />
                      )}
                      <DropDownBox
                        title=""
                        mainClassName="bx--form-item mb-0"
                        titleClass=""
                        labelClass=""
                        required
                        custom
                        className={`w-100 xpa-customize-dropdown`}
                        options={batchArr && Array.isArray(batchArr)
                          && batchArr.length > 0
                          ? (batchArr.map(one => ({
                            label: one.batchName,
                            value: one.batchName,
                          }))) : []}
                        placeholder={t('batch')}
                        onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                        name="batch"
                        selectedValue={batch}
                      />
                      <div className="">
                        {' '}
                        <Button
                          size="small"
                          className="bx--btn bx--btn--primary full-width ml-3"
                          onClick={() => this.onBatchChange(profileId, this.state.batch)}>
                          {' '}
                          Save{' '}
                        </Button>
                        <JDProcessOverlay
                          show={this.state && this.state.batchLoader}
                          message="processing..."
                        />
                      </div>
                    </div>
                    <JDProcessOverlay
                      show={this.state && this.state.selectedLoader}
                      message="processing..."
                    />
                  </div>
                )
              }
              {roleId && Number(roleId) !== 3 && ctypeId && Number(ctypeId) === 2 && (isSuperAdmin === true || isAdmin === true || isCourseAdmin === true) && selectedTab === 6 && (
                <div className="mt-2">
                  <div className="mt-2">
                    <strong>Account Inactivity Status</strong>
                  </div>
                    {!inactivityStatus ? 
                      <div className="text-dark mt-2">
                        <small>Account of this student is currently <strong style={{ color: "green" }}>Active</strong>.</small>
                      </div>:
                      <div className="d-flex align-items-center">
                        <div className="text-dark mt-2">
                          <small>Account of this student is currently <strong style={{ color: "red" }}>Inactive</strong>. Toggle the below button to activate this account.</small>
                        </div>
                        <div>
                          <Toggle
                            className="bx--col-md-2 xpa-toggle"
                            onToggle={(e) => {
                              this.handleInactivityStatus(e, studentUserId);
                            }}
                            toggled={!inactivityStatus}
                            labelA={t('no')}
                            labelB={t('yes')}
                            disabled={inactivityStatus === false}
                          />
                        </div>
                      </div>
                    }
                  <JDProcessOverlay
                    show={this.state && this.state.inactivityStatusLoading}
                    message="processing..."
                  />
                </div>
              )}
              {/* {
          roleId && Number(roleId) === 3
            && scores && Array.isArray(scores)
            && scores.length > 0
            && (
              <div>
                {
                  showNotifInfo === true && (
                    <InlineNotification lowContrast
                      className="mt-1 mb-1"
                      title=""
                      iconDescription="describes the close button"
                      kind="info"
                      subtitle={t('prefSaved')}
                      onCloseButtonClick={() => { this.setState({ showNotifInfo: false }); }}
                    />
                  )
                }
                <Preferences
                  ref="preferences"
                  disabled={false}
                  hide={['jobpreferences', 'jobfunctions', 'jobpreferences', 'industries', 'citypreferences']}
                  setData={this.setPreferences}
                  jobMatchesPreferences={jobMatchesPreferences}
                  jobMatchesPreferencesdata={scores}
                  scores={scores}
                />
                {
                  scores && Array.isArray(scores)
                    && scores.length > 0
                    && (
                      <div className="text-center">
                        <Button
                          kind="primary"
                          small
                          onClick={this.handleSaveStudentPref}
                        >
                          {t('save')}
                        </Button>
                      </div>
                    )
                }
              </div>
            )
        } */}
              {/* {
            (!scores || showError === true || (scores && Array.isArray(scores) && scores.length <= 0))
              && selectedTab !== 0
              && selectedTab !== 4
              && selectedTab !== 5
              && selectedTab !== 6
              && (showLoader === false)
              && (
                <div>
                  <div className="text-right mb-2">
                    <Button
                      className="mb-2"
                      onClick={this.activeCitiesModal}
                      kind="tertiary"
                    >
                      {t('filter')}
                      {
                        citiesList && Array.isArray(citiesList) && citiesList.length > 0
                          && (
                            <strong className="badge p-1 ml-2" style={{ backgroundColor: 'rgb(62, 165, 89)' }}>{citiesList.length}</strong>
                          )
                      }
                    </Button>
                  </div>
                  <NoContentBox errorCode={errorCode} t={t} />
                </div>
              )
          } */}

            </>
          )}

        <JDProcessOverlay
          show={(this.props && this.props.loading) || (this.state && this.state.inactivityStatusLoading)}
          message="processing..."
        />
        <Modal
          open={isOpenEmailModal}
          modalHeading={`${t('emailTemplates')}`}
          modalLabel={`${t('email')}`}
          primaryButtonText={t('send')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissEmailModal}
          onRequestSubmit={this.templateModalSubmit}>
          <div className="bx--modal-content__text">
            {showSuccessMsg && (
              <InlineNotification
                lowContrast
                kind="success"
                title="Email Sent Successfully"
                subtitle=""
                hideCloseButton
                onCloseButtonClick={() =>
                  this.setState({ showSuccessMsg: false })
                }
              />
            )}
            {noTemplateError && (
              <InlineNotification
                lowContrast
                kind="error"
                title="No template selected"
                subtitle=""
                hideCloseButton
                onCloseButtonClick={() =>
                  this.setState({ noTemplateError: false })
                }
              />
            )}
            <Select
              labelText="Select Email Template"
              className="mb-4"
              id="email-templates-list"
              onChange={this.onEmailTemplateChange}
              value={selectedEmailTemplate}
              defaultValue="placeholder-item">
              <SelectItem
                disabled
                value="placeholder-item"
                text="Choose a template"
              />
              {finalEmailTemplates &&
                Array.isArray(finalEmailTemplates) &&
                finalEmailTemplates.map(
                  ({ displayName = '', id = null, templateName = '' }) => (
                    <SelectItem
                      key={id}
                      name={templateName}
                      label={displayName}
                      value={`${id}_${templateName}`}
                    />
                  )
                )}
            </Select>
            <Fragment>
              <span className="pt-0 pb-1 text-dark d-block">
                {t('clickingOn')} <strong>{t('submit')}</strong>{' '}
                {t('clickingOn2')}
              </span>
            </Fragment>
          </div>
        </Modal>
        <Modal
          open={isOpenCityModal}
          modalHeading={`${t('filterByCity')}`}
          modalLabel={`${t('action')}`}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissCitiesModal}
          onRequestSubmit={this.handleSubmitCitiesModal}>
          <div>
            <div className="bx--row">
              <div className="text-left ml-3 text-capitalize bx--type-omega">
                {t('clearFiltersMsg')}
              </div>
              <div className="ml-5 mb-2">
                <Button
                  small
                  kind="tertiary"
                  onClick={() => this.handlClearFilter()}>
                  {t('clear')}
                </Button>
              </div>
            </div>
            {cities &&
              Array.isArray(cities) &&
              cities.length > 0 &&
              cities.map(res => {
                const { cityId, cityName } = res || {};
                const cityChecked =
                  cityArr &&
                  Array.isArray(cityArr) &&
                  cityArr.includes(cityName);
                return (
                  <Checkbox
                    id={cityId}
                    value={cityName}
                    labelText={cityName}
                    checked={cityChecked}
                    name={`city-${cityId}`}
                    onChange={e =>
                      this.handleCheckBoxChange(e, cityName, cityId)
                    }
                  />
                );
              })}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  scores: state.StudentProfileView.scores,
  count: state.StudentProfileView.count,
  candidateProfile: state.roboroyApplications.candidateProfile,
  loading: state.StudentProfileView.loading,
  interviewFeedback: state.StudentProfileView.interviewFeedback,
  emails: state.StudentProfileView.emails,
  emailTemplates: state.emailTemplates.templates,
  cities: state.StudentProfile.cities,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
  countries: state.RegistrationRequest.countries,
  taskId: state.resumeUpload.taskId,
});

const mapDispatchToProps = {
  getStudentProfileJobMatches,
  updateStudentProfileJobMatches,
  getCandidateProfile,
  editCandidateProfile,
  addStudentProfileToJob,
  getStudentProfileEmails,
  getCustomEmailTemplates,
  sendEmails,
  getCitiesList,
  addStudentToSetAsHired,
  getJobAppliedCount,
  uploadCampusDocs,
  uploadSignedOfferDocument,
  getBatchDetails,
  upsertUserInfoDetails,
  getInterviewFeedback,
  createInterviewFeedback,
  upsertInterviewFeedback,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StudentProfileView));