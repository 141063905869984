import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, TextArea, Button, TextInput } from 'carbon-components-react';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
import {
  fetchAllOffices,
  addOffice,
  upsertOfficeDetails,
} from 'actions/talentSearch/newJob';
import {
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import { getAllCountries } from 'actions/admin/CompanyAndOffice';
import {
  upsertCompanyDetailsById,
  getCompanyDetailsById,
} from 'actions/InstitutionView';
import {
  getLocations,
} from 'actions/campus/registrationRequest';
import companyLogoPlaceholder from 'assets/images/company.png';
import { updatePictureUrl, uploadLogo } from 'actions/userProfile/action';
import { updateCompanyInfo } from 'actions/admin/AdminSettings';

class ProfileUpdateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenCompanyInfoModal: false,
      isOpenOfficeAddressModal: false,
      addressLine1: '',
      addressLine2: '',
      city: '',
      zipcode: 0,
      country: '',
      officeName: '',
      companyName: '',
      website: '',
      description: '',
      domain: '',
      ctypeId: 0,
      locationsArr: [],
    };
  }

  componentDidMount() {
    const { user, companyDetails } = this.props;
    if (!companyDetails) {
      const {
        company: { companyName, displayCompanyName, website, description, domain, ctypeId } = {},
        office: {
          officeName,
          address: {
            addressLine1,
            addressLine2,
            zipcode,
            location: { locationName, locationId, state: { countryId } = {} } = {},
          } = {},
        } = {},
      } = user || {};
      this.setState(
        {
          addressLine1,
          addressLine2,
          zipcode,
          country: countryId,
          officeName,
          companyName,
          displayCompanyName,
          website,
          description,
          domain,
          ctypeId,
        },
        () => {
          if (
            locationName &&
            locationName !== null &&
            locationName !== undefined &&
            locationName !== ''
          ) {
            this.setState({
              city: `${locationName}`,
              location: { label: locationName, value: locationId, countryId },
            });
          }
        }
      );
    } else {
      this.handleGetCompanyDetailsVals(companyDetails);
    }
    this.props.getAllCountries();
  }

  componentWillReceiveProps(nextProps) {
    const { companyDetails } = this.props;
    if (companyDetails !== nextProps.companyDetails) {
      this.handleGetCompanyDetailsVals(nextProps.companyDetails);
    }
  }

  handleGetCompanyDetailsVals = companyDetails => {
    const {
      office: {
        officeName,
        address: {
          addressLine1,
          addressLine2,
          zipcode,
          location: { locationName, locationId, country: { countryId } = {} } = {},
        } = {},
      } = {},
      companyName,
      displayCompanyName,
      website,
      description,
      domain,
      ctypeId,
    } = companyDetails || {};
    this.setState(
      {
        addressLine1,
        addressLine2,
        zipcode,
        country: countryId,
        officeName,
        companyName,
        displayCompanyName,
        website,
        description,
        domain,
        ctypeId,
      },
      () => {
        if (
          locationName &&
          locationName !== null &&
          locationName !== undefined &&
          locationName !== ''
        ) {
          this.setState({
            city: `${locationName}`,
            location: { label: locationName, value: locationId },
            
          });
        }
      }
    );
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activeCompanyInfoModal = () => {
    this.setState({
      isOpenCompanyInfoModal: true,
    });
  };

  setDismissCompanyInfoModalState = () => {
    this.setState({
      isOpenCompanyInfoModal: false,
      description: '',
      domain: '',
      website: '',
      isValidDomain: false,
      isValidDomainText: '',
    }, () => {
      const { companyDetails } = this.props || {};
      this.handleGetCompanyDetailsVals(companyDetails);
    });
  };

  dismissCompanyInfoModal = () => {
    this.setDismissCompanyInfoModalState();
  };

  activeOfficeInfoModal = () => {
    this.setState({
      isOpenOfficeAddressModal: true,
    });
  };

  dismissOfficeInfoModal = () => {
    const { companyDetails } = this.props || {};
    this.handleGetCompanyDetailsVals(companyDetails);
    this.setState({
      isOpenOfficeAddressModal: false,
    });
  };

  handleSubmitCompanyDetails = () => {
    const { companyDetails } = this.props;
    let companyId = 0;
    if (!companyDetails) {
      const {
        user: { company: { companyId: userCompId } = {} } = {},
      } = this.props;
      companyId = userCompId;
    } else {
      const { companyDetails: { companyId: detCompId } = {} } = this.props;
      companyId = detCompId;
    }
    const { website, domain, description } = this.state;
    if (!domain) {
      this.setState({
        isValidDomain: true,
        isValidDomainText: 'Please enter valid domain',
      });
    } else {
      this.setState(
        {
          isValidDomain: false,
          isValidDomainText: '',
        },
        () => {
          const data = {
            website,
            domain,
            description,
          };
          this.props
            .upsertCompanyDetailsById(data, companyId)
            .then(res => {
              if (res && !res.error) {
                this.props.getCompanyDetailsById(companyId).then(() => {
                  this.setDismissCompanyInfoModalState();
                });
              }
            })
            .catch(e => console.log('Error:::::', e));
        }
      );
    }
  };

  handleSubmitOfficeAddressModal = () => {
    const { t } = this.props;
    const { addressLine1, zipcode, country, city, officeName } = this.state;
    let isValidAddrLine1 = null;
    let isValidAddrLine1Text = '';
    let isValidZipcode = null;
    let isValidZipcodeText = '';
    let isValidCountry = null;
    let isValidCountryText = '';
    let isValidCity = null;
    let isValidCityText = '';
    let isValidOfficeName = null;
    let isValidOfficeNameText = '';

    if (!addressLine1 || addressLine1 === null || addressLine1 === undefined) {
      isValidAddrLine1 = true;
      isValidAddrLine1Text = `${t('addressLine1Valid')}`;
    } else {
      isValidAddrLine1 = false;
      isValidAddrLine1Text = '';
    }
    if (!zipcode || zipcode === null || zipcode === undefined) {
      isValidZipcode = true;
      isValidZipcodeText = `${t('zipcodeValidation')}`;
    } else {
      isValidZipcode = false;
      isValidZipcodeText = '';
    }
    if (!country || country === null || country === undefined) {
      isValidCountry = true;
      isValidCountryText = `${t('countryValidation')}`;
    } else {
      isValidCountry = false;
      isValidCountryText = '';
    }
    if (!city || city === null || city === undefined) {
      isValidCity = true;
      isValidCityText = `${t('cityValidation')}`;
    } else {
      isValidCity = false;
      isValidCityText = '';
    }
    if (!officeName || officeName === null || officeName === undefined) {
      isValidOfficeName = true;
      isValidOfficeNameText = `${t('officeValidation')}`;
    } else {
      isValidOfficeName = false;
      isValidOfficeNameText = '';
    }

    this.setState(
      {
        isValidAddrLine1,
        isValidAddrLine1Text,
        isValidCountry,
        isValidCountryText,
        isValidOfficeName,
        isValidOfficeNameText,
        isValidZipcode,
        isValidZipcodeText,
        isValidCity,
        isValidCityText,
      },
      () => {
        this.handleValidateOfcData();
      }
    );
  };

  handleValidateOfcData = () => {
    const { countries, companyDetails } = this.props;
    const {
      isValidAddrLine1,
      isValidCity,
      isValidCountry,
      isValidOfficeName,
      isValidZipcode,
      addressLine1,
      zipcode,
      country,
      city,
      officeName,
      addressLine2,
      location,
    } = this.state;
    if (
      isValidAddrLine1 !== true &&
      isValidCountry !== true &&
      isValidOfficeName !== true &&
      isValidZipcode !== true &&
      isValidCity !== true
    ) {
      const countryArr =
        countries &&
        Array.isArray(countries) &&
        countries.length > 0 &&
        countries.filter(e => e.countryId == country);
      let companyId = 0;
      let officeId = 0;
      if (!companyDetails) {
        const {
          user: { companyId: userCompId, officeId: userOfcId } = {},
        } = this.props;
        companyId = userCompId;
        officeId = userOfcId;
      } else if (companyDetails) {
        const { companyId: detCompId, office: { officeId: detOfcId } = {} } =
          companyDetails || {};
        companyId = detCompId;
        officeId = detOfcId;
      }
      const data = {
        addressLine1,
        addressLine2,
        zipcode,
        country: countryArr && countryArr[0] && countryArr[0].countryFull,
        companyId,
        officeName,
        location: location && location.label || null,
        locationName: location && location.label || null,
        officeId,
        type: 'office',
      };
      this.props
        .upsertOfficeDetails(data)
        .then(res => {
          if (res && !res.error) {
            this.props.getCompanyDetailsById(companyId).then(() => {
              this.dismissOfficeInfoModal();
            });
          }
        })
        .catch(e => console.log('Error:::::', e));
    }
  };

  onInputDropdownChange = (value) => {
    if (value) {
      this.setState({
        showDropDownLoading: true,
      }, () => {
        this.props.getLocations(value).then((res) => {
          if (res && !res.error) {
            const {
              locations,
            } = this.props;
            const values = {
              locations,
            };
            const locationsArr = getLocationsDetails(values);
            this.setState({
              locationsArr,
              showDropDownLoading: false,
            });
          }
        });
      });
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    }, () => {
      const {
        location,
      } = this.state;
      let country = null;
      if (name === 'location' && location) {
        const {
          countryId,
        } = location;
        if (countryId) {
          country = countryId;
        }
        this.setState({
          country,
        });
      }
    });
  };

  changeImage = element => {
    const { companyDetails: { companyId } = {} } = this.props;
    const fileData = element.target.files;
    const file = new FormData();
    file.append('file', fileData[0]);
    this.setState({
      uploading: true,
    });
    this.props
      .uploadLogo(file)
      .then(res => {
        const { name } =
          (res && res.result && res.result.files && res.result.files.file[0]) ||
          {};
        this.setState({
          companyLogo: name,
          uploading: false,
        });
        if (name) {
          this.props
            .updateCompanyInfo({
              companyId,
              logo: name,
            })
            .then(vals => {
              if (vals && !vals.error) {
                this.props.getCompanyDetailsById(companyId);
              }
            })
            .catch(e => console.log('Error:::::', e));
        }
      })
      .catch(() => {
        this.setState({
          uploading: false,
        });
      });
  };

  render() {
    const {
      isOpenCompanyInfoModal,
      isOpenOfficeAddressModal,
      addressLine1,
      addressLine2,
      zipcode,
      country,
      city,
      officeName,
      isValidAddrLine1,
      isValidAddrLine1Text,
      isValidAddrLine2,
      isValidAddrLine2Text,
      isValidCity,
      isValidCityText,
      isValidCountry,
      isValidCountryText,
      isValidOfficeName,
      isValidOfficeNameText,
      website,
      isValidDomain,
      isValidDomainText,
      description,
      domain,
      ctypeId,
      isValidZipcode,
      isValidZipcodeText,
      companyName,
      displayCompanyName,
      showDropDownLoading,
      locationsArr,
    } = this.state;
    const { user, countries, t, companyDetails, history, editSettings } = this.props;
    const { isAdmin, isSuperAdmin } = user || {};
    const { logo, companyadditionalinfo: { companyUEN } = {} } = companyDetails || {};
    const countrArr =
      countries &&
      Array.isArray(countries) &&
      countries.length > 0 &&
      countries.filter(e => e.countryId == country);
    const { officeId } = user || {};
    let logoURL = companyLogoPlaceholder;
    if (logo) {
      logoURL = `https://s3-ap-southeast-1.amazonaws.com/x0pa-companies/${logo}`;
    }
    return (
      <Fragment>
        <div>
          {ctypeId && Number(ctypeId) === 1 && (
            <div className="bx--col-lg-2">
              {t('company')} {t('info')}
            </div>
          )}
          {ctypeId && Number(ctypeId) === 2 && (
            <div className="bx--col-lg-2">
              {t('institution')} {t('info')}
            </div>
          )}
        </div>
        <div className="bx--row">
          <div className="bx--col-lg-2 ml-3 bx--col-md-3">
            <div>
              <img
                alt="Company Logo"
                src={logoURL}
                className="img60_60 rounded-circle mr-2 border"
              />
              {
                editSettings && (
                  <label
                    className="bx--btn bx--btn--tertiary bx--btn--sm mt-3"
                    htmlFor="logo-upload">
                    {t('changeLogo')}
                    <input
                      name="logo-upload"
                      accept=".png,.jpg,.jpeg,.gif,image/gif,image/jpg,image/jpeg,image/png"
                      id="logo-upload"
                      type="file"
                      onChange={this.changeImage}
                      style={{ opacity: 0, width: 1, height: 1 }}
                    />
                  </label>
                )
              }

            </div>
          </div>
          <div className="bx--col-lg-6 bx--col-md-4">
            <div className="d-flex">
              <div className="bx--col-lg-3">
                {ctypeId && Number(ctypeId) === 1 && (
                  <span className="  text-dark">
                    {t('company')} {t('name')}:
                  </span>
                )}
                {ctypeId && Number(ctypeId) === 2 && (
                  <span className="  text-dark">
                    {t('institution')} {t('name')}:
                  </span>
                )}
              </div>
              <div className="text-capitalize   mb-0 bx--col-lg-4">
                {displayCompanyName}
              </div>
            </div>
            <div className="d-flex">
              <div className="  bx--col-lg-3 text-dark">
                {ctypeId && Number(ctypeId) === 1 && (
                  <span className="  text-dark">
                    {t('company')} UEN:
                  </span>
                )}
                {/* {ctypeId && Number(ctypeId) === 2 && (
                  <span className="  text-dark">
                    {t('institution')} UEN:
                  </span>
                )} */}
              </div>
              {
                ctypeId && Number(ctypeId) === 1 && (
                  <div className="mb-0 bx--col-lg-9">
                  {companyUEN ? companyUEN : '-'}
                </div>
                )
              }
            </div>
            <div className="d-flex">
              <div className="  bx--col-lg-3 text-dark">
                {t('desc')}:
              </div>
              <div className="mb-0 bx--col-lg-9">
                {description ? description : '-'}
              </div>
            </div>
            <div className="d-flex">
              <div className="  bx--col-lg-3 text-dark">
                {t('website')}:
              </div>
              <div className="mb-0   bx--col-lg-3">
                {website ? <a target="_blank" href={website}>{website}</a> : '-'}
              </div>
            </div>
            <div className="d-flex">
              <div className="  bx--col-lg-3 text-dark">
                {t('domain')}:
              </div>
              <div className="mb-0   bx--col-lg-3">
                {domain ? domain : '-'}
              </div>
            </div>
            <div className="ml-3">
              {((isAdmin === true) || isSuperAdmin === true) && editSettings && (
                <Button
                  className="mt-2"
                  onClick={this.activeCompanyInfoModal}
                  kind="tertiary"
                  small>
                  {t('edit')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="bx--row">
          <div className="bx--col-lg-2 ml-3 bx--col-md-3">
            {t('office')} {t('address')}
          </div>
          <div className="bx--col-lg-6 bx--col-md-4">
            <div className="d-flex">
              <div className="bx--col-md-3   text-dark">
                {/* <div>
                  {t('office')} {t('name')}:
                </div> */}
                <div>
                  {t('address')} {t('line')} 1:
                </div>
                <div>
                  {t('address')} {t('line')} 2:
                </div>
                <div>{t('zipcode')}:</div>
                <div>{t('city')}:</div>
                <div>{t('country')}:</div>
                {(isAdmin === true || isSuperAdmin === true) && editSettings && (
                  <div>
                    <Button
                      onClick={this.activeOfficeInfoModal}
                      className="mt-2"
                      kind="tertiary"
                      small>
                      {t('edit')}
                    </Button>
                  </div>
                )}
              </div>
              <div>
                {/* <div className="text-capitalize   mb-0">
                  {officeName ? officeName : '-'}
                </div> */}
                <div className="mb-0  ">
                  {addressLine1 ? addressLine1 : '-'}
                </div>
                <div className="mb-0  ">
                  {addressLine2 ? addressLine2 : '-'}
                </div>
                <div className="mb-0  ">
                  {zipcode ? zipcode : '-'}
                </div>
                <div className="mb-0   text-capitalize">
                  {city && city !== null && city !== undefined ? city : '-'}
                </div>
                <div className="mb-0   text-capitalize">
                  {country && countrArr && countrArr[0]
                    ? countrArr[0].countryFull
                    : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isOpenCompanyInfoModal}
          modalHeading={
            <span>
              {t('edit')} {t('company')} {t('info')}
            </span>
          }
          modalLabel={
            <span>
              {t('company')} {t('details')}
            </span>
          }
          primaryButtonText={t('edit')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissCompanyInfoModal}
          onRequestSubmit={this.handleSubmitCompanyDetails}>
          <div>
            <div className="mt-2">
              <TextInput
                className="mb-2"
                name="companyName"
                id="companyName"
                value={companyName}
                labelText={t('companyName')}
                disabled
              />
            </div>
            <div>
              <TextArea
                className="some-class"
                id="test2"
                name="description"
                value={description}
                labelText={t('desc')}
                placeholder={t('desc')}
                hideLabel={false}
                onChange={e => this.handleChange(e)}
              />
            </div>
            <div className="mt-2">
              <TextInput
                className="mb-2"
                name="website"
                id="website"
                value={website}
                labelText={t('website')}
                placeholder={t('websitePlaceholder')}
                onChange={e => this.handleChange(e)}
              />
            </div>
            <div className="mt-2">
              <TextInput
                className="mb-2"
                name="domain"
                id="domain"
                value={domain}
                invalid={isValidDomain}
                invalidText={isValidDomainText}
                labelText={t('domain')}
                placeholder={t('domain')}
                onChange={e => this.handleChange(e)}
              />
            </div>
          </div>
        </Modal>
        {isOpenOfficeAddressModal && (
          <Modal
            open={isOpenOfficeAddressModal}
            modalHeading={
              <span>
                {t('edit')} {t('office')} {t('info')}
              </span>
            }
            modalLabel={
              <span>
                {t('office')} {t('details')}
              </span>
            }
            primaryButtonText={t('edit')}
            secondaryButtonText={t('cancel')}
            iconDescription="Close the modal"
            onRequestClose={this.dismissOfficeInfoModal}
            onRequestSubmit={this.handleSubmitOfficeAddressModal}>
            <div>
              <OfficeDetails
                countries={countries}
                handleChange={this.handleChange}
                city={city}
                showOfcInput
                zipcode={zipcode}
                officeName={officeName}
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                country={country}
                isValidAddrLine1={isValidAddrLine1}
                isValidAddrLine1Text={isValidAddrLine1Text}
                isValidAddrLine2={isValidAddrLine2}
                isValidAddrLine2Text={isValidAddrLine2Text}
                onInputDropdownChange={this.onInputDropdownChange}
                handleDropdownChange={this.handleDropdownChange}
                isValidCity={isValidCity}
                isValidCityText={isValidCityText}
                isValidCountry={isValidCountry}
                isValidCountryText={isValidCountryText}
                isValidOfficeName={isValidOfficeName}
                isValidOfficeNameText={isValidOfficeNameText}
                isValidZipcode={isValidZipcode}
                isValidZipcodeText={isValidZipcodeText}
                showDropDownLoading={showDropDownLoading}
                locationsArr={locationsArr}
                location={this.state.location}
              />
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  countries: state.companyAndOffice.countries,
  locations: state.RegistrationRequest.locations,
});

const mapDispatchToProps = {
  addOffice,
  fetchAllOffices,
  getAllCountries,
  upsertOfficeDetails,
  upsertCompanyDetailsById,
  getCompanyDetailsById,
  updatePictureUrl,
  uploadLogo,
  updateCompanyInfo,
  getLocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileUpdateDetails));