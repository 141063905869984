import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import { DatePickerInput, DatePicker } from 'carbon-components-react';
import { withTranslation } from 'react-i18next';
import phone from 'phone';
import SkillsAndLanguage from './skillsAndLanguage';
import 'components/roboroy/timeline.css';
import 'components/roboroy/CandidateDetails.css';
import 'rc-slider/assets/index.css';
import {
  getAllCountries,
} from 'actions/admin/CompanyAndOffice';
import {
  createCityPerference,
} from 'actions/campus/internCandidate';
import {
  Button, TextInput, RadioButtonGroup, RadioButton,
  InlineNotification, SelectItem, Select,
} from 'carbon-components-react';
import {
  getLocations,
} from 'actions/campus/registrationRequest';
import {
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import {
  getCandidateProfile,
} from 'actions/talentSearch/candidatesList';

import {
  getAllDropDownData,
  getFewDropDownData,
  updateProfile,
  getAllCourses,
  getAllFaculties,
  createNewCourses,
} from 'actions/candidate/CandidateDetails';
import {
  editProfileContact,
 } from 'actions/talentSearch/candidatesList';

import {
  createStudentProfile,
  updateStudentProfile,
} from 'actions/campus/internCandidate';
import { getCountries } from 'actions/campus/registrationRequest';
import {
  REQUEST_ALL_COUNTRY,
  REQUEST_FEW_ETHNICITIES,
  REQUEST_FEW_COURSES,
  REQUEST_FEW_FACULTY,
  REQUEST_FEW_SCHOOLS,
} from 'actions/actionTypes';
import './registerCandidate.css';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
// const Range = createSliderWithTooltip(Slider.Range);

const motherTongueListArr = [
  'Chinese',
  'Malay',
  'Tamil',
  'Others',
]

const internshipPreferenceListArr = [
  'Physical',
  'Remote',
]

const chineseGrades = [
  'NA',
  'A1',
  'A2',
  'B3',
  'B4',
  'C5',
  'C6',
  'D7',
  'E8',
  'F9',
];

const yearsOfStudy = [
  '1',
  '2',
  '3',
  '4',
];

const semestersOfStydy = [
  '1',
  '2',
  '3',
];

class PublicCandidateInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender:'',
      addressLine1 :'',
      mailingAddress:'',
       addressLine2:'',
       location:{ label: '', value: '' },
      residentStatus: '',
      placeOfBirth: '',
      studentUniversityId: '',
      email: '',
      locationsArr: [],
      schoolValue: { label: '', value: '' },
      chineseOGrade: '',
      chineseHigherOGrade: '',
      chineseOGradeScore: '',
      chineseHigherOGradeScore: '',
      currentGpa: '',
      facultyValue: null,
      courseValue: null,
      yearOfStudy: null,
      semOfStudy: null,
      firstName: '',
      lastName: '',
      personalEmail: '',
      contact: '',
      showErrorNotif: false,
      availabilityStart: moment(new Date()).format('MM/DD/YYYY'),
      availabilityEnd: moment().add(6, 'M').format('MM/DD/YYYY'),
      countryCode: 0,
      countryFull: '',
      durationInWeek: 0,
      months: '',
      years: '',
      portfolioLink: '',
    };
    this.notifRef = React.createRef();
  }

  componentDidMount() {
    const { candidateProfile: { profile = {} }, handleValidationStages } = this.props;
    if (profile === null || !profile.profileId || profile.profileId === null) {
      const { profileId } = this.props;
      if (profileId) {
        this.props.getCandidateProfile(profileId).then((res) => {
          handleValidationStages();
          this.setStageBio();
          this.props.getAllCountries();
        });
      }
    } else {
      this.setStageBio();
      this.props.getAllCountries();
    }
  }

  componentDidUpdate(prevProps){
    const { candidateProfile: { profile: prevProfile = {} } } = prevProps || {};
    const { candidateProfile: { profile = {} } } = this.props || {};
    const {profileId: currProfileId} = profile || {};
    const {profileId: prevProfileId} = prevProfile || {};

    if(currProfileId !== prevProfileId){
      this.setStageBio();
      this.props.getAllCountries();
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  isValid = (info, type) => {
    let data = info || {};
    if (type === 'number') return info && info !== null && info.toString().length > 0 ? true : false;
    if (type === 'string') return info && info !== null && info.length > 0 ? true : false;
    if (type === 'array') data = info && Array.isArray(info) ? info[0] || {} : {};
    const { value = null, label = null } = data;
    if (value !== null && label !== null && value !== '' && label !== '') return true;
    return false;
  }

  isValidString = (str = null) => {
    if (str !== null && typeof str === 'string' && str.trim().length > 0) return true;
    return false;
  }


  isValidBio = () => {
    // const {} = this.state;

    // if (this.isValid(genderValue, 'object') === false || this.isValid(ethnicityValue, 'object') === false || this.isValidString(moment(dob).format('MM/DD/YYYY')) === false
    //   || this.isValidString(studentUniversityId) === false || this.isValidString(email) === false || this.isValid(schoolValue, 'object') === false || this.isValidString(placeOfBirth) === false
    //   || this.isValidString(currentGpa) === false || this.isValid(yearOfStudy, 'object') === false) {
    //   if (this.props.onNextStep && this.props.onNextStep(false)) return false;
    // }
    // if (this.props.onNextStep && this.props.onNextStep(true)) return true;
    // return false;
  }

  setStageBio = () => {
    const {
      candidateProfile: { profile = {}, studentprofile = {} } = {}, allCountries,
    } = this.props;
    // const {
    //   addressLine1,
    //   addressLine2,
    //   locationName,
    //   locationId
    // } = this.state;
    const {
      gender = '',
      address: {
        zipcode,
        addressLine1,
        addressLine2,
        location: {
          locationName,
          locationId,
          countryId,

        } = {},
      } = {},
      residentStatus = {},
      militaryRank = '',
      militaryTitle = '',
      ethnicity = {},
      birthDate = '',
      email: email = '',
      placeOfBirth = {},
      school = {},
      contacts,
      firstName,
      lastName,
      personalEmail,
      mailingAddress,
    } = profile || {};
    const {
      mobile,
      country: {
        countryCode,
      } = {},
    } = (contacts && contacts[0]) || [];
    let finalCountryCode = 0;
    const {
      company = null,
      studentUniversityId = null,
      chinesegrade: { grade: chineseOGrade = {}, score: chineseOGradeScore = null } = {},
      hchinesegrade:  { grade: chineseHigherOGrade = {}, score: chineseHigherOGradeScore = null } = {},
      internshipPreference,
      motherTongue,
      faculty = {},
      courses = {},
      currentGpa = '',
      semester,
      internshipStartDate,
      noOfWeeks,
      portfolioLink,
    } = studentprofile || {};

    let { yearOfStudy = null } = studentprofile || {};

    let { facultyValue, courseValue } = this.state;
    let semOfStudy;
    let durationInWeek = 0;
    let availabilityStart = moment(new Date()).format('MM/DD/YYYY');
    let months = '';
    let years = '';
    let finalPlaceOfBirthVal = 0;
    if (company !== null && company.companyId && company.companyId !== null) {
      school.value = company.companyId;
      school.label = company.companyName && company.companyName.toUpperCase();
    }
    if (faculty !== null && faculty.facultyId && faculty.facultyId !== null) {
      facultyValue = { value: faculty.facultyId, label: faculty.facultyName };
    }
    if (courses !== null && courses.courseId && courses.courseId !== null) {
      courseValue = { value: courses.courseId, label: courses.courseName };
    }
    let finalGender;
    if (profile !== null && profile.genderId && profile.genderId !== null) {
      finalGender = gender.genderName;
    }
    if (profile !== null && profile.ethnicityId && profile.ethnicityId !== null) {
      ethnicity.label = ethnicity.ethnicityName;
      ethnicity.value = ethnicity.ethnicityId;
    }

    if (placeOfBirth) {
      const filteredListCount = allCountries.filter(res => res.countryId == Number(placeOfBirth));
      finalPlaceOfBirthVal = filteredListCount && Array.isArray(filteredListCount)
        && filteredListCount.length > 0 && filteredListCount[0] && filteredListCount[0].countryFull;
    }

    if (yearOfStudy !== null && typeof yearOfStudy === 'string') {
      yearOfStudy = { label: yearOfStudy, value: yearsOfStudy.indexOf(yearOfStudy) + 1 };
    }

    if (countryCode && countryCode !== null && countryCode !== undefined) {
      finalCountryCode = countryCode;
    }
    if (semester && semester !== null && semester !== undefined) {
      semOfStudy = { label: semester, value: Number(semester) };
    }
    if (internshipStartDate && (internshipStartDate !== null && internshipStartDate !== undefined)) {
      months = `${moment(internshipStartDate).get('month')}`;
      years = `${moment(internshipStartDate).get('year')}`;
    }
    if (noOfWeeks && noOfWeeks !== null && noOfWeeks !== undefined) {
      durationInWeek = noOfWeeks;
    }

    this.setState({
      gender: finalGender,
      residentStatus,
      countryId,
      mailingAddress,
      addressLine1,
      addressLine2,
      zipcode,
      location: { label: locationName, value: locationId },
      country: countryId,
      ethnicityValue: ethnicity,
      militaryRank,
      militaryTitle,
      placeOfBirth: finalPlaceOfBirthVal,
      studentUniversityId,
      email,
      firstName,
      lastName,
      contact: mobile,
      schoolValue: school,
      motherTongue: {label: motherTongue, value: motherTongue},
      chineseOGrade: { label: chineseOGrade.gradeName ? chineseOGrade.gradeName : '', value: chineseOGrade.gradeId ? chineseOGrade.gradeId : '' },
      chineseHigherOGrade: { label: chineseHigherOGrade.gradeName ? chineseHigherOGrade.gradeName : '', value: chineseHigherOGrade.gradeId ? chineseHigherOGrade.gradeId : '' },
      internshipPreference: { label:internshipPreference, value: internshipPreference},
      currentGpa,
      facultyValue,
      courseValue,
      yearOfStudy,
      personalEmail,
      countryCode: finalCountryCode,
      chineseOGradeScore,
      chineseHigherOGradeScore,
      semOfStudy,
      dob: birthDate === null ? moment() : moment(birthDate).toDate(),
      durationInWeek,
      months,
      portfolioLink,
      years,
    });
  }

  // To handle all text and text areas
  handleText = (e) => {
    const { target: { name = '', value = '' } } = e;
    const finalVal = value && value.toString() && value.trimLeft();
    if (name === 'contact' && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
      this.setState({ [name]: finalVal });
    } else {
      this.setState({ [name]: finalVal });
    }
  }
  // =======================================================

  onInputDropdown = (value, params) => {
    if ((/^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/.test(value) && value.length >= 2) || value === '') {
      const actionData = {
        actionType: '',
        apiName: '',
        value: '',
      };

      switch (params) {
        case 'country': {
          actionData.actionType = REQUEST_ALL_COUNTRY;
          actionData.apiName = 'Countries';
          break;
        }
        case 'course': {
          actionData.actionType = REQUEST_FEW_COURSES;
          actionData.apiName = 'Courses';
          break;
        }
        case 'faculty': {
          actionData.actionType = REQUEST_FEW_FACULTY;
          actionData.apiName = 'Faculty';
          break;
        }
        case 'school': {
          actionData.actionType = REQUEST_FEW_SCHOOLS;
          actionData.apiName = 'Schools';
        }
        default: return;
      }
      actionData.value = value;
      this.props.getAllDropDownData(actionData);
    }
  }

  onDropdownChange = (e, params) => {
    if (e === undefined) return;
    const stageBio = e === null ? {} : e;
    this.setState({ [params]: stageBio });
  };

  onAvailabilityChange = ([
    availabilityStart = this.state.availabilityStart,
    availabilityEnd = this.state.availabilityEnd
  ]) => {
    this.setState({
      availabilityEnd: moment(availabilityEnd).format('MM/DD/YYYY'),
      availabilityStart: moment(availabilityStart).format('MM/DD/YYYY'),
    });
  }

  onresidentStatusChange = (value, name) => {
    this.setState({ [name]: value });
  }
  onGenderStatusChange = (value, name) => {
    this.setState({ [name]: value });
  }
  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    }, () => {
      const {
        location,
      } = this.state;
      let country = null;
      if (name === 'location' && location) {
        const {
          countryId,
        } = location;
        if (countryId) {
          country = countryId;
        }
        this.setState({
          country,
        });
      }
    });
  };
  onInputDropdownChange = (value) => {
    if (value) {
      this.setState({
        showDropDownLoading: true,
      }, () => {
        this.props.getLocations(value).then((res) => {
          if (res && !res.error) {
            const {
              locations,
            } = this.props;
            const values = {
              locations,
            };
            const locationsArr = getLocationsDetails(values);
            this.setState({
              locationsArr,
              showDropDownLoading: false,
            });
          }
        });
      });
    }
  };

  onInputFacultyDropdow = (value) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length > 2) {
        const {
          candidateProfile,
        } = this.props;
        const {
          studentprofile: {
            schoolId,
          } = {},
        } = candidateProfile || {};
        const data = {
          schoolId,
        };
        this.props.getAllFaculties(data);
      }
      return value;
    }
    return null;
  };

  facultyDropdown = (value) => {
    this.setState({
      facultyValue: value,
    }, () => {
      if (!this.state.facultyValue) {
        this.setState({
          courseValue: null,
        });
      } else {
        const {
          facultyValue,
        } = this.state;
        const {
          value: facultyId,
        } = facultyValue;
        const {
          candidateProfile,
        } = this.props;
        const {
          studentprofile: {
            schoolId,
          } = {},
        } = candidateProfile || {};
        const data = {
          facultyId,
          schoolId,
          courseItemsPerPage: 1000,
          courseSearchKey: '',
          courseActivePage: 1,
        };
        this.props.getAllCourses(data);
      }
    });
  }

  onInputCoursesDropdow = (value, cb) => {
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      if (value.length > 2) {
        const {
          facultyValue,
        } = this.state;
        const {
          facultyData,
        } = this.props;
        const facultyObj = facultyData.find(e => e.facultyId == facultyValue.value);
        const {
          schoolId,
          facultyId,
        } = facultyObj || {};
        const data = {
          schoolId: Number(schoolId),
          facultyId,
          courseItemsPerPage: 10,
          courseSearchKey: '',
          courseActivePage: 1,
        };
        this.props.getAllCourses(data).then((res) => {
          if (res && !res.error) {
            const options = res.map(one => ({ label: one.skillName, value: one.skillId }));
            if (cb && typeof cb === 'function') cb(options);
          }
        });
      }
    }
  };

  coursesDropdown = (selectedOption, name) => {
    //let difference = this.state[name].filter(x => !value.includes(x));
    this.setState({
      [selectedOption]: name,
    });
  };

  onNewOptionCreate = (params, value) => {
    const {
      facultyValue,
    } = this.state;
    const {
      facultyData,
    } = this.props;
    const facultyObj = facultyData.find(e => e.facultyId == facultyValue.value);
    const {
      schoolId,
      facultyId,
    } = facultyObj || {};
    const data = {
      schoolId: Number(schoolId),
      facultyId,
      courseName: value,
    };
    this.props.createNewCourses(data).then((res) => {
      if (res.courseId) {
        const courseValue = {
          label: res.courseName,
          value: Number(res.courseId),
        };
        this.setState({ courseValue });
      }
    });
  }

  handlePreviousButtonClick = () => {
    const {
      push,
      profileId
    } = this.props;
    if (push) {
      push(`/app/campus/candidate/register/profile/${profileId}/stage/index/2`);
    }
  }

  handleNextButtonClick = () => {
    const {
      push,
      candidateProfile,
      allCountries,
    } = this.props;
    let finalPersonNumber = 0;
    const {
      profile: {
        profileId,
      } = {},
      studentprofile: {
        studentProfileId = '',
      } = {},
    } = candidateProfile || {};
    const mobileObj = (candidateProfile && candidateProfile.contacts && candidateProfile.contacts[0]) || {};
    const { personMobId } = mobileObj || {};
    const {
      firstName, lastName, contact, residentStatus,gender,
      studentUniversityId, currentGpa, countryCode,
      facultyValue, courseValue, personalEmail,
      placeOfBirth, availabilityEnd, availabilityStart,
      motherTongue, chineseOGrade, yearOfStudy, semOfStudy,
      chineseHigherOGrade, internshipPreference, countryFull, durationInWeek,
      months, years, portfolioLink,
      addressLine1, addressLine2, zipcode, country,locationsArr,
      city, mailingAddress,
      location: { label: locationName, value: locationId },
    } = this.state;
    const {
      value: facultyId,
    } = facultyValue || {};
    const {
      value: courseId,
    } = courseValue || {};
    if ((!firstName || firstName === '' || firstName === null || firstName === undefined)) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    }
    // else  if ((!gender.label || gender.label === '' || gender.label === null || gender.label === undefined)) {
    //   this.setState({
    //     showErrorNotif: true,
    //   }, () => {
    //     const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
    //     && this.notifRef.current.focus();
    //   });
    // }
      else if (!lastName || lastName === '' || lastName === null || lastName === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    }
    // else if (!studentUniversityId || studentUniversityId === 0 || studentUniversityId === null || studentUniversityId === undefined) {
    //   this.setState({
    //     showErrorNotif: true,
    //   }, () => {
    //     const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
    //     && this.notifRef.current.focus();
    //   });
    // }
    else if (!countryCode || countryCode === 0 || countryCode === null || countryCode === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!contact || contact === 0 || contact === null || contact === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!currentGpa || currentGpa === 0 || currentGpa === null || currentGpa === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!personalEmail || personalEmail === '' || personalEmail === null || personalEmail === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!residentStatus || residentStatus === null || residentStatus === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!facultyId || facultyId === null || facultyId === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!courseId || courseId === null || courseId === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!months || months === null || months === undefined || !years || years === null || years === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!durationInWeek || durationInWeek === 0 || durationInWeek === null || durationInWeek === undefined) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!yearOfStudy || (yearOfStudy && (!(yearOfStudy.label) || yearOfStudy.label === null || yearOfStudy.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!semOfStudy || (semOfStudy && (!(semOfStudy.label) || semOfStudy.label === null || semOfStudy.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!motherTongue || (motherTongue && (!(motherTongue.label) || motherTongue.label === null || motherTongue.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!chineseOGrade || (chineseOGrade && (!(chineseOGrade.label) || chineseOGrade.label === null || chineseOGrade.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!chineseHigherOGrade || (chineseHigherOGrade && (!(chineseHigherOGrade.label) || chineseHigherOGrade.label === null || chineseHigherOGrade.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if (!internshipPreference || (internshipPreference && (!(internshipPreference.label) || internshipPreference.label === null || internshipPreference.label === undefined))) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    } else if(!mailingAddress) {
      this.setState({
        showErrorNotif: true,
      }, () => {
        const c = this.notifRef && this.notifRef.current && this.notifRef.current.focus
        && this.notifRef.current.focus();
      });
    }else{
      let countryId = 0;
      if (contact) {
        const countryShortArr = allCountries && Array.isArray(allCountries)
        && allCountries.length > 0
        && allCountries.filter(e => e.countryCode === countryCode);
        const countryShort = countryShortArr && Array.isArray(countryShortArr)
          && countryShortArr.length > 0
          && countryShortArr[0].countryShort;
        const countryValue = countryShortArr && Array.isArray(countryShortArr)
        && countryShortArr.length > 0
        && countryShortArr[0].countryValue;
        countryId = countryShortArr && countryShortArr[0].countryId;
        const phoneValue = phone(contact, countryShort);
        if (!phoneValue || phoneValue.length <= 0) {
          this.setState({
            showErrorNotif: true,
          });
        } else if (phoneValue && Array.isArray(phoneValue) && phoneValue[0].includes(countryCode)) {
          finalPersonNumber = phoneValue[0].split(countryCode);
        } else if (phoneValue && Array.isArray(phoneValue) && phoneValue[0].includes(countryValue)) {
          finalPersonNumber = phoneValue[0].split(countryValue);
        }
      }
    // else if (!placeOfBirth || placeOfBirth === 0 || placeOfBirth === null || placeOfBirth === undefined) {
    //   this.setState({
    //     showErrorNotif: true,
    //   });
    // }

      const mobileData = {
        mobile: finalPersonNumber && finalPersonNumber[1],
        profileId,
      };
      if (personMobId) {
        mobileData.personMobId = personMobId;
      }
      if (countryCode) {
        mobileData.codeId = countryId;
      }
      const {
        canWorkInAsean,
        travelledOverseasAlone,
        plannedOverseasTrip,
        geipReasonToChoose,
        geipSelectionAchievement,
        marketingGeipQuestion,
        aboutBusiness,
        startedBusinessOutsideCourse,
        cityPreferences,
      } = this.props;
      if (mobileData && mobileData.mobile) {
        this.props.editProfileContact(mobileData);
      }
      const data = {
        profileId,
        infoData: {
          firstName,
          lastName,
          personalEmail,
          zipcode,
          address: {
            addressLine1,
            addressLine2,
            location: {
              locationName,
              locationId,
              countryId: country,

            }
          },
          mailingAddress,
        },
      };
      if (residentStatus === 'others') {
        data.infoData.residentStatus = countryFull;
      } else {
        data.infoData.residentStatus = residentStatus;
      }
      if (gender === 'others') {
        data.infoData.gender = '';
      } else {
        data.infoData.gender = gender;
      }
      const infoData = {
        canWorkInAsean,
        travelledOverseasAlone,
        plannedOverseasTrip,
        geipReasonToChoose,
        marketingGeipQuestion,
        geipSelectionAchievement,
        aboutBusiness,
        startedBusinessOutsideCourse,
        yearOfStudy: yearOfStudy && yearOfStudy.label,
        semester: semOfStudy && semOfStudy.label,
        courseId,
        facultyId,
        currentGpa,
        portfolioLink,
        motherTongue: motherTongue && motherTongue.label,
        chineseOGrade: chineseOGrade && chineseOGrade.label,
        chineseHigherOGrade: chineseHigherOGrade && chineseHigherOGrade.label,
        internshipPreference: internshipPreference && internshipPreference.label,
        noOfWeeks: durationInWeek,
        internshipStartDate: moment().set({'year': years, 'month': months}).format(),
      };
      this.setState({
        showErrorNotif: false,
        showLoading:true
      }, () => {
        const finalData = Array.isArray(cityPreferences) && cityPreferences.map((o, i) => ({ cityName: o, rank: i + 1 }));
        this.props.createCityPerference(finalData, studentProfileId);
        this.props.updateProfile(data).then(() => {
          infoData.studentUniversityId = studentUniversityId;
          this.props.updateStudentProfile(infoData, studentProfileId).then(() => {
            this.setState({showLoading:false},()=>{
              // if (push) {
              //   push('/app/campus/candidate/interncandidate/1');
              // }
              if (push) {
                push(`/app/campus/candidate/register/profile/${profileId}/stage/index/4`);
              }
            })
          });
        });
      });
    }
  }

  render() {
    const {
      disabled,
      t,
      indexId,
      push,
      candidateProfile,
      loading,
      profileLoader,
      profileId
    } = this.props;

    const {
      residentStatus,
      addressLine1, addressLine2, zipcode, country,
      mailingAddress,
      location,
      city,
      gender,
      studentUniversityId,
      locationsArr,
      // placeOfBirth,
      email,
      schoolValue,
      motherTongue,
      chineseOGrade,
      chineseHigherOGrade,
      internshipPreference,
      currentGpa,
      yearOfStudy,
      chineseOGradeScore,
      chineseHigherOGradeScore,
      firstName,
      lastName,
      courseValue,
      facultyValue,
      personalEmail,
      contact,
      availabilityStart,
      availabilityEnd,
      showErrorNotif,
      countryCode,
      semOfStudy,
      countryFull,
      durationInWeek,
    } = this.state;
    let finalArr = []
    for (let i = 0; i < 52; i++) {
      if (finalArr.length <= 0) {
        finalArr = [i + 1];
      } else {
        finalArr.push(i+1);
      }
    }
    const {
      candidateDetails: {
        // courses = [],
        // faculty = [],
        // schools = [],
        countries = [],
      },
      coursesData,
      facultyData,
      allCountries,
    } = this.props;
    const months = [{label: `${t('march')}`, value: '2'}, {label: `${t('sept')}`, value: '8'}];
    const date = new Date;
    const currYear = date.getFullYear();
    const years = [currYear, currYear + 1];
    return (
      <Fragment>
        <div className="m-0 p-0">
          {
            indexId && Number(indexId) === 3
              && (
                <Fragment>
                  <input
                    style={{ height: 0, opacity: 0 }}
                    ref={this.notifRef}
                  />
                  {
                    showErrorNotif === true && (
                      <InlineNotification lowContrast className="w-100" kind="error" title="Please fill all fields marked with *" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showErrorNotif: false })} />
                    )
                  }
                  <div className="h4 font-weight-bold text-dark bx--col bx--row m-0 pl-4 mb-2">
                    {t('bioInformation')}
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('firstName')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('firstName')} disabled={disabled} labelText="" hideLabel name="firstName" max="20" value={firstName || ''} onChange={this.handleText} id="firstName" />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('lastName')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('lastName')} disabled={disabled} labelText="" hideLabel name="lastName" max="20" value={lastName || ''} onChange={this.handleText} id="lastName" />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('personalEmail')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('email')} disabled={disabled} labelText="" hideLabel name="personalEmail" max="50" value={personalEmail || ''} onChange={this.handleText} id="personalEmail" />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('contact')}<span className="text-danger">*</span></div>
                    <div className="d-flex col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <div>
                        <Select
                          name="countryCode"
                          labelText=""
                          hideLabel
                          disabled={disabled}
                          id="countryCode"
                          onChange={e => this.handleChange(e)}
                          value={countryCode || {}}
                        >
                          <SelectItem text="Select" />
                          {
                            allCountries && Array.isArray(allCountries) && allCountries.map((b, i) => (
                              <SelectItem key={i} text={`${b.countryFull}(+${b.countryCode})`} value={`${b.countryCode}`} />
                            ))
                          }
                        </Select>
                      </div>
                      <div className="w-75 ml-2">
                        <TextInput placeholder="9876543210" id="contact" disabled={disabled} labelText="" hideLabel name="contact" max="10" value={contact || ''} onChange={this.handleText} />
                      </div>
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('portfolioLink')}</div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder="www.myportfolio.com" disabled={disabled} labelText="" hideLabel name="portfolioLink" max="20" value={this.state.portfolioLink || ''} onChange={this.handleText} id="portfolioLink" />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3 pt-2 pb-2">
                    <div className="col-sm-12 mt-2 col-md-3 form-label bx--type-zeta mb-1">
                      <div>
                        {t('residentStatus')}<span className="text-danger">*</span>
                      </div>
                      <div style={{ fontWeight: 'normal' }} className="small mt-1">
                        {t('residentStatus1')}
                      </div>
                    </div>
                    <div className="d-flex">
                      <RadioButtonGroup
                        legend=""
                        name="residentStatus"
                        disabled={disabled}
                        className="ml-3"
                        valueSelected={residentStatus}
                        onChange={this.onresidentStatusChange}
                      >
                        <RadioButton
                          value="singapore"
                          id="singapore"
                          disabled={disabled}
                          labelText={t('singapore')}
                        />
                        <RadioButton
                          value="singapore-pr"
                          id="singapore-pr"
                          disabled={disabled}
                          labelText={t('singaporePr')}
                        />
                        {/* <RadioButton
                          value="others"
                          disabled={disabled}
                          id="others"
                          labelText={t('others')}
                        /> */}
                      </RadioButtonGroup>
                    </div>
                  </div>

                  <div className="bx--col bx--row m-0 pl-3 pt-2 pb-2">
                    <div className="col-sm-12 mt-2 col-md-3 form-label bx--type-zeta mb-1" />
                    {
                      residentStatus && residentStatus === 'others'
                        && (
                          <div className="ml-3">
                            <Select
                              name="countryFull"
                              labelText=""
                              disabled={disabled}
                              hideLabel
                              id="countryFull"
                              onChange={e => this.handleChange(e)}
                              value={countryFull || {}}
                            >
                              <SelectItem text="Select" />
                              {
                                allCountries && Array.isArray(allCountries) && allCountries.map((b, i) => (
                                  <SelectItem key={i} text={b.countryFull} value={`${b.countryFull}`} />
                                ))
                              }
                            </Select>
                          </div>
                        )
                    }
                  </div>
                  <div className="bx--col bx--row m-0 pl-3 pt-2 pb-2">
                    <div className="col-sm-12 mt-2 col-md-3 form-label bx--type-zeta mb-1">
                      <div>
                        {t('gender')}<span className="text-danger">*</span>
                      </div>
                      <div style={{ fontWeight: 'normal' }} className="small mt-1">
                        {t('gender')}
                      </div>
                    </div>
                    <div className="d-flex">
                      <RadioButtonGroup
                          name="gender"
                          disabled={disabled}
                          className="ml-3"
                          valueSelected={gender}
                          onChange={this.onGenderStatusChange}
                        >
                          <RadioButton
                            value="Male"
                            id="male"
                            disabled={disabled}
                            labelText={t('male')}
                          />
                          <RadioButton
                            value="Female"
                            id="female"
                            disabled={disabled}
                            labelText={t('female')}
                          />
                        </RadioButtonGroup>
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3 pt-2 pb-2"><hr className="mt-2 mb-2 w-100"/></div>
                  <div className="h4 font-weight-bold text-dark bx--col bx--row m-0 pl-4 mb-2">
                    {t('address')}
                  </div>
                    <div className="pl-4 w-50 small-devices-width">
                      <OfficeDetails
                        disabled={disabled}
                        countries={allCountries}
                        handleChange={this.handleChange}
                        city={city}
                        zipcode={zipcode}
                        // officeName={officeName}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        mailingAddress={mailingAddress}
                        country={country}
                        showOfcInput={false}
                        // showDropDownLoading={showDropDownLoading}
                        // isValidAddrLine1={isValidAddrLine1}
                        // isValidAddrLine1Text={isValidAddrLine1Text}
                        // isValidCity={isValidCity}
                        // isValidCityText={isValidCityText}
                        // isValidCountry={isValidCountry}
                        // isValidCountryText={isValidCountryText}
                        // isValidZipcode={isValidZipcode}
                        // isValidZipcodeText={isValidZipcodeText}
                        // disabled={showSteps === false}
                        location={location}
                        handleDropdownChange={this.handleDropdownChange}
                        onInputDropdownChange={this.onInputDropdownChange}
                        locationsArr={locationsArr}
                      />
                    </div>
                  <div className="bx--col bx--row m-0 pl-3 pt-2 pb-2">
                    <hr className="mt-2 mb-2 w-100" />
                  </div>
                  <div className="h4 font-weight-bold text-dark bx--col bx--row mb-2 m-0 pl-4">
                    {t('education')} {t('information')}
                  </div>
                  <div className="bx--col ml-md-1 ml-lg-0 ">
                    <DropDownBox
                      title={t('school')}
                      required
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 mt-2"
                      className="pl-3 xpa-disabled-curson"
                      options={countries.map(one => ({
                        label: one.schoolName,
                        value: one.schoolId,
                      }))}
                      disabled
                      name="schoolValue"
                      onInputChange={e => this.onInputDropdown(e, 'school')}
                      placeholder={t('school')}
                      onChange={(id, value, name) => this.onDropdownChange(value, name)}
                      selectedValue={schoolValue}
                    />
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('studentId')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('studentId')}  labelText="" hideLabel name="studentUniversityId" max="20" value={studentUniversityId || ''} onChange={this.handleText} id="studentUniversityId" />
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('schoolEmail')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('emailSchool')} disabled labelText="" hideLabel name="email" max="50" value={email || ''} onChange={this.handleText} id="email" />
                    </div>
                  </div>
                  <div className={disabled === true ? 'bx--col ml-md-1 ml-lg-0 xpa-disabled-curson' : 'bx--col ml-md-1 ml-lg-0'}>
                    <DropDownBox
                      title={t('faculty')}
                      required
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 mt-2"
                      options={facultyData && Array.isArray(facultyData)
                        && facultyData.length > 0
                        ? (facultyData.map(one => ({
                          label: one.facultyName,
                          value: one.facultyId,
                        }))) : []}
                      disabled={disabled}
                      className="pl-3"
                      name="facultyValue"
                      onInputChange={e => this.onInputFacultyDropdow(e)}
                      placeholder={t('faculty')}
                      onChange={(id, value, name) => this.facultyDropdown(value)}
                      selectedValue={facultyValue}
                    />
                  </div>
                  <div className={(!facultyValue || !facultyValue.label) ? 'bx--col ml-md-1 ml-lg-0 xpa-disabled-curson' : (disabled === true ? 'bx--col ml-md-1 ml-lg-0 xpa-disabled-curson' : 'bx--col ml-md-1 ml-lg-0')}>
                    <DropDownBoxWithCreate
                      title={t('course')}
                      required
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 mt-2"
                      options={coursesData && Array.isArray(coursesData)
                        && coursesData.length > 0
                        ? (coursesData.map(one => ({
                          label: one.courseName,
                          value: one.courseId,
                        }))) : []}
                      className="pl-3"
                      disabled={(facultyValue && !facultyValue.label) || (!facultyValue) || disabled}
                      newOptionCreator={this.onNewOptionCreate}
                      name="courseValue"
                      onInputChange={e => this.onInputCoursesDropdow(e)}
                      placeholder={t('course')}
                      onChange={(value, name) => this.coursesDropdown(name, value)}
                      selectedValue={courseValue}
                    />
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('studentGpa')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('studentGpa')} id="currentGpa" disabled={disabled} labelText="" hideLabel name="currentGpa" max="10" value={currentGpa || ''} onChange={this.handleText} />
                    </div>
                  </div>
                  {/* <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('birthPlace')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <TextInput placeholder={t('birthPlace')} disabled={disabled} labelText="" hideLabel name="placeOfBirth" max="50" value={placeOfBirth || ''} onChange={this.handleText} id="placeOfBirth" />
                    </div>
                  </div> */}
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('internshipAvailability')}<span className="text-danger">*</span></div>
                    <div className="d-flex col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <div className="w-50">
                        <Select
                          name="months"
                          labelText=""
                          hideLabel
                          className="w-100"
                          disabled={disabled}
                          id="months"
                          onChange={e => this.handleChange(e)}
                          value={this.state.months || ''}
                        >
                          <SelectItem text="Select" />
                          {
                            months && Array.isArray(months) && months.map((b, i) => (
                              <SelectItem key={i} text={b.label} value={b.value} />
                            ))
                          }
                        </Select>
                      </div>
                      <div className="w-50 ml-2">
                        <Select
                          name="years"
                          labelText=""
                          hideLabel
                          className="w-100"
                          disabled={disabled}
                          id="years"
                          onChange={e => this.handleChange(e)}
                          value={this.state.years || ''}
                        >
                          <SelectItem text="Select" />
                          {
                            years && Array.isArray(years) && years.map((b, i) => (
                              <SelectItem key={i} text={`${b}`} value={`${b}`} />
                            ))
                          }
                        </Select>
                      </div>
                      {/* <DatePicker
                        id="date-picker"
                        onChange={this.onAvailabilityChange}
                        minDate={moment().format('MM/DD/YYYY')}
                        // maxDate={availabilityEnd}
                        datePickerType="single"
                      >
                        <DatePickerInput
                          id="min-date-pub-can"
                          className=""
                          labelText=""
                          name="availabilityStart"
                          pattern="d{1,2}/d{4}"
                          value={availabilityStart}
                          disabled={disabled}
                          placeholder="dd/mm/yyyy"
                        /> */}
                        {/* <DatePickerInput
                          id="max-date-pub-can"
                          labelText=""
                          pattern="d{1,2}/d{4}"
                          placeholder="dd/mm/yyyy"
                        /> */}
                      {/* </DatePicker> */}
                    </div>
                  </div>
                  <div className="bx--col bx--row m-0 pl-3">
                    <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('durationInWeeks')}<span className="text-danger">*</span></div>
                    <div className="col-md-9 col-sm-12 newjob_input_container pl-2 pl-md-3 pr-2 pr-md-3">
                      <Select
                        name="durationInWeek"
                        labelText=""
                        hideLabel
                        className="w-100"
                        disabled={disabled}
                        id="durationInWeek"
                        onChange={e => this.handleChange(e)}
                        value={durationInWeek || {}}
                      >
                        <SelectItem text="Select" />
                        {
                          finalArr && Array.isArray(finalArr) && finalArr.map(b => (
                            <SelectItem key={b} text={b} value={`${b}`} />
                          ))
                        }
                      </Select>
                    </div>
                  </div>
                  <div className={disabled ? 'bx--col ml-md-1 ml-lg-0 xpa-disabled-curson' : 'bx--col ml-md-1 ml-lg-0'}>
                    <DropDownBox
                      title={t('currentYearOfStudy')}
                      disabled={disabled}
                      required
                      className="pl-3"
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 mt-2"
                      mainClassName="row m-0 pl-0"
                      options={yearsOfStudy.map((year, i) => ({ label: year, value: i + 1 }))}
                      name="yearOfStudy"
                      placeholder={t('year')}
                      onChange={(id, value, name) => this.onDropdownChange(value, name)}
                      selectedValue={yearOfStudy}
                    />
                  </div>
                  <div className={disabled ? 'bx--col ml-md-1 ml-lg-0 xpa-disabled-curson' : 'bx--col ml-md-1 ml-lg-0'}>
                    <DropDownBox
                      title={t('semester')}
                      disabled={disabled}
                      required
                      labelClass="bx--type-zeta mb-0"
                      titleClass="col-sm-12 col-md-3 mt-2 mr-1"
                      mainClassName="row m-0 pl-0"
                      options={semestersOfStydy.map((sem, i) => ({ label: sem, value: i + 1 }))}
                      name="semOfStudy"
                      placeholder={t('semester')}
                      onChange={(id, value, name) => this.onDropdownChange(value, name)}
                      selectedValue={semOfStudy}
                    />
                  </div>
                  <div className={`bx--col ml-md-1 ml-lg-0 m-0 mb-2 ${disabled ? "xpa-disabled-curson":''}`}>
                    {/* <div className={disabled ? 'bx--col-lg-8 bx--col-md-5 p-0 xpa-disabled-curson' : 'bx--col-lg-8 bx--col-md-5 p-0'}> */}
                      <DropDownBox
                        title={t('motherTongue')}
                        disabled={disabled}
                        // custom
                        required
                        // className="col-sm-12 col-md-5 pl-4"
                        labelClass="bx--type-zeta mb-0"
                        titleClass="col-sm-12 col-md-3 mt-2 mr-1"
                        mainClassName="row m-0 pl-0"
                        options={motherTongueListArr.map((one, i) => ({ value: one, label: one }))}
                        name="motherTongue"
                        placeholder={t('motherTongue')}
                        onChange={(id, value, name) => this.onDropdownChange(value, name)}
                        selectedValue={motherTongue}
                      />
                    {/* </div> */}
                    {/* <div className="bx--row m-0 p-0 mb-3">
                      <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('score')}</div>
                      <div className="col-md col-sm-12 pl-2 pl-md-0 pr-2 pr-md-3">
                        <TextInput type="number" placeholder={t('score')} id="chineseOGradeScore" disabled={disabled} labelText="" hideLabel name="chineseOGradeScore" max="100" value={chineseOGradeScore || ''} onChange={this.handleText} />
                      </div>
                    </div> */}
                  </div>
                  <div className={`bx--col ml-md-1 ml-lg-0 m-0 mt-2 mb-2 ${disabled ? "xpa-disabled-curson" : ""}`}>
                    {/* <div className={disabled ? 'bx--col-lg-8 bx--col-md-5 p-0 xpa-disabled-curson' : 'bx--col-lg-8 bx--col-md-5 p-0'}> */}
                      <DropDownBox
                        title={t('chineseGrade')}
                        disabled={disabled}
                        // custom
                        required
                        // className="col-sm-12 col-md-5 pl-4"
                        labelClass="bx--type-zeta mb-0"
                        titleClass="col-sm-12 col-md-3 mt-2 mr-1"
                        options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
                        name="chineseOGrade"
                        placeholder={t('grade')}
                        onChange={(id, value, name) => this.onDropdownChange(value, name)}
                        selectedValue={chineseOGrade}
                      />
                    {/* </div> */}
                    {/* <div className="bx--row m-0 p-0 mb-3">
                      <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('score')}</div>
                      <div className="col-md col-sm-12 pl-2 pl-md-0 pr-2 pr-md-3">
                        <TextInput type="number" placeholder={t('score')} id="chineseOGradeScore" disabled={disabled} labelText="" hideLabel name="chineseOGradeScore" max="100" value={chineseOGradeScore || ''} onChange={this.handleText} />
                      </div>
                    </div> */}
                  </div>
                  <div className={`bx--col ml-md-1 ml-lg-0 m-0 mb-2 align-items-center ${disabled ? "xpa-disabled-curson" : ""}`}>
                    {/* <div className={disabled ? 'bx--col-lg-8 bx--col-md-5 p-0 xpa-disabled-curson' : 'bx--col-lg-8 bx--col-md-5 p-0'}> */}
                      <DropDownBox
                        title={t('higherChineseGrade')}
                        disabled={disabled}
                        // custom
                        required
                        // className="col-sm-12 col-md-5 pl-4"
                        labelClass="bx--type-zeta mb-0"
                        titleClass="col-sm-12 col-md-3 mt-2 mr-1"
                        options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
                        name="chineseHigherOGrade"
                        placeholder={t('grade')}
                        onChange={(id, value, name) => this.onDropdownChange(value, name)}
                        selectedValue={chineseHigherOGrade}
                      />
                    {/* </div> */}
                    {/* <div className="bx--row m-0 p-0 mb-3">
                      <div className="col-sm-12 mt-3 col-md-3 form-label bx--type-zeta mb-1">{t('score')}</div>
                      <div className="col-md col-sm-12 pl-2 pl-md-0 pr-2 pr-md-3">
                        <TextInput type="number" placeholder={t('score')} id="chineseHigherOGradeScore" disabled={disabled} labelText="" hideLabel name="chineseHigherOGradeScore" max="100" value={chineseHigherOGradeScore || ''} onChange={this.handleText} />
                      </div>
                    </div> */}
                  </div>
                  <div className={`bx--col ml-md-1 ml-lg-0 m-0 align-items-center ${disabled ? "xpa-disabled-curson" : ""}`}>
                    {/* <div className={disabled ? 'bx--col-lg-8 bx--col-md-5 p-0 xpa-disabled-curson' : 'bx--col-lg-8 bx--col-md-5 p-0'}> */}
                      <DropDownBox
                        title={t('internshipPreference')}
                        disabled={disabled}
                        // custom
                        required
                        // className="col-sm-12 col-md-5 pl-4"
                        labelClass="bx--type-zeta mb-0"
                        titleClass="col-sm-12 col-md-3 mt-2 mr-1"
                        options={internshipPreferenceListArr.map((one, i) => ({ value: one, label: one }))}
                        name="internshipPreference"
                        placeholder={t('internshipPreference')}
                        onChange={(id, value, name) => this.onDropdownChange(value, name)}
                        selectedValue={internshipPreference}
                      />
                      <div style={{ marginLeft: "10px", fontSize: "small" }}>
                        <strong>Note: </strong>Your preference is just an indicator because the final placement will be dependent on the interview
                      </div>
                    {/* </div> */}
                  </div>
                  {
                    disabled === false && (
                      <div className="d-flex w-100 justify-content-center mt-5">
                        <div>
                          <Button onClick={this.handlePreviousButtonClick} className="mr-2" kind="tertiary" small>{t('previous')}</Button>
                        </div>
                        <div>
                          <Button onClick={this.handleNextButtonClick} kind="primary" small>{t('next')}</Button>
                        </div>
                      </div>
                    )
                  }
                </Fragment>
              )
          }
          <JDProcessOverlay show={loading || profileLoader || this.state.showLoading} message="processing..." />
        </div>
        {/* {
            indexId && (Number(indexId) === 3)
              && (
                <SkillsAndLanguage
                  ref="skillsAndLanguage"
                  indexId={indexId}
                  candidateProfile={candidateProfile}
                  push={push}
                />
              )
          } */}
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  candidateDetails: state.candidateDetails,
  facultyData: state.candidateDetails.facultyData,
  allCountries: state.RegistrationRequest.countries,
  coursesData: state.candidateDetails.coursesData,
  loading: state.candidateDetails.loading,
  profileLoader: state.roboroyApplications.loading,
  locations: state.RegistrationRequest.locations,
});

const mapDispatchToProps = {
  getAllDropDownData,
  getFewDropDownData,
  getCandidateProfile,
  updateProfile,
  updateStudentProfile,
  createStudentProfile,
  getAllCourses,
  getAllFaculties,
  editProfileContact,
  getAllCountries,
  getLocations,
  createNewCourses,
  createCityPerference,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { withRef: true },
)(withTranslation()(PublicCandidateInfo));