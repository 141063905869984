import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import {
  Tile, Modal, Pagination, Button, ToolbarItem,
  Select, SelectItem, InlineNotification,
  RadioButton,
  RadioButtonGroup,
} from 'carbon-components-react';
import queryString from 'query-string';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { getCustomEmailTemplates } from 'actions/email-templates';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import BackRow from 'components/common/BackRow/BackRow';
import FacetsBrowse from 'components/roboroy/common/FacetsBrowse';
import Avatar from 'components/common/Avatar';
import {
  fetchAllStudentsList,
  getStudentsList,
  getStudentsFacets,
  sendOfferConfirmationMail
} from 'actions/BrowseStudents';
import {
  editCandidateProfile,
  updateSelectedForGeip
} from 'actions/talentSearch/candidatesList';
import { sendEmails } from 'actions/talentSearch/talentPool';
import { CheckmarkOutline16, CheckmarkFilled16, CloseOutline16, CloseFilled16, DocumentImport24, DocumentExport24, Download24, UserRole24, CalendarHeatMap24 } from '@carbon/icons-react';
import { keys } from 'lodash';
import "./BrowseStudents.css";
import {
  Layout,
  PagingInfo,
  Result,
  SearchBox,
  Facets,
  MultiCheckboxFacet,
  SingleSelectFacet,
  SingleLinksFacet,
} from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { getBatchDetails } from 'actions/BatchManagementView';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import { toast } from "react-toastify";

const NoStudentsBox = () => (
  <Tile className="">
    <div className="card-body text-center">
      <div className="empty-icon">
        <i className="fa fa-flag fa-2x" />
      </div>
      <p className="empty-title h5">There are no students for your current selection.</p>
    </div>
  </Tile>
);

class BrowseStudents extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus' },
    { id: 'student', name: 'Student', href: '' },
  ];



  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      activePage: 1,
      itemsPerPage: 10,
      isOpenMoreModal: false,
      mainVals: null,
      isOpenMarketModal: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { search, state } = location || {};
    const { batch = null, course = null, faculty = null, fromDashboard=false, addOn } = state || {};
    const { label = null, value = null } = addOn || {};
    this.props.getStudentsFacets().then(() => {
      this.props.getBatchDetails().then((res) => {
        const { facets } = this.props;
        const { list: batchList } = res;
        const currentBatch = batchList.filter((batch) => batch.isCurrentBatch === true)[0];
        // const tempBatch={ label: currentBatch.batchName, value: currentBatch.batchId };
        let defaultCheckList = {};
        defaultCheckList["Suspended"] = ["no"];
        if (batch != null && batch != undefined && batch != "") {
          defaultCheckList["Batch"] = [batch];
        } else if(!fromDashboard){
          defaultCheckList["Batch"] = [currentBatch.batchName];
        }
        defaultCheckList["Courses"] = [course];
        defaultCheckList["Faculties"] = [faculty];
        defaultCheckList[label] = [value];
        if (currentBatch !== null && currentBatch !== undefined && batchList !== null && batchList !== undefined) {
          this.setState({
            ...this.state,
            currentBatch: currentBatch,
            batchDefaultValue: currentBatch.batchName,
            facets: facets,
            defaultCheckList
          }, () => {

            this.handleChangeCheckBoxVals("no", true, "Suspended");
            if (batch != null && batch != "") {
              this.handleChangeCheckBoxVals(batch, true, "Batch");
            } else if(!fromDashboard){
              this.handleChangeCheckBoxVals(currentBatch.batchName, true, "Batch");
            }
            if (course != null && course != "") {
              this.handleChangeCheckBoxVals(course, true, "Courses");
            }
            if (faculty != null && faculty != "") {
              this.handleChangeCheckBoxVals(faculty, true, "Faculties")
            }
            if (value != null && value != "" && label != null && label != "") {
              this.handleChangeCheckBoxVals(value, true, label)
            }
          })
        } else {
          this.setState({
            ...this.state,
            facets: facets,
            defaultCheckList
          })
        }
      });
    }
    );
    if (search) {
      this.handleSearchQuery();
    } else {
      this.handleSubmitFilterVals();
      // this.handleChangeCheckBoxVals("no", true, "Suspended");
    }
    this.props.getCustomEmailTemplates();
  }


  // setFilterPropsArr=(filterPropsArr)=>{
  //   console.log(filterPropsArr)
  //   this.setState({

  //     filterPropsArr,
  //   })
  // }

  handleSearchQuery = () => {
    const {
      location: {
        search,
      } = {},
    } = this.props;
    const splitSearchVal = search && search.toString()
      && search.split('?');
    const stringVals = splitSearchVal && Array.isArray(splitSearchVal)
      && splitSearchVal.length > 1
      && splitSearchVal[1];
    const filterNameArr = stringVals && stringVals.toString()
      && stringVals.split('&');
    const filterNameSplit = filterNameArr && Array.isArray(filterNameArr)
      && filterNameArr.length > 0
      && filterNameArr.forEach((res) => {
        if (res) {
          const valSpl = res.split('=');
          let valueArr = [];
          const decodedVal = valSpl[1] && decodeURIComponent(valSpl[1]);
          if (decodedVal && decodedVal.includes(',')) {
            valueArr = decodedVal.split(',');
          } else {
            valueArr = [decodedVal];
          }
          const finalFilterName = decodeURIComponent(valSpl[0]);
          this.setState({
            [finalFilterName]: valueArr,
            finalString: search,
          }, () => {
            this.handleSubmitFilterVals(null);
          });
        }
      });
  }

  searchTimer = null;

  handleSearchSubmit = (event) => {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.setState({ keyword: event.target.value, searchKey: event.target.value, activePage: 1 }, () => {
      const {
        itemsPerPage,
        activePage,
        keyword,
      } = this.state;

      this.searchTimer = setTimeout(() => (
        this.handleSubmitFilterVals()
      ), 1000);
    });
  }

  activeMoreModal = (keys) => {
    this.setState({
      isOpenMoreModal: true,
      mainVals: keys,
    });
  }

  dismissMoreModal = () => {
    this.setState({
      isOpenMoreModal: false,
      mainVals: null,
    });
  }

  handlePaginationChange = (data) => {
    const activePage = (data.page) || 1;
    const itemsPerPage = data.pageSize || this.state.itemsPerPage;
    this.setState({ activePage, itemsPerPage }, () => {
      this.props.getStudentsList(this.state);
    });
  }

  handleRedirectProfilePage = (profileId, studentProfileId) => {
    const {
      history: { push } = {},
    } = this.props;
    if (push) {
      push(`/app/campus/profile/${profileId}/student/${studentProfileId}`);
    }
  }

  handleSelectCustomRegModal = (actionItems, actionState) => {
    if (actionState !== 'list') {
      this.setState({
        selectedValue: null,
        isOpenPassiveCustRegModal: true,
        actionItems,
        actionState,
      });
    } else if (actionState === 'list') {
      this.setState({
        selectedValue: null,
        bulkActionsModal: true,
        actionItems,
        actionState,
      });
      // this.setState({
      //   selectedValue: null,
      //   isOpenMarketModal: true,
      //   actionItems,
      // });
    }
  }

  batchActionClickHandler = () => {
    this.setState({
      selectTemplateModal: true,
    });
    const {
      actionItems, actionState,
    } = this.state;
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      const emailData = this.handleCurrentSelectedEmail(actionItemsIds);
      this.setState({ selectTemplateModal: true, selectedEmails: emailData });
    } else if (actionState === 'email-all') {
      const {
        count,
      } = this.props;
      const data = {
        itemsPerPage: count,
        activePage: 1,
        searchKey: null,
      };
      this.setState({
        itemsPerPage: count,
      }, () => {
        this.props.getStudentsList(this.state).then((res) => {
          if (res && !res.error) {
            const {
              students,
            } = this.props;
            let studentProfileIds = [];
            if (students && Array.isArray(students) && students.length > 0) {
              studentProfileIds = students.map((stds) => {
                const {
                  _source: {
                    studentProfileId,
                  } = {},
                } = stds || {};
                return studentProfileId;
              });
            }
            this.getSelectedRowsMails(studentProfileIds);
          }
        });
      });
    }
  }

  handleSubmitOpenMarketData = () => {
    const {
      actionItems,
      selectedOpenMarketValue,
    } = this.state;
    const selectedOpenMarketValueInvalid = !selectedOpenMarketValue ? true : false;
    this.setState({
      selectedOpenMarketValueInvalid,
    })
    if(selectedOpenMarketValueInvalid){
      return;
    }
    let actionItemsIds = [];
    if (actionItems && Array.isArray(actionItems) && actionItems.length > 0) {
      actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('row_')[1]));
    }
    const {
      students,
    } = this.props;
    let profileIds = [];
    let filteredList = [];
    if (actionItemsIds && Array.isArray(actionItemsIds) && actionItemsIds.length > 0
      && students && Array.isArray(students) && students.length > 0) {
      filteredList = students.filter(res => actionItemsIds.includes(res && res._source && res._source.studentProfileId));
    }
    if (filteredList && Array.isArray(filteredList) && filteredList.length > 0) {
      profileIds = filteredList.map((res) => {
        const {
          _source: {
            profileId,
          } = {},
        } = res || {};
        return profileId && Number(profileId);
      });
    }
    const data = {
      profileIds,
      isOpenMarket: selectedOpenMarketValue == 'isOpenMarket-true' ? true : selectedOpenMarketValue == 'isOpenMarket-false' ? false : null,
    };
    this.props.editCandidateProfile(data).then((res) => {
      if (res && !res.error) {
        this.handleSubmitFilterVals();
        this.setState({
          isOpenMarketModal: false,
        });
      }
    });
  }

  handleSubmitSendRegEmail = () => {
    const { selectedValue: templateName } = this.state || {};
    const templateId =  templateName == 'imported-students-registration' ? 1021 :
      templateName == 'geip-appln-submission-reminder'? 51 : 49;
    const {
      actionItems, actionState,
    } = this.state;
    let emailData = [];
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      emailData = this.handleCurrentSelectedEmail(actionItemsIds);
      const finalData = {
        templateId,
        templateName,
        emailData,
        actionName: 'send-email',
      };
      this.props.sendEmails(finalData).then((res) => {
        // if (res && !res.error) {
          this.setState({
            isOpenSendRegEmailModal: false,
            isOpenPassiveCustRegModal: false,
          });
        // }
      });
    } else if (actionState === 'email-all') {
      const {
        count,
      } = this.props;
      const data = {
        itemsPerPage: count,
        activePage: 1,
        searchKey: null,
      };
      this.setState({
        itemsPerPage: count,
      }, () => {
        this.props.getStudentsList(this.state).then((res) => {
          if (res && !res.error) {
            const {
              students,
            } = this.props;
            let studentProfileIds = [];
            if (students && Array.isArray(students) && students.length > 0) {
              studentProfileIds = students.map((stds) => {
                const {
                  _source: {
                    studentProfileId,
                  } = {},
                } = stds || {};
                return studentProfileId;
              });
            }
            emailData = this.handleGetSelectedEmails(studentProfileIds);
            const finalData = {
              templateId,
              templateName,
              emailData,
              actionName: 'send-email',
            };
            this.props.sendEmails(finalData).then((ems) => {
              if (ems && !ems.error) {
                this.setState({
                  isOpenSendRegEmailModal: false,
                  isOpenPassiveCustRegModal: false,
                  itemsPerPage: 10,
                }, () => {
                  this.props.getStudentsList(this.state)
                });
              }
            });
          }
        });
      });
    }
  }

  templateModalSubmit = () => {
    const { selectedEmails, selectedEmailTemplate } = this.state;
    if (selectedEmailTemplate === null || selectedEmailTemplate.indexOf('placeholder-item') > -1) {
      this.setState({ noTemplateError: true });
      return;
    }
    if (Array.isArray(selectedEmails) && selectedEmails.length === 0) {
      this.setState({
        selectedEmailTemplate: 'placeholder-item',
        selectTemplateModal: false,
        selectedEmails: [],
        noTemplateError: false,
      });
      return;
    }
    const templateId = selectedEmailTemplate.split('_')[0];
    const templateName = selectedEmailTemplate.split('_')[1];
    const emailData = selectedEmails;
    const data = {
      templateId,
      templateName,
      emailData,
      actionName: 'user-defined-email',
    };
    // user-defined-email

    this.setState({
      // selectedEmailTemplate: 'placeholder-item',
      selectedEmails: [],
      noTemplateError: false,
    }, () => {
      this.props.sendEmails(data).then((res) => {
        if (res && !res.error) {
          this.setState({
            showSuccessMsg: true,
          }, () => {
            setTimeout(() => {
              this.templateModalClose();
            }, 5000);
          });
        } else if (res && res.error) {
          this.setState({
            noTemplateError: true,
          });
        }
      });
    });
  }

  templateModalClose = () => {
    this.setState({
      selectedEmailTemplate: 'placeholder-item',
      selectTemplateModal: false,
      selectedEmails: [],
      noTemplateError: false,
    });
  }

  onEmailTemplateChange = (e) => {
    const selectedEmailTemplate = e.target.value;
    this.setState({ selectedEmailTemplate, noTemplateError: false });
  }

  capitalizeLetter = str => {
    str = (str && str.trim().split(" ")) || [];
    for (var i = 0, x = str.length; i < x; i++) {
      str[i] =
        ((str[i][0] && str[i][0].toUpperCase && str[i][0].toUpperCase()) || "") +
        str[i].substr(1);
    }

    return str.join(" ");
  };

  handleCurrentSelectedEmail = (selected) => {
    const {
      students,
    } = this.props;
    const mailsArray = Array.isArray(students) ? students.filter((res) => {
      const {
        _source: {
          studentProfileId,
        } = {},
      } = res || {};
      return selected.indexOf(studentProfileId) > -1;
    }) : [];
    let emailData = [];
    if (mailsArray && Array.isArray(mailsArray) && mailsArray.length > 0) {
      emailData = mailsArray.map((res) => {
        let {
          _source: {
            studentUniversityId,
            courseId,

            courses: {
              courseName
            } ={},
            profile: {
              profileId,
              firstName,
              lastName,
              email,
            } = {},
          } = {},
        } = res || {};
        courseName = this.capitalizeLetter(courseName)
        const data = {
          email,
          profileId,
          payload: {
            profileId,
            email,
            firstName,
            lastName,
            studentUniversityId,
            courseName
          },
        };
        return data;
      });
    }
    return emailData;
  }

  handleGetSelectedEmails = (selected) => {
    const { students } = this.props;
    const mailsArray = Array.isArray(students) ? students.filter((res) => {
      const {
        _source: {
          studentProfileId,
        } = {},
      } = res || {};
      return selected.indexOf(studentProfileId) > -1;
    }) : [];
    const emailData = [];
    if (Array.isArray(mailsArray)) {
      mailsArray.forEach((e) => {
        let {
          _source: {
            profileId, profile: { email, firstName, lastName } = {},
            studentUniversityId,
            courseId,
            courses: { courseName } ={}
          } = {},
        } = e || {};
        courseName = this.capitalizeLetter(courseName)
        if (email !== null && email !== undefined && email !== '' && email.toString() && profileId !== null && email.length > 0) {
          emailData.push({
            email,
            profileId,
            payload: {
              email,
              firstName,
              lastName,
              studentUniversityId,
              courseName
            },
          });
        }
      });
    }
    return emailData;
  }

  getSelectedRowsMails = (selected) => {
    const emailData = this.handleGetSelectedEmails(selected);
    this.setState({ selectTemplateModal: true, selectedEmails: emailData });
  }

  handleChangeCheckBoxVals = (key, e, res) => {
    const {
      mainVals,
      facets
    } = this.state;
    let {
      finalString,
    } = this.state;
    let filterVals = this.state[`${res}`];
    if (e === true) {
      if (!Array.isArray(filterVals)) {
        filterVals = [key];
      } else if (filterVals && Array.isArray(filterVals) && filterVals.length <= 0) {
        filterVals = [key];
      } else {
        filterVals.push(key);
      }
    }
    if (res === "Faculties" || res === "Courses" || res === "Batch") {
      facets[`${res}`] = facets[`${res}`].map((item) => {
        if (item.key === key) {
          item.isSelected = e;
        }
        return item;
      })
    } else {
      facets[`${res}`] = facets[`${res}`].map((item) => {
        if (item.key === key) {
          item.isSelected = e;
        } else {
          item.isSelected = false;
        }
        return item;
      })
    }
    finalString = '';
    const facetValues = {};
    for (let arr in facets) {
      if (facets[arr] && Array.isArray(facets[arr]) && facets[arr].length > 0) {
        const fv = facets[arr].map((item) => {
          if (item.isSelected === true) {
            return item["key"];
          } else {
            return null
          }
        }).filter((item) => item !== null);
        if (fv && Array.isArray(fv) && fv.length > 0) {
          finalString += `${arr}=${encodeURIComponent(fv)}&`;
        }
        facetValues[arr] = fv
      }
    }

    queryString.stringify(finalString);
    if (e === true || e === false) {
      this.setState({
        ...facetValues,
        facets: facets,
        finalString,
      }, () => {
        // this.props.history.push({
        //   pathname: '/app/campus/browse/students',
        //   search: `?${this.state.finalString}`,
        // });
        if (mainVals === null) {
          this.handleSubmitFilterVals(res);
        }
      });
    } else if (mainVals === null) {
      this.handleSubmitFilterVals(res);
    }
  }

  handleSelectClearAll = (res, isSelectAll) => {
    const {
      mainVals,
      facets
    } = this.state;
    facets[`${res}`] = facets[`${res}`].map((item) => {
      if (isSelectAll) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      return item;
    })
    const facetValues = {};
    for (let arr in facets) {
      if (facets[arr] && Array.isArray(facets[arr]) && facets[arr].length > 0) {
        const fv = facets[arr].map((item) => {
          if (item.isSelected === true) {
            return item["key"];
          } else {
            return null
          }
        }).filter((item) => item !== null);
        facetValues[arr] = fv
      }
    }
    this.setState({
      ...facetValues,
      facets: facets,
    }, () => {
      if (mainVals === null) {
        this.handleSubmitFilterVals(res);
      }
    });

  }

  handleSubmitFilterVals = (res) => {
    const finaldata = this.state;
    this.props.getStudentsList(finaldata);
    this.props.getStudentsFacets(finaldata);
  }

  handleClearFilters = () => {
    this.props.getStudentsFacets().then(() => {
      const { facets } = this.props;
      this.setState({ facets: facets })
    });
    this.setState({
      Faculties: [],
      Courses: [],
      Institutions: [],
      Selected: [],
      'Applied to Jobs': [],
      Hired: [],
      'Completed Workflow': [],
      'Application Submitted': [],
      Suspended: [],
      'Accepted Offer': [],
      'Open Market': [],
      'Internship Date': [],
      'Uploaded Signed Offer Letter': [],
      'Offer In Progress': [],
      "Shortlisted By Companies": [],
      Batch: [],
      'Profiling Assessment': [],
      'Selected for GEIP': [],
      'Room Offline Video Interview': [],
      'Booked Panel Interview': [],
      finalString: null,
      Scholar: [],
    }, () => {
      this.props.history.push('/app/campus/browse/students');
      this.handleSubmitFilterVals(null);

    });
  }

  handleSubmitCheckBox = () => {
    this.handleSubmitFilterVals(null);
    this.dismissMoreModal();
  }

  handleRedirectImportStdsPage = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props;
    if (push) {
      push('/app/campus/import/students');
    }
  }

  handleChangeRadioButton = (e) => {
    switch (e) {
      case 'geip-appln-submission-reminder':
        this.setState({
          selectedValue: e,
          templates: false,
          isOpenSendRegEmailModal: true,
        })
        break;
      case 'panel-interview-booked':
        this.setState({
          selectedValue: e,
          templates: false,
          isOpenSendRegEmailModal: true,
        })
        break;
      case 'custom-templates':
        this.setState({
          selectedValue: e,
          templates: true,
          // isOpenPassiveCustRegModal: false,
        }, () => {
          this.batchActionClickHandler();
        });
        break;
      case 'imported-students-registration':
        this.setState({
          selectedValue: e,
          templates: false,
          isOpenSendRegEmailModal: true,
        });
        break;
      case 'offer-letter-submission-reminder':
        this.setState({
          selectedValue: e,
          isOpenSendReminderEmailModal: true
        });
        break;
      case 'placement-confirmation-email':
        this.setState({
          selectedValue: e,
          isOpenSendConfirmEmailModal: true
        });
        break;
      // case 'isOpenMarket-true':
      //   this.setState({
      //     selectedValue: e,
      //     isOpenMarket: true,
      //   });
      //   break;
      // case 'isOpenMarket-false':
      //   this.setState({
      //     selectedValue: e,
      //     isOpenMarket: false,
      //   });
      //   break;
      case 'open-market-to-students':
        this.setState({
          isOpenMarketModal: true,
          bulkActionsModal: false,
        });
        break;
      case 'selected-for-geip':
        this.setState({
          bulkActionsModal: false,
          isOpenSelectedForGeipModal: true,
        });
        break;
      default:
        break;
    }
  };

  handleSubmitSendReminderEmail = () => {
    console.log("handleSubmitSendReminderEmail called")
    const {
      actionItems, actionState,
    } = this.state;
    let emailData = [];
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      emailData = this.handleCurrentSelectedEmail(actionItemsIds);
      const offerReminderData = {
        // templateId: 1022,
        templateName: 'offer-letter-reminder',
        emailData,
        actionName: 'send-email',
      };
      this.props.sendEmails(offerReminderData).then((res) => {
        if (res && !res.error) {
          this.setState({
            isOpenSendReminderEmailModal: false,
            isOpenPassiveCustRegModal: false,
          });
        }
      });
    } else if (actionState === 'email-all') {
      const {
        count,
      } = this.props;
      const data = {
        itemsPerPage: count,
        activePage: 1,
        searchKey: null,
      };
      this.setState({
        itemsPerPage: count,
      }, () => {
        this.props.getStudentsList(this.state).then((res) => {
          if (res && !res.error) {
            const {
              students,
            } = this.props;
            let studentProfileIds = [];
            if (students && Array.isArray(students) && students.length > 0) {
              studentProfileIds = students.map((stds) => {
                const {
                  _source: {
                    studentProfileId,
                  } = {},
                } = stds || {};
                return studentProfileId;
              });
            }
            emailData = this.handleGetSelectedEmails(studentProfileIds);
            const finalData = {
              // templateId: 1022,
              templateName: 'offer-letter-reminder',
              emailData,
              actionName: 'send-email',
            };
            this.props.sendEmails(finalData).then((ems) => {
              if (ems && !ems.error) {
                this.setState({
                  isOpenSendReminderEmailModal: false,
                  isOpenPassiveCustRegModal: false,
                  itemsPerPage: 10,
                }, () => {
                  this.props.getStudentsList(this.state)
                });
              }
            });
          }
        });
      });
    }
  }

  handleSubmitSendConfirmationEmail = () => {
    console.log("handleSubmitConfirmationReminderEmail called")
    const {
      actionItems, actionState,
    } = this.state;
    let emailData = [];
    if (actionState === 'email') {
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[1]));
      emailData = this.handleCurrentSelectedEmail(actionItemsIds);
      let profileIds = [];
      if(emailData && Array.isArray(emailData) && emailData.length > 0){
        emailData.forEach((res) => {
          let profileId = res && res.profileId
          profileIds.push(profileId)
        })
      }
      let dataDet = {}
      dataDet.profileIds = profileIds
      this.props.sendOfferConfirmationMail(dataDet).then((res) => {
        if (res && !res.error) {
          this.setState({
            isOpenSendConfirmEmailModal: false,
            isOpenPassiveCustRegModal: false,
          });
        }
      });
    } else if (actionState === 'email-all') {
      const {
        count,
      } = this.props;
      const data = {
        itemsPerPage: count,
        activePage: 1,
        searchKey: null,
      };
      this.setState({
        itemsPerPage: count,
      }, () => {
        this.props.getStudentsList(this.state).then((res) => {
          if (res && !res.error) {
            const {
              students,
            } = this.props;
            let studentProfileIds = [];
            if (students && Array.isArray(students) && students.length > 0) {
              studentProfileIds = students.map((stds) => {
                const {
                  _source: {
                    studentProfileId,
                  } = {},
                } = stds || {};
                return studentProfileId;
              });
            }
            emailData = this.handleGetSelectedEmails(studentProfileIds);
            let profileIds = [];
            if(emailData && Array.isArray(emailData) && emailData.length > 0){
              emailData.forEach((res) => {
                let profileId = res && res.profileId
                profileIds.push(profileId)
              })
            }
            let dataInfo = {}
            dataInfo.profileIds = profileIds
            this.props.sendOfferConfirmationMail(dataInfo).then((ems) => {
              if (ems && !ems.error) {
                this.setState({
                  isOpenSendConfirmEmailModal: false,
                  isOpenPassiveCustRegModal: false,
                  itemsPerPage: 10,
                }, () => {
                  this.props.getStudentsList(this.state)
                });
              }
            });
          }
        });
      });
    }
  }

  onViewEmail = () => {
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/imported-students-registration`, 'Email Preview', params);
  }

  onViewReminderEmail = () => {
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/offer-letter-reminder`, 'Email Preview', params);
    console.log("onViewReminderEmail called")
  }

  onCustomTemplatePreview = () => {
    const { selectedEmailTemplate = '' } = this.state;
    if (selectedEmailTemplate !== null && selectedEmailTemplate !== undefined && selectedEmailTemplate !== '' && selectedEmailTemplate !== 'placeholder-item') {
      const previewEmailName = selectedEmailTemplate.split('_')[1];
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
      window.open(`/emailpreview/${previewEmailName}`, 'Email Preview', params);
    }
  }

  onViewConfirmationEmail = () => {
    const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=680';
    window.open(`/emailpreview/student-placement-confirmation-email`, 'Email Preview', params);
    console.log("onViewConfirmationEmail called")
  }


  handleFacetsLabels = (label) => {
    let labelValue = '';
    switch (label) {
      case 'Faculties':
        labelValue = 'Schools';
        break;
      case 'Application Submitted':
        labelValue = 'Profile completion status';
        break;
      case 'Applied to Jobs':
        labelValue = 'Job Application status';
        break;
      case 'Completed Workflow':
        labelValue = 'Interview Completion Status';
        break;
      case 'Hired':
        labelValue = 'Placement Completion Status';
        break;
      case 'Accepted Offer':
        labelValue = 'Position offer status';
        break;
      default:
        labelValue = label;
        break;
    }
    return labelValue;
  }

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue
    })
  }

  handleSubmitSelectedForGeip = async () => {
    const { t } = this.props || {};
    const { selectedForGeip, geipInternshipProgram, actionItems } = this.state || {};
    let selectedGeipInvalid = false;
    let geipInternshipProgramInvalid = false;
    if(!selectedForGeip){
      selectedGeipInvalid = true;
    }
    if(selectedForGeip && selectedForGeip == 'no' && !geipInternshipProgram){
      geipInternshipProgramInvalid = true;
    }
    this.setState({
      selectedGeipInvalid,
      geipInternshipProgramInvalid
    })
    if(!selectedGeipInvalid && !geipInternshipProgramInvalid){
      const actionItemsIds = actionItems.map(({ id = '' }) => Number(id.split('_')[2]));
      const data = {
        profileIds: actionItemsIds,
        selected: selectedForGeip == 'yes' ? true : selectedForGeip == 'no' ? false : null,
        internshipProgram: geipInternshipProgram ? geipInternshipProgram.value : null,
      }
      this.setState({ selectedForGeipLoader: true })
      const res = await this.props.updateSelectedForGeip(data);
      this.setState({ selectedForGeipLoader: false })
      if(res && !res.error){
        this.dismissSelectedForGeipModal();
        this.handleSubmitFilterVals();
        toast.success(t("selected_for_geip_success"));
      } else {
        toast.error(t("selected_for_geip_error"));
      }
    }
  }

  dismissSelectedForGeipModal = (showBulkActionsModal = false) => {
    this.setState({ 
      isOpenSelectedForGeipModal: false,
      selectedForGeip: null,
      selectedGeipInvalid: false,
      geipInternshipProgram: null,
      geipInternshipProgramInvalid: false,
      bulkActionsModal: showBulkActionsModal
    });
  }

  dismissOpenMarketModal = (showBulkActionsModal = false) => {
    this.setState({ 
      isOpenMarketModal: false,
      selectedOpenMarketValue: null,
      selectedOpenMarketValueInvalid: false,
      bulkActionsModal: showBulkActionsModal
    });
  }

  render() {
    const {
      students, count, t, emailTemplates,
      loading, user: { userId, isSuperAdmin, isAdmin, company: { ctypeId } = {} } = {},
    } = this.props;
    const {
      activePage, selectTemplateModal, noTemplateError,
      selectedEmailTemplate, isOpenMoreModal,
      mainVals, showSuccessMsg, isOpenPassiveCustRegModal,
      isOpenSendRegEmailModal, isOpenSendReminderEmailModal,
      selectedValue,
      isOpenSendConfirmEmailModal,
      Faculties,
      Courses,
      Institutions,
      Suspended,
      Scholar,
      Selected,
      facets,
      defaultCheckList,
      isRoomAssessmentDone,
      isProfilingAssessmentDone,
      isBookPanelInterviewDone,
      isSelectedForGEIP,
      // filterPropsArr,
      Batch,
      bulkActionsModal,
      isOpenSelectedForGeipModal,
    } = this.state;
    const columnWithFilter = [
      {
        title: `${t('student')} ${t('details')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
      {
        title: `${t('status')}`,
        className: 'text-left',
        name: 'offerId',
        propName: 'offerId',
      },
    ];
    // const keys = facets && Object.keys(facets);
    // if (keys && Array.isArray(keys)) {
    //   keys.sort();
    // }
    const finalEmailTemplates = emailTemplates && Array.isArray(emailTemplates)
      && emailTemplates.length > 0
      && emailTemplates.filter(res => res && res.ownerId && Number(res.ownerId) === (userId && Number(userId)));
    let url = '/roboroy/api/v1/companies/downloadStudents?';
    if (Faculties && Array.isArray(Faculties) && Faculties.length > 0) {
      url += `faculty=${encodeURIComponent(JSON.stringify(Faculties))}&`;
    }
    if (
      this.state &&
      this.state['Application Submitted'] &&
      Array.isArray(this.state['Application Submitted']) &&
      this.state['Application Submitted'].length > 0
    ) {
      url += `isAppSubmitted=${encodeURIComponent(
        JSON.stringify(this.state['Application Submitted'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Open Market'] &&
      Array.isArray(this.state['Open Market']) &&
      this.state['Open Market'].length > 0
    ) {
      url += `isOpenMarket=${encodeURIComponent(
        JSON.stringify(this.state['Open Market'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Internship Date'] &&
      Array.isArray(this.state['Internship Date']) &&
      this.state['Internship Date'].length > 0
    ) {
      url += `internshipDate=${encodeURIComponent(
        JSON.stringify(this.state['Internship Date'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Hired'] &&
      Array.isArray(this.state['Hired']) &&
      this.state['Hired'].length > 0
    ) {
      url += `isHired=${encodeURIComponent(
        JSON.stringify(this.state['Hired'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Completed Workflow'] &&
      Array.isArray(this.state['Completed Workflow']) &&
      this.state['Completed Workflow'].length > 0
    ) {
      url += `isCompletedWorkflow=${encodeURIComponent(
        JSON.stringify(this.state['Completed Workflow'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Profiling Assessment'] &&
      Array.isArray(this.state['Profiling Assessment']) &&
      this.state['Profiling Assessment'].length > 0
    ) {
      url += `isProfilingAssessmentDone=${encodeURIComponent(
        JSON.stringify(this.state['Profiling Assessment'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Booked Panel Interview'] &&
      Array.isArray(this.state['Booked Panel Interview']) &&
      this.state['Booked Panel Interview'].length > 0
    ) {
      url += `isBookPanelInterviewDone=${encodeURIComponent(
        JSON.stringify(this.state['Booked Panel Interview'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Selected for GEIP'] &&
      Array.isArray(this.state['Selected for GEIP']) &&
      this.state['Selected for GEIP'].length > 0
    ) {
      url += `isSelectedForGEIP=${encodeURIComponent(
        JSON.stringify(this.state['Selected for GEIP'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Room Offline Video Interview'] &&
      Array.isArray(this.state['Room Offline Video Interview']) &&
      this.state['Room Offline Video Interview'].length > 0
    ) {
      url += `isRoomAssessmentDone=${encodeURIComponent(
        JSON.stringify(this.state['Room Offline Video Interview'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Applied to Jobs'] &&
      Array.isArray(this.state['Applied to Jobs']) &&
      this.state['Applied to Jobs'].length > 0
    ) {
      url += `hasAppliedToJobs=${encodeURIComponent(
        JSON.stringify(this.state['Applied to Jobs'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Accepted Offer'] &&
      Array.isArray(this.state['Accepted Offer']) &&
      this.state['Accepted Offer'].length > 0
    ) {
      url += `hasAcceptedOffer=${encodeURIComponent(
        JSON.stringify(this.state['Accepted Offer'])
      )}&`;
    }
    if (
      this.state &&
      this.state['Uploaded Signed Offer Letter'] &&
      Array.isArray(this.state['Uploaded Signed Offer Letter']) &&
      this.state['Uploaded Signed Offer Letter'].length > 0
    ) {
      url += `isSignedOffer=${encodeURIComponent(
        JSON.stringify(this.state['Uploaded Signed Offer Letter'])
      )}&`;
    }

    if (
      this.state &&
      this.state['Offer In Progress'] &&
      Array.isArray(this.state['Offer In Progress']) &&
      this.state['Offer In Progress'].length > 0
    ) {
      url += `offerInProgress=${encodeURIComponent(
        JSON.stringify(this.state['Offer In Progress'])
      )}&`;
    }
    if (Suspended && Array.isArray(Suspended) && Suspended.length > 0) {
      url += `suspended=${encodeURIComponent(JSON.stringify(Suspended))}&`;
    }
    if (Selected && Array.isArray(Selected) && Selected.length > 0) {
      url += `selected=${encodeURIComponent(JSON.stringify(Selected))}&`;
    }
    if (Courses && Array.isArray(Courses) && Courses.length > 0) {
      url += `course=${encodeURIComponent(JSON.stringify(Courses))}&`;
    }
    if (Institutions && Array.isArray(Institutions) && Institutions.length > 0) {
      url += `institution=${encodeURIComponent(JSON.stringify(Institutions))}&`;
    }
    if (Batch && Array.isArray(Batch) && Batch.length > 0) {
      url += `batch=${encodeURIComponent(JSON.stringify(Batch))}&`;
    }
    if (Scholar && Array.isArray(Scholar) && Scholar.length > 0) {
      url += `scholar=${encodeURIComponent(JSON.stringify(Suspended))}&`
    }

    url += `filter[limit]=${count}&_=${new Date().getTime()}`;
    const otherActionsButton = [];
    otherActionsButton.push(
      <ToolbarItem>
        <a className='bx--btn bx--btn--tertiary mr-2' title='Download Profiling Report' style={{ textDecoration: 'none', paddingRight: '33px' }} target="_blank" href={`/roboroy/api/v1/companyaddinfo/reports/${1}/profiling?_=${new Date().getTime()}`}>
          <UserRole24 />
        </a>
        <a className='bx--btn bx--btn--tertiary mr-2' title='Download Interview Report' style={{ textDecoration: 'none', paddingRight: '33px' }} target="_blank" href={`/roboroy/api/v1/companyaddinfo/reports/${1}/interview?_=${new Date().getTime()}`}>
          <CalendarHeatMap24 />
        </a>
        <a target="_blank" title='Export Students' className="btn--color" href={url}>
          <Button
            style={{ paddingRight: '33px' }}
            kind="secondary"
            className="mr-2"
            onClick={() => { }}
          >
            <DocumentExport24 />
            {/* Export {t('students')} */}
          </Button>
        </a>
        <Button
          style={{ paddingRight: '33px' }}
          title='Import Students'
          kind="primary"
          onClick={() => this.handleRedirectImportStdsPage()}
        >
          <DocumentImport24 />
          {/* {t('import')} {t('students')} */}
        </Button>
      </ToolbarItem>
    );

    let keys = []
    const finalKeys = facets && Object.keys(facets);
    if (finalKeys && Array.isArray(finalKeys) && finalKeys.length > 0) {
      keys.splice(0, 0, "Faculties")
      keys.splice(1, 0, "Courses")
      keys.splice(2, 0, "Batch")
      keys.splice(3, 0, "Suspended")
      keys.splice(4, 0, "Open Market")
      keys.splice(5, 0, "Application Submitted")
      keys.splice(6, 0, "Applied to Jobs")
      keys.splice(7, 0, "Shortlisted By Companies")
      keys.splice(8, 0, "Completed Workflow")
      keys.splice(9, 0, "Offer In Progress")
      keys.splice(10, 0, "Accepted Offer")
      keys.splice(11, 0, "Hired")
      keys.splice(12, 0, "Uploaded Signed Offer Letter")
      keys.splice(13, 0, "Scholar")
      keys.splice(14, 0, "Room Offline Video Interview")
      keys.splice(15, 0, "Profiling Assessment")
      keys.splice(16, 0, "Booked Panel Interview")
      keys.splice(17, 0, "Selected For GEIP")
    }

    return (
      <Fragment>
        <BackRow
          paths={this.paths}
        />
        <div className="bx--type-delta mb-2 mt-2">{t('browseStudents')}</div>

        <div className="students-layout">
          <Layout
            bodyHeader={
              <div className="meta d-flex justify-content-between w-100 align-items-center">
                <div>
                  <PagingInfo
                    end={Math.min(
                      Number(count),
                      Number(this.state.itemsPerPage) *
                      Number(this.state.activePage)
                    )}
                    start={
                      1 +
                      Number(this.state.activePage - 1) *
                      Number(this.state.itemsPerPage)
                    }
                    totalResults={Number(count)}
                  />
                </div>
              </div>
            }

            sideContent={
              (defaultCheckList !== null && defaultCheckList !== undefined && defaultCheckList != {}) ?
                <FacetsBrowse
                  handleClearFilters={this.handleClearFilters}
                  handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
                  handleSubmitFilterVals={this.handleSubmitFilterVals}
                  values={this.state}
                  facets={facets}
                  keys={keys}
                  defaultCheckList={this.state.defaultCheckList}
                  showAlteredFacetsLabels
                  handleFacetsLabels={this.handleFacetsLabels}
                  activeMoreModal={this.activeMoreModal}
                  handleSubmitCheckBox={this.handleSubmitCheckBox}
                  dismissMoreModal={this.dismissMoreModal}
                  isOpenMoreModal={isOpenMoreModal}
                  mainVals={mainVals}
                  handleSelectClearAll={this.handleSelectClearAll}
                /> : null
            }

            bodyContent={
              <div className="">
                <GenericDataTable
                  className=""
                  otherActions={otherActionsButton}
                  batchActions={
                    [{
                      label: `${t('send')} ${t('email')}`,
                      key: 'email',
                      icon: {
                        id: 'icon--email',
                        name: 'icon--email',
                        tags: 'icon--email',
                        styles: '',
                        viewBox: '0 0 16 11',
                        width: '16',
                        height: '11',
                        svgData: {
                          circles: '',
                          ellipses: '',
                          paths: [
                            {
                              d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                              'fill-rule': 'nonzero',
                            },
                          ],
                          polygons: '',
                          polylines: '',
                          rects: '',
                        },
                      },
                    },
                    {
                      label: `${t('send')} ${t('email')} ${t('all')} ${count} ${t('students')}`,
                      key: 'email-all',
                      icon: {
                        id: 'icon--email',
                        name: 'icon--email',
                        tags: 'icon--email',
                        styles: '',
                        viewBox: '0 0 16 11',
                        width: '16',
                        height: '11',
                        svgData: {
                          circles: '',
                          ellipses: '',
                          paths: [
                            {
                              d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                              'fill-rule': 'nonzero',
                            },
                          ],
                          polygons: '',
                          polylines: '',
                          rects: '',
                        },
                      },
                    },
                    {
                      label: 'Batch Actions',
                      key: 'list',
                    }]
                  }
                  headers={
                    columnWithFilter.map((data, index) => (
                      {
                        key: `header_${index}`,
                        header: data.title,
                        className: data.className,
                        colpropName: data.propName,
                      }
                    ))}
                  rows={students && Array.isArray(students)
                    && students.length > 0
                    ? students.map((res) => {
                      const {
                        _source: {
                          picture,
                          profile: {
                            firstName,
                            lastName,
                            email,
                            profileId,
                            suspended,
                            scholar
                          } = {},
                          studentProfileId,
                          studentUniversityId,
                          isOpenMarket,
                          isAppSubmitted,
                          isRoomAssessmentDone,
                          isProfilingAssessmentDone,
                          isBookPanelInterviewDone,
                          isSelectedForGEIP,
                          hasAppliedToJobs,
                          isShortlistedByCompany,
                          isCompletedWorkflow,
                          offerInProgress,
                          hasAcceptedOffer,
                          isHired,
                          isSignedOffer,
                          registrationEmailDeliveryStatus
                        } = {},
                      } = res || {};
                      return ({
                        isExpanded: true,
                        id: `row_${studentProfileId}_${profileId}`,
                        // key: `${studentProfileId}`,
                        header_0: (
                          <div className="d-flex p-2">
                            <div className="mt-2">
                              <Avatar
                                user={{
                                  firstname: firstName,
                                  lastname: lastName,
                                  picture,
                                }}
                                classNameInfo="rounded-circle"
                                size="60"
                              />
                            </div>
                            <div className="mt-2 ml-2">
                              <div><small><b className="text-dark text-uppercase">{t('studentUniversityId')}</b> {studentUniversityId}</small></div>
                              <div className="bx--type-epsilon text-uppercase xpa-link"><a href={`/app/campus/profile/${profileId}/student/${studentProfileId}`}>{firstName} {lastName}</a></div>
                              <div>
                                <i className="fa fa-envelope-o" aria-hidden="true" />&nbsp;
                            <a href={`mailto:${email}`}>{email}</a>
                              </div>
                              <div>
                                {
                                  registrationEmailDeliveryStatus
                                    ? (
                                      <span className="xpa-bx--tag--s bx--tag--green">{t('Registration Email Sent')}</span>
                                    ) : null
                                }
                              </div>
                              <div>
                                {
                                  suspended
                                    ? (
                                      <span className="btn btn-sm btn-danger">{t('accountSuspended')}</span>
                                    ) : null
                                }
                              </div>
                            </div>
                          </div>
                        ),
                        header_1: (
                          <div className="m-2">
                            <div>
                              {isOpenMarket === true ? <span className="xpa-bx--tag--s bx--tag--green">Open Market<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Open Market<CloseOutline16 className="ml-1" width='12' /></span>}
                            </div>
                            <hr className="mt-1 mb-1"/>
                            <div>
                              {isAppSubmitted === true ? <span className="xpa-bx--tag--s bx--tag--green">Profile Completion<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Profile Completion<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isRoomAssessmentDone === true ? <span className="xpa-bx--tag--s bx--tag--green">Room Offline Video Interview<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Room Offline Video Interview<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isProfilingAssessmentDone === true ? <span className="xpa-bx--tag--s bx--tag--green">Profiling Assessment<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Profiling Assessment<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isBookPanelInterviewDone === true ? <span className="xpa-bx--tag--s bx--tag--green">Book Panel Interview<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Book Panel Interview<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isSelectedForGEIP === true ? <span className="xpa-bx--tag--s bx--tag--green">Selected For GEIP<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Selected For GEIP<CloseOutline16 className="ml-1" width='12' /></span>}
                            </div>
                            <hr className="mt-1 mb-1"/>
                            <div>
                              {hasAppliedToJobs === true ? <span className="xpa-bx--tag--s bx--tag--green">Job Application<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Job Application<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isShortlistedByCompany === true ? <span className="xpa-bx--tag--s bx--tag--green">Shortlisted by Companies<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Shortlisted by Companies<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isCompletedWorkflow === true ? <span className="xpa-bx--tag--s bx--tag--green">Interview Completion<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Interview Completion<CloseOutline16 className="ml-1" width='12' /></span>}
                              {offerInProgress === true ? <span className="xpa-bx--tag--s bx--tag--green">Offer In Progress<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Offer In Progress<CloseOutline16 className="ml-1" width='12' /></span>}
                              {hasAcceptedOffer === true ? <span className="xpa-bx--tag--s bx--tag--green">Position Offer<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Position Offer<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isHired === true ? <span className="xpa-bx--tag--s bx--tag--green">Placement Completion<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Placement Completion<CloseOutline16 className="ml-1" width='12' /></span>}
                              {isSignedOffer === true ? <span className="xpa-bx--tag--s bx--tag--green">Uploaded Signed Offer Letter<CheckmarkOutline16 className="ml-1" width='12' /></span> : <span className="xpa-bx--tag--s bx--tag--red">Uploaded Signed Offer Letter<CloseOutline16 className="ml-1" width='12' /></span>}
                            </div>
                          </div>
                        )
                      });
                    }) : []}
                  selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
                  searchable
                  onSearchInputChange={this.handleSearchSubmit}
                  expandable={false}
                  batchActionClick={this.handleSelectCustomRegModal}
                />
                {
                  students && Array.isArray(students)
                  && students.length > 0
                  && (
                    <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
                  )
                }
                {
                  ((!students)
                    || (students && Array.isArray(students)
                      && students.length <= 0))
                  && <NoStudentsBox />
                }
              </div>
            }
          />
        </div>

        {/* <div className="bx--row">
          <div className="bx--col-lg-3 bx--col-md-12 bx--col-sm-12">
            <FacetsBrowse
              handleClearFilters={this.handleClearFilters}
              handleChangeCheckBoxVals={this.handleChangeCheckBoxVals}
              values={this.state}
              facets={facets}
              showAlteredFacetsLabels
              handleFacetsLabels={this.handleFacetsLabels}
              activeMoreModal={this.activeMoreModal}
              handleSubmitCheckBox={this.handleSubmitCheckBox}
              dismissMoreModal={this.dismissMoreModal}
              isOpenMoreModal={isOpenMoreModal}
              mainVals={mainVals}
            />
          </div>
          <div className="bx--col-lg-9 bx--col-md-12 bx--col-sm-4 bx--col-xs-4">
            <GenericDataTable
              className=""
              otherActions={otherActionsButton}
              batchActions={
                [{
                  label: `${t('send')} ${t('email')}`,
                  key: 'email',
                  icon: {
                    id: 'icon--email',
                    name: 'icon--email',
                    tags: 'icon--email',
                    styles: '',
                    viewBox: '0 0 16 11',
                    width: '16',
                    height: '11',
                    svgData: {
                      circles: '',
                      ellipses: '',
                      paths: [
                        {
                          d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                          'fill-rule': 'nonzero',
                        },
                      ],
                      polygons: '',
                      polylines: '',
                      rects: '',
                    },
                  },
                },
                {
                  label: `${t('send')} ${t('email')} ${t('all')} ${count} ${t('students')}`,
                  key: 'email-all',
                  icon: {
                    id: 'icon--email',
                    name: 'icon--email',
                    tags: 'icon--email',
                    styles: '',
                    viewBox: '0 0 16 11',
                    width: '16',
                    height: '11',
                    svgData: {
                      circles: '',
                      ellipses: '',
                      paths: [
                        {
                          d: 'M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z',
                          'fill-rule': 'nonzero',
                        },
                      ],
                      polygons: '',
                      polylines: '',
                      rects: '',
                    },
                  },
                },
                {
                  label: 'Batch Actions',
                  key: 'list',
                }]
              }
              headers={
                columnWithFilter.map((data, index) => (
                  {
                    key: `header_${index}`,
                    header: data.title,
                    className: data.className,
                    colpropName: data.propName,
                  }
                ))}
              rows={students && Array.isArray(students)
                && students.length > 0
                ? students.map((res) => {
                  const {
                    _source: {
                      picture,
                      profile: {
                        firstName,
                        lastName,
                        email,
                        profileId,
                        suspended,
                        scholar
                      } = {},
                      studentProfileId,
                      studentUniversityId,
                    } = {},
                  } = res || {};
                  return ({
                    isExpanded: true,
                    id: `row_${studentProfileId}`,
                    // key: `${studentProfileId}`,
                    header_0: (
                      <div className="d-flex p-2">
                        <div className="mt-2">
                          <Avatar
                            user={{
                              firstname: firstName,
                              lastname: lastName,
                              picture,
                            }}
                            classNameInfo="rounded-circle"
                            size="60"
                          />
                        </div>
                        <div className="mt-2 ml-2">
                          <div><small><b className="text-dark text-uppercase">{t('studentUniversityId')}</b> {studentUniversityId}</small></div>
                          <div onClick={() => this.handleRedirectProfilePage(profileId, studentProfileId)} className="bx--type-epsilon text-uppercase xpa-link">{firstName} {lastName}</div>
                          <div>
                            <i className="fa fa-envelope-o" aria-hidden="true" />&nbsp;
                            <a href={`mailto:${email}`}>{email}</a>
                          </div>
                          <div>
                            {
                              suspended
                                ? (
                                  <span className="btn btn-sm btn-danger">{t('accountSuspended')}</span>
                                ) : null
                            }
                          </div>
                        </div>
                      </div>
                    ),
                  });
                }) : []}
              selectable={isSuperAdmin || (isAdmin && ctypeId && Number(ctypeId) === 2)}
              searchable
              onSearchInputChange={this.handleSearchSubmit}
              expandable={false}
              batchActionClick={this.handleSelectCustomRegModal}
            />
            {
              students && Array.isArray(students)
              && students.length > 0
              && (
                <Pagination onChange={this.handlePaginationChange} pageSizes={[10, 20, 30, 40, 50]} page={activePage} totalItems={count} />
              )
            }
            {
              ((!students)
                || (students && Array.isArray(students)
                  && students.length <= 0))
              && <NoStudentsBox />
            }
          </div>
        </div> */}
        <Modal
          onRequestClose={() => { this.setState({ isOpenPassiveCustRegModal: false }); }}
          open={isOpenPassiveCustRegModal}
          passiveModal
          size="lg"
          modalLabel="Select Templates"
          modalHeading={t('select')}
        >
          <div className="row ml-1">
            <RadioButtonGroup onChange={e => {
              this.handleChangeRadioButton(e);
            }}
              defaultSelected={this.state.selectedValue}
              valueSelected={this.state.selectedValue}
            >
              <RadioButton
                name="templates"
                value="panel-interview-booked"
                labelText="Interview Scheduling Email"
              />
              <RadioButton
                name="templates"
                value="geip-appln-submission-reminder"
                labelText="Incomplete Submission Email"
              />
              <RadioButton
                name="templates"
                value="custom-templates"
                labelText="Custom Templates"
              />
              <RadioButton
                name="templates"
                value="imported-students-registration"
                labelText="Registration Email"
              />
              <RadioButton
                name="templates"
                value="offer-letter-submission-reminder"
                labelText="Offer Letter Submission Reminder"
              />
              <RadioButton
                name="templates"
                value="placement-confirmation-email"
                labelText="Student Confirmation Email"
              />
            </RadioButtonGroup>
          </div>
        </Modal>
        {bulkActionsModal && (
          <Modal
            onRequestClose={() => { this.setState({ bulkActionsModal: false }); }}
            open={bulkActionsModal}
            passiveModal
            size="lg"
            modalLabel="Select Bulk Action"
            modalHeading={t('select')}
          >
            <div className="row ml-1">
              <RadioButtonGroup
                onChange={e => {
                  this.handleChangeRadioButton(e);
                }}
                valueSelected={this.state.selectedValue}
              >
                <RadioButton
                  name="bulk-actions"
                  value="open-market-to-students"
                  labelText="Open Market"
                />
                <RadioButton
                  name="bulk-actions"
                  value="selected-for-geip"
                  labelText="Selected For GEIP"
                />
              </RadioButtonGroup>
            </div>
          </Modal>
        )}
        {isOpenSelectedForGeipModal && (
          <Modal
            onRequestSubmit={this.handleSubmitSelectedForGeip}
            onRequestClose={() => this.dismissSelectedForGeipModal(true)}
            open={this.state.isOpenSelectedForGeipModal}
            modalLabel="Action"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            modalHeading="Batch Actions"
            className="xpa-modal-height-70"
          >
            <>
              <div className="mt-2">
                <strong>{t('selectedForGeip')}</strong>
              </div>
              <div className='mt-1'>
                <div>
                  <RadioButtonGroup
                    valueSelected={this.state.selectedForGeip || null}
                    onChange={(e) => { this.setState({ selectedForGeip: e, selectedGeipInvalid: false, geipInternshipProgram: null, geipInternshipProgramInvalid: false }) }}
                  >
                    <RadioButton
                      value="yes"
                      id="selected-geip-yes"
                      labelText="Yes"
                    />
                    <RadioButton
                      value="no"
                      id="selected-geip-no"
                      labelText="No"
                    />
                  </RadioButtonGroup>
                </div>
                {this.state.selectedGeipInvalid === true && (
                  <div style={{ fontSize: '.75rem', color: '#da1e28' }} className="mt-1">
                    {t('select_yes_no_err_msg')}
                  </div>
                )}
                <div className="text-dark">
                  <small>{t('selectedForGeipSubheader')}</small>
                </div>
                {this.state.selectedForGeip == 'no' && (
                  <div style={{ maxWidth: '15rem' }}>
                    <div className="">
                      <span className="bx--type-zeta">{(t('Internship Program'))}</span> <span className="xpa-job-title">*</span>
                    </div>
                    <DropDownBox
                      title=""
                      mainClassName="bx--form-item mb-0"
                      titleClass=""
                      labelClass=""
                      required
                      custom
                      className={`w-100 xpa-customize-dropdown ${this.state.geipInternshipProgramInvalid ? 'invalid' : ''}`}
                      options={[{label: 'Overseas Internship', value: 'overseas'}, {label: 'Graded Internship', value: 'graded'}]}
                      placeholder={t('geipInternshipProgram')}
                      onChange={(value, e, name) => this.handleDropdownChange(value, e, name)}
                      name="geipInternshipProgram"
                      selectedValue={this.state.geipInternshipProgram || null}
                    />
                    {this.state.geipInternshipProgramInvalid === true && (
                      <div style={{color: '#da1e28', fontSize: '.75rem'}} className="mb-2">
                        {t('Please select internship program')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          </Modal>
        )}
        <Modal
          onRequestSubmit={this.handleSubmitSendRegEmail}
          onRequestClose={() => {
            this.setState({
              isOpenSendRegEmailModal: false,
            })
          }}
          open={isOpenSendRegEmailModal}
          modalLabel={
            `Send ${(selectedValue == 'geip-appln-submission-reminder') ?
              "Incomplete Submission Email":
              (selectedValue == 'panel-interview-booked') ? "Interview Scheduling Email":"Registration Email"}`}
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >
          <div>
            {t('regEmailMsg')}
          </div>
          <span className="pt-0 pb-1 text-dark d-block">
            Clicking on <strong>submit</strong> will send an email to
            the applicant.&nbsp;
            {selectedValue == 'import-student-reg' &&
              <span>
                <span className="xpa-link" onClick={this.onViewEmail}>
                  Click here
                </span>{' '}
                to view content of the email.
              </span>
            }
          </span>
        </Modal>

        <Modal
          onRequestSubmit={this.handleSubmitSendReminderEmail}
          onRequestClose={() => {
            this.setState({
              isOpenSendReminderEmailModal: false,
            })
          }}
          open={isOpenSendReminderEmailModal}
          modalLabel="Send Offer Letter Submission Reminder Email"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >
          <div>
            Are you sure you want to send offer letter submission reminder email to selected students?
          </div>
          <span className="pt-0 pb-1 text-dark d-block">
            Clicking on <strong>submit</strong> will send an email to
            the applicant.&nbsp;
            <span className="xpa-link" onClick={this.onViewReminderEmail}>
              Click here
            </span>{' '}
            to view content of the email.
          </span>
        </Modal>

        <Modal
          onRequestSubmit={this.handleSubmitSendConfirmationEmail}
          onRequestClose={() => {
            this.setState({
              isOpenSendConfirmEmailModal: false,
            })
          }}
          open={isOpenSendConfirmEmailModal}
          modalLabel="Send Confirmation Email"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >
          <div>
            {t('confirmEmailMsg')}
          </div>
          <span className="pt-0 pb-1 text-dark d-block">
            Clicking on <strong>submit</strong> will send an email to
            the student.&nbsp;
            <span className="xpa-link" onClick={this.onViewConfirmationEmail}>
              Click here
            </span>{' '}
            to view content of the email.
          </span>
        </Modal>

        <Modal
          onRequestSubmit={this.templateModalSubmit}
          onRequestClose={this.templateModalClose}
          open={selectTemplateModal}
          modalLabel="Email Templates"
          primaryButtonText={t('submit')}
          secondaryButtonText={t('cancel')}
          modalHeading="Send Mail"
        >
          <div className="bx--modal-content__text">
            {
              showSuccessMsg && <InlineNotification lowContrast kind="success" title="Email Sent Successfully" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ showSuccessMsg: false })} />
            }
            {
              noTemplateError && <InlineNotification lowContrast kind="error" title="No template selected" subtitle="" hideCloseButton onCloseButtonClick={() => this.setState({ noTemplateError: false })} />
            }
            <Select
              labelText="Select Email Template"
              className="mb-4"
              id="email-templates-list"
              onChange={this.onEmailTemplateChange}
              value={selectedEmailTemplate}
              defaultValue="placeholder-item"
            >
              <SelectItem disabled value="placeholder-item" text="Choose a template" />
              {
                finalEmailTemplates && Array.isArray(finalEmailTemplates)
                && finalEmailTemplates.map(({ displayName = '', id = null, templateName = '' }) => (<SelectItem key={id} name={templateName} label={displayName} value={`${id}_${templateName}`} />))
              }
            </Select>
            <Fragment>
              <span className="pt-0 pb-1 text-dark d-block">
                {t('clickingOn')} <strong>{t('submit')}</strong> {t('clickingOn2')}&nbsp;
                <span className="xpa-link" onClick={this.onCustomTemplatePreview}>
                  Click here
                </span>{' '}
                to view content of the email.
              </span>
            </Fragment>
          </div>
        </Modal>
        <Modal
          onRequestSubmit={this.handleSubmitOpenMarketData}
          onRequestClose={() => this.dismissOpenMarketModal(true)}
          open={this.state.isOpenMarketModal}
          modalLabel="Action"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          modalHeading="Batch Actions">
          <div>
            <div className="mb-2 text-dark">
              For the students that you've selected, please choose the properties to
              be set.
            </div>
            <div className="mb-2 bx--type-zeta">Open Market to students</div>
            <div className="d-flex">
              <RadioButtonGroup 
                onChange={(e) => { this.setState({ selectedOpenMarketValue: e }) }}
                defaultSelected={this.state.selectedOpenMarketValue}
                valueSelected={this.state.selectedOpenMarketValue}
              >
                <RadioButton
                  name="isOpenMarket"
                  value="isOpenMarket-true"
                  labelText="Yes"
                />
                <RadioButton
                  name="isOpenMarket"
                  value="isOpenMarket-false"
                  labelText="No"
                />
              </RadioButtonGroup>
            </div>
            {this.state.selectedOpenMarketValueInvalid === true && (
              <div style={{ fontSize: '.75rem', color: '#da1e28' }} className="mt-1">
                {t('select_yes_no_err_msg')}
              </div>
            )}
          </div>
        </Modal>
        <JDProcessOverlay show={loading} message="processing..." />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  students: state.BrowseStudents.students,
  studentList: state.BrowseStudents.studentList,
  count: state.BrowseStudents.count,
  emailTemplates: state.emailTemplates.templates,
  facets: state.BrowseStudents.facets,
  loading: state.BrowseStudents.loading,
});

const mapDispatchToProps = {
  getStudentsList,
  getCustomEmailTemplates,
  sendEmails,
  fetchAllStudentsList,
  getStudentsFacets,
  editCandidateProfile,
  getBatchDetails,
  sendOfferConfirmationMail,
  updateSelectedForGeip
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BrowseStudents));